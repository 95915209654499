import React from "react";

import "./immovestorButton.scss";

/**
 * `ImmovestorButton` is a reusable button component for the Immovestor application.
 *
 * @component
 * @param {object} props The properties passed to the component.
 * @param {React.ReactNode} props.children The content to be displayed within the button.
 * @param {function} [props.onClick=() => {}] The handler function to be invoked when the button is clicked. Default is an empty function.
 * @param {string} props.href The URL the button should navigate to when clicked. If present, the button is rendered as an `a` element; otherwise, it is a `button` element.
 * @param {boolean} [props.newTab=false] Specifies whether the URL should be opened in a new tab or the current tab. This prop is ignored if `href` is not provided. Default is false.
 * @param {boolean} [props.outlined=false] Specifies whether the button should be styled as an outlined button. Default is false.
 * @param {string} props.className Additional CSS classes to apply to the button. Default is an empty string.
 * @param {boolean} [props.grey=false] Specifies whether the button should be styled in grey. Default is false.
 *
 * @example
 * // Outlined button with a click handler
 * <ImmovestorButton outlined onClick={handleClick}>Click Me</ImmovestorButton>
 *
 * // Button navigating to an external URL in a new tab
 * <ImmovestorButton href="https://example.com" newTab>Visit Example</ImmovestorButton>
 *
 * @returns {React.Component} Returns a button or a link, depending on the provided properties.
 */

const ImmovestorButton = ({
    children,
    onClick = () => {},
    href,
    newTab = false,
    outlined = false,
    className,
    grey = false,
}) => {
    if (href) {
        return (
            <a
                className={`Immovestor-button--link ${
                    outlined && "Immovestor-button--link--outlined"
                } ${className && className} ${
                    grey && "Immovestor-button--link--grey"
                }`}
                href={href}
                target={newTab ? "_blank" : "_self"}
                rel={newTab ? "noopener noreferrer" : ""}
            >
                {children}
            </a>
        );
    }

    return (
        <button
            className={`Immovestor-button--button ${
                outlined && "Immovestor-button--button--outlined"
            } ${className && className} ${
                grey && "Immovestor-button--button--grey"
            }`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default ImmovestorButton;
