import formatNumber from "../helpers/formatNumber";
import { good_state } from "../constants/constant_lists";

/**
 * Empty value text.
 *
 * @type {string}
 */
const NO_DATA = "non communiqué";

const yes_no_data = (
    bool,
    main_data,
    main_data_supp = "m²",
    additional_data = null,
    additional_data_sup = ""
) => {
    if (!main_data) {
        return bool ? "oui" : "non";
    }

    return bool
        ? `${main_data}${main_data_supp} ${
              additional_data ? additional_data : ""
          }
			${additional_data_sup ? additional_data_sup : ""}`
        : "non";
};

const show_parkings = (countIndoor, countOutdoor, countClosedBox) => {
    if (countIndoor || countOutdoor || countClosedBox) {
        let type = countIndoor ? "extérieur" : "";
        type += countOutdoor
            ? type.length > 0
                ? ", extérieur"
                : "extérieur"
            : "";
        return `${countIndoor + countOutdoor + countClosedBox} ${type}`;
    } else {
        return "non";
    }
};

export const complete_data = (place, isAdmin) => {
    const parsedValues = {};

    const parseData = (condition, key, outputValue) => {
        if (condition) {
            parsedValues[key] = outputValue();
        } else {
            if (isAdmin) parsedValues[key] = NO_DATA;
        }
    };

    parseData(
        !isNaN(place.property_data.bot_data.rent) &&
            place.property_data.bot_data.rent > 0,
        "Loyer",
        () => `${formatNumber(place.property_data.bot_data.rent)}€`
    );

    parseData(
        !isNaN(place.property_data.bot_data.rdm) &&
            place.property_data.bot_data.rdm > 0,
        "Rdmt brut",
        () => `${formatNumber(place.property_data.bot_data.rdm, 1)}%`
    );

    parseData(
        !isNaN(place.property_data.bot_data.potential_rent) &&
            place.property_data.bot_data.potential_rent > 0,
        "Loyer potentiel",
        () => `${formatNumber(place.property_data.bot_data.potential_rent)}€`
    );

    parseData(
        !isNaN(place.property_data.transaction.sale?.cadastralIncome),
        "Revenu cadastral",
        () =>
            formatNumber(
                place.property_data.transaction.sale?.cadastralIncome
            ) == 0
                ? "Non communiqué"
                : `${formatNumber(
                      place.property_data.transaction.sale?.cadastralIncome
                  )}€`
    );

    parseData(
        place.property_data.certificats.epcScore,
        "PEB",
        () => `${place.property_data.certificats.epcScore}`
    );

    parseData(
        place.property_data.netHabitableSurface,
        "Prix au m²",
        () =>
            `${formatNumber(
                place.property_data.price /
                    place.property_data.netHabitableSurface
            )}€`
    );

    // if (place.loyer && isVisible) {
    // 	parsedValues["Status location"] = "inconnu";
    // }
    //
    // if (place.loyer) {
    // 	parsedValues["Precompte immo"] = "inconnu";
    // }
    //
    // if (place.loyer) {
    // 	parsedValues["Frais copro"] = "inconnu";
    // }

    parseData(
        place.property_data.building.constructionYear,
        "Année de construction",
        () => place.property_data.building.constructionYear
    );

    parseData(
        place.property_data.building?.condition,
        "Etat du bien",
        () => good_state[place.property_data.building?.condition]
    );

    parseData(
        place.property_data.building.floorCount,
        "Nb étage",
        () => place.property_data.building.floorCount
    );

    parseData(
        place.property_data.location.floor,
        "Nº étage du bien",
        () => place.property_data.location.floor
    );

    parseData(place.property_data.installations?.hasLift, "Ascenseur", () =>
        yes_no_data(place.property_data.installations?.hasLift, null)
    );

    parseData(
        place.property_data.constructionPermit.totalBuildableGroundFloorSurface,
        "Surface construite",
        () =>
            `${formatNumber(
                place.property_data.constructionPermit
                    .totalBuildableGroundFloorSurface
            )}m²`
    );

    parseData(
        place.property_data.netHabitableSurface,
        "Surface habitable",
        () => `${formatNumber(place.property_data.netHabitableSurface)}m²`
    );

    parseData(
        place.property_data.land?.surface,
        "Surface extérieure",
        () => `${formatNumber(place.property_data.land.surface)}m²`
    );

    parseData(
        place.property_data.constructionPermit.totalBuildableGroundFloorSurface,
        "Surface constructible",
        () =>
            `${formatNumber(
                place.property_data.constructionPermit
                    .totalBuildableGroundFloorSurface
            )}m²`
    );

    parseData(
        place.property_data.building.facadeCount,
        "Nb facade",
        () => place.property_data.building.facadeCount
    );

    parseData(place.loyer, "Urbanistique", () => null);

    parseData(place.loyer, "Non conformité", () => null);

    parseData(
        place.property_data.bedroomCount,
        "Nb chambre",
        () => place.property_data.bedroomCount
    );

    parseData(
        place.property_data.roomCount,
        "Nb piece",
        () => place.property_data.roomCount
    );

    parseData(
        place.property_data.bathroomCount,
        "Nb sdb",
        () => place.property_data.bathroomCount
    );

    parseData(
        place.property_data.toiletsCount,
        "Nb WC séparés",
        () => place.property_data.toiletsCount
    );

    parseData("exist" in place.property_data.garden, "Jardin", () =>
        yes_no_data(
            place.property_data.garden.exist,
            place.property_data.garden.surface,
            "m²",
            place.property_data.garden.orientation
        )
    );

    parseData("exist" in place.property_data.balcony, "Balcon", () =>
        yes_no_data(place.property_data.balcony.exist, null)
    );

    parseData("exist" in place.property_data.terrace, "terrasse", () =>
        yes_no_data(
            place.property_data.terrace.exist,
            place.property_data.terrace.surface,
            "m²",
            place.property_data.terrace.orientation
        )
    );

    parseData("exist" in place.property_data.attic, "Grenier", () =>
        yes_no_data(
            place.property_data.attic.exist,
            place.property_data.attic.surface,
            "m²",
            null
        )
    );

    parseData(
        place.property_data.bot_data.convertible_attic,
        "Grenier aménageable",
        () =>
            yes_no_data(
                place.property_data.bot_data.convertible_attic,
                null,
                "m²",
                null
            )
    );

    parseData("exist" in place.property_data.basement, "Cave", () =>
        yes_no_data(
            place.property_data.basement.exist,
            place.property_data.basement.surface,
            "m²",
            null
        )
    );

    // parseData(
    // 	(place.property_data.parking?.indoor >= 0 || place.property_data.parking?.outdoor >= 0|| place.property_data.parking?.closed_box >= 0),
    // 	"Parking",
    // 	() => (place.property_data.parking?.indoor + place.property_data.parking?.outdoor + place.property_data.parking?.closed_box)
    // )

    parsedValues["Parking"] = show_parkings(
        place.property_data.parking.indoor,
        place.property_data.parking.outdoor,
        place.property_data.parking.closed_box
    );

    parseData(
        place.property_data.energy?.heatingType,
        "Chauffage",
        () => place.property_data.energy?.heatingType
    );

    // if (place.loyer) {
    // 	parsedValues["Cuisiniere"] = "inconnu";
    // }

    parseData(
        place.property_data.energy?.hasPhotovoltaicPanels,
        "Panneaux photovoltaïques",
        () => place.property_data.energy?.hasPhotovoltaicPanels
    );

    parseData(
        place.property_data.energy?.hasThermicPanels,
        "Panneaux solaires",
        () => place.property_data.energy?.hasThermicPanels
    );

    // if (place.loyer) {
    // 	parsedValues["Conso théorique"] = "inconnu";
    // }

    return parsedValues;
};
