// src/store/toolsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const updateYieldCalculatorSettings = createAsyncThunk(
    "settings/updateYieldCalculatorSettings",
    async (settings) => {
        const response = await axios.put("/settings/yield-calculator", settings);
        return response.data;
    }
);


export const getYieldCalculatorSettings = createAsyncThunk(
    "settings/getYieldCalculatorSettings",
    async () => {
        const response = await axios.get("/settings/yield-calculator");
        return response.data;
    }
);

const appSettingsSlice = createSlice({
    name: "appSettings",
    initialState: {
        isLoading: false,
        error: null,
        yieldCalculator: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateYieldCalculatorSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateYieldCalculatorSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.yieldCalculator = action.payload;
            })
            .addCase(updateYieldCalculatorSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(getYieldCalculatorSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getYieldCalculatorSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.yieldCalculator = action.payload;
            })
            .addCase(getYieldCalculatorSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    },
});


export const appSettingsMethods = {
    updateYieldCalculatorSettings,
    getYieldCalculatorSettings
}

export default appSettingsSlice;
