import React from "react";

import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorBring = ({ bring, setBring }) => {
    return (
        <div className="yield-calculator__bring">
            <h2 className="yield-calculator__bring__title">Apport</h2>

            <YieldCalculatorInput
                value={bring}
                setValue={setBring}
                className={"yield-calculator__input"}
                label={"Apport"}
                id={"yield-calculator-bring"}
                prefix={"€"}
            />
        </div>
    );
};

export default YieldCalculatorBring;
