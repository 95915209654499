import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const getMyAgencyProperties = createAsyncThunk(
    "agencyProperties/getMyAgencyProperties",
    async () => {
        const response = await axios.get("/me/agency-properties");
        return response.data;
    }
);

const agencyPropertiesSlice = createSlice({
    name: "agencyProperties",
    initialState: {
        isLoading: false,
        error: null,
        properties: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMyAgencyProperties.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMyAgencyProperties.fulfilled, (state, action) => {

                /**
                 * Comment these two make stop infinite loop ?????
                 * Need to comment these two : not only one!
                 */
                state.isLoading = false;
                state.properties = action.payload;
            })
            .addCase(getMyAgencyProperties.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    },
});

export const agencyPropertiesMethods = {
    getMyAgencyProperties
}


export default agencyPropertiesSlice;
