
import React, { useEffect, useRef }  from 'react'
import { createContext, useState } from 'react';
import "./toast-context.scss"
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { TOAST_LVL } from '../constants/constant_lists';

const ToastContext = createContext();

export const ToastProvider = (props) => {

    const setToast = useRef(() => null);

    const Toast = () => {
        const [toastsReload, setToastsReload] = useState(null);

        let toasts = useRef({});

        useEffect(() => {
          setToast.current = (level, title, description) => {
            let start_date = new Date().getTime();
            const new_toast = {
                level: level,
                title: title,
                description: description,
                time: start_date,
                inter: null,
            };
            toasts.current[start_date] = new_toast;
            toasts.current[start_date].inter = setTimeout(() => {
                document.getElementById(`${start_date}-toast-elem`).classList.add("active")
                toasts.current[start_date].inter = setTimeout(() => {
                    if (document.getElementById(`${start_date}-toast-elem`)) {
                        document.getElementById(`${start_date}-toast-elem`).classList.remove("active")
                        toasts.current[start_date].inter = setTimeout(() => {
                            delete toasts.current[start_date];
                            if (document.getElementById(`${start_date}-toast-elem`))
                                document.getElementById(`${start_date}-toast-elem`).style.display = "none";
                                setToastsReload(new Date().getTime())
                        }, 300);
                    }
                }, 10000);
            }, 100);
            setToastsReload(start_date)
          }
        }, [])

        const close = (start_date) => {
            document.getElementById(`${start_date}-toast-elem`).classList.remove("active")
            clearTimeout(toasts.current[start_date].inter);
            setTimeout(() => {
                delete toasts.current[start_date];
                document.getElementById(`${start_date}-toast-elem`).style.display = "none";
                setToastsReload(new Date().getTime())
            }, 300);
        }

        useEffect(() => {
        }, [toastsReload])

        return (
            <div className='toasts-container'>
                {
                    Object.keys(toasts.current).map((elem, id) => {
                        const toast = toasts.current[elem];
                        return (
                            <div id={`${toast.time}-toast-elem`} key={id} className={`toast ${toast.level}`}>
                                <div className='row-toast'>
                                    {
                                        toast.level === TOAST_LVL.INFO ? <InfoIcon/> : TOAST_LVL.SUCCESS === toast.level ? <CheckCircleIcon/> : <ErrorIcon/>
                                    }
                                    <div className='toast-content'>
                                        <span>{toast.title}</span>
                                        <p>{toast.description}</p>
                                    </div>
                                </div>
                                <CloseIcon onClick={() => close(toast.time)} className='close-btn'/>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

	return (
		<ToastContext.Provider value={{ 
            setToast
		}}>
            <Toast/>
			{ props.children }
		</ToastContext.Provider>
	);
};

export default ToastContext;