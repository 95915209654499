import StarIcon from "@mui/icons-material/Star";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";
import { LockClock as LockedIcon } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { Tooltip } from "@mui/material";

const PropertyElemStateIcons = ({
    spotlight,
    flagPriceDecrease,
    flagFastSale,
    flagAlreadySold,
    freezeByMe,
    favoriteOfMe,
}) => {
    return (
        <div className="property-element__icon__container">
            {favoriteOfMe && (
                <div className="property-element__icon__item favorite-of-me">
                    <Tooltip title="Cette annonce est dans votre liste de favoris.">
                        <FavoriteIcon />
                    </Tooltip>
                </div>
            )}
            {freezeByMe && (
                <div className="property-element__icon__item  freezed-by-me">
                    <Tooltip title="Vous avez bloqué cette annonce.">
                        <LockedIcon />
                    </Tooltip>
                </div>
            )}
            {spotlight && (
                <div className="property-element__icon__item  spotlight">
                    <Tooltip title="Ceci est un bien à la une.">
                        <StarIcon />
                    </Tooltip>
                </div>
            )}
            {flagPriceDecrease && (
                <div className="property-element__icon__item  flagPriceDecrease">
                    <Tooltip title="Le prix de ce bien à diminué.">
                        <TrendingDownIcon />
                    </Tooltip>
                </div>
            )}
            {flagFastSale && (
                <div className="property-element__icon__item  flagFastSale">
                    <Tooltip title="Ce bien est à vendre rapidement.">
                        <TimerOutlinedIcon />
                    </Tooltip>
                </div>
            )}
            {flagAlreadySold && (
                <div className="property-element__icon__item  flagAlreadySold">
                    <Tooltip title="Ce bien est déja vendu.">
                        <LoyaltyRoundedIcon />
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default PropertyElemStateIcons;
