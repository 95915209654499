import { createContext, useContext, useState } from "react";
import axios from "../services/axios";

const ToolsContext = createContext();

export const useTools = () => {
    return useContext(ToolsContext);
};

export const ToolsProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const updateFavorite = async (propertyId) => {
        setIsLoading(true);
        try {
            const response = await axios.put(`/users/favorite/${propertyId}`);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    const freezeProperty = async (propertyId) => {
        setIsLoading(true);
        try {
            const response = await axios.put(`/freeze-property/${propertyId}`);
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }

    const getFavoritesProperties = async (user) => {
        setIsLoading(true);
        try {
            const response = await axios.get("/me/favorites-properties");
            setIsLoading(false);
            return response.data;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }

    const value = {
        updateFavorite,
        getFavoritesProperties,
        freezeProperty,
        isLoading,
    };

    return (
        <ToolsContext.Provider value={value}>{children}</ToolsContext.Provider>
    );
};

export default ToolsProvider;
