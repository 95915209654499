import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { getData, storeData } from "../functions/store_data";
import axios, {
    set_instance_token,
    unset_instance_token,
} from "../services/axios";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import Loading from "../components/main/loading/Loading";
import settings from "../settings";

const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [addedByCron, setAddedByCron] = useState([]);

    const [user, setUser] = useState({
        first_name: "Anonymous",
        last_name: "User",
        token: null,
        permissions: settings.unregisteredPermissions,
    });

    const [needLog, setNeedLog] = useState(false);

    const getProfile = async () => {
        const test = await axios
            .post("/profile")
            .then((response) => {
                const usr = { ...user, ...response.data };
                setUser(usr);
                return true;
            })
            .catch((e) => {
                logout();
                return false;
            });
        return test;
    };

    const login = async (email, password) => {
        const auth = getAuth();
        const ee = await signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;
                const token = await user.getIdToken(true);
                set_instance_token(token);
                storeData("token", token);
                return await axios
                    .post("/profile")
                    .then((response) => {
                        const usr = { ...user, ...response.data, token: token };
                        setUser(usr);
                        setNeedLog(false);
                        return true;
                    })
                    .catch((e) => {
                        logout();
                        return false;
                    });
            })
            .catch((error) => {
                return false;
            });
        return ee;
    };

    const setup = async () => {
        const app_data = getData();
        if (app_data.token) {
            set_instance_token(app_data.token);
            await axios
                .post("/profile")
                .then(async (response) => {
                    const usr = {
                        ...user,
                        ...response.data,
                        token: app_data.token,
                    };
                    setUser(usr);
                    if (usr.isAdmin) {
                        const ret = await axios
                            .get("/new-by-cron")
                            .then((e) => e.data)
                            .catch((e) => null);
                        if (ret) {
                            setAddedByCron(ret);
                        }
                    }
                })
                .catch((e) => {
                    logout();
                });
        } else {
            const usr = { ...user, token: "" };
            storeData("token", "");
            setUser(usr);
        }
    };

    const updateProfile = async (data) => {
        const response = await axios
            .put("/profile", data)
            .then((response) => {
                const usr = { ...user, ...response.data };
                setUser(usr);
                return true;
            })
            .catch((e) => {
                return false;
            });
        return response;
    };

    useEffect(() => {
        setup();
    }, []);

    const register = async (
        email,
        password,
        first_name,
        last_name,
        isInvestor = true
    ) => {
        const auth = getAuth();
        const reg = await createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;
                await axios.post("/signup", {
                    user: {
                        first_name: first_name,
                        last_name: last_name,
                        email: email,
                        password: password,
                        // type: isInvestor ? "investor" : "agent",
                        type: "start",
                    },
                    uid: user.uid,
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
        const user = await login(email, password);
        return user ? "ok" : "already";
    };

    const logout = async () => {
        localStorage.clear();
        unset_instance_token();
        setUser({
            first_name: "Anonymous",
            last_name: "User",
            token: "",
            permissions: settings.unregisteredPermissions,
        });
    };

    if (user.token === null) {
        return (
            <div className="main-loading">
                <h1>Bienvenue sur Immovestor</h1>
                <Loading />
            </div>
        );
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                register,
                logout,
                needLog,
                setNeedLog,
                getProfile,
                addedByCron,
                setAddedByCron,
                updateProfile,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
