import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InfoTooltip = ({ infoText }) => {
    return (
        <Tooltip title={infoText}>
            <InfoIcon
                sx={{
                    fontSize: "1rem",
                    fill: "var(--color-secondary)",
                    marginLeft: ".2em",
                    marginBottom: "-.1em",
                }}
            />
        </Tooltip>
    );
};

export default InfoTooltip;
