import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

const DisplayFilesDialog = ({open,onClose,files}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Documents</DialogTitle>
            <DialogContent>
                {files.map((file,index) => (
                    <div>
                        <a href={file.downloadUrl} target="_blank" rel="noreferrer">{file.fileName}</a>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
};

export default DisplayFilesDialog;