import React, { useContext, useEffect } from "react";
import _ from "lodash";
import {
    Button,
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import "./style.scss";
import { useState } from "react";
import SaveButton from "../../components/filters/SaveButton";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { editable_data } from "./editable_data";
import AuthContext from "../../contexts/AuthContext";
import {
    good_state,
    home_type,
    peb_scores,
} from "../../constants/constant_lists";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import hasPermission from "../../utils/hasPermission";
import FilesDialog from "./filesDialog";
import DeleteFileDialog from "./deleteFileDialog";
import DeleteIcon from "@mui/icons-material/Delete";

let updated_value = {};

const EditDetails = ({
    investments,
    getInvestment,
    updateInvestment,
    setPropertyFiles,
}) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const [openEditFiles, setOpenEditFiles] = useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);
    const [fileNameToDelete, setFileNameToDelete] = useState(null);

    const [filesList, setFilesList] = useState([]);

    useEffect(() => {
        let invFound = investments.propreties?.find((elem) => elem.id === id);
        setFilesList(invFound?.media?.files || []);
    }, [investments]);

    let current_investment = investments.propreties?.filter(
        (elem) => elem.id === id
    );

    useEffect(() => {
        if (!current_investment || !current_investment.length)
            getInvestment(id);
    }, []);

    const isAPropertyFromUserAgency =
        current_investment &&
        current_investment[0] &&
        user.agencyId === current_investment[0].agencyId;

    if (!current_investment || !current_investment.length) return null;

    // todo : need to add a loading system with a loading icon ?
    // todo : test this only if is loaded ?
    // this will probably have a problem : get the property is a long task,
    // so this will probably navigate to homepage if current_investment is not yet loaded
    if (
        !current_investment.length ||
        !(
            (hasPermission(user, "write_myAgencyProperty") &&
                isAPropertyFromUserAgency) ||
            user.isAdmin
        )
    )
        return <Navigate to="/" />;

    current_investment = current_investment[0];
    updated_value = {};
    let key_to_map = editable_data;

    let prev = "";

    const handleSetPropertyFiles = (newFiles) => {
        setPropertyFiles(newFiles);
        setFilesList(newFiles);
    };

    const handleRemoveFile = (fileName) => {
        const newFiles = filesList.filter((elem) => elem.fileName !== fileName);
        setFilesList(newFiles);
        setPropertyFiles(newFiles);
    };

    const RenderInput = ({ id, value }) => {
        const [val, setVal] = useState(value);
        let end_id = id.substring(id.lastIndexOf(".") + 1);

        if (
            id === "subtype" ||
            id === "type" ||
            id.includes("condition") ||
            id.includes("epcScore")
        ) {
            let ok_values =
                id === "subtype" || id === "type"
                    ? Object.keys(home_type)
                    : Object.keys(good_state);
            if (id.includes("epcScore")) {
                ok_values = peb_scores;
            }
            return (
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        {end_id}
                    </InputLabel>
                    <Select
                        value={val === null ? "" : val}
                        label={end_id}
                        onChange={(e) => {
                            _.set(updated_value, _.toPath(id), e.target.value);
                            setVal(e.target.value);
                        }}
                    >
                        {ok_values.map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {elem}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else if (
            typeof value === "boolean" ||
            (value === null &&
                (end_id.includes("is") || end_id.includes("has")))
        ) {
            return (
                <div className="boolean-view">
                    <p>{end_id}: </p>
                    <Checkbox
                        onChangeCapture={(e) =>
                            _.set(
                                updated_value,
                                _.toPath(id),
                                _.get(updated_value, id) === true ? false : true
                            )
                        }
                        defaultChecked={value}
                    />
                </div>
            );
        } else if (id.includes(".orientation")) {
            return (
                <FormControl>
                    <InputLabel id="demo-simple-select-label">
                        Orientation
                    </InputLabel>
                    <Select
                        value={val === null ? "" : val}
                        label="Orientation"
                        onChange={(e) => {
                            _.set(updated_value, _.toPath(id), e.target.value);
                            setVal(e.target.value);
                        }}
                    >
                        {["NORTH", "SOUTH", "EAST", "WEST"].map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {elem}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else if (id == "description") {
            return (
                <div>
                    <CKEditor
                        editor={ClassicEditor}
                        data={value === null ? "" : value}
                        config={{
                            toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "undo",
                                "redo",
                            ],
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            _.set(updated_value, _.toPath(id), data);
                        }}
                    />
                </div>
            );
        } else if (id.includes("files")) {
            return (
                <div>
                    <div className="title">Files</div>
                    <Button
                        variant="outlined"
                        onClick={() => setOpenEditFiles(true)}
                    >
                        Upload Files
                    </Button>
                    <div className="fileList">
                        {value?.map((elem, id) => (
                            <div key={id} className="fileItem">
                                <div className="file-name">
                                    <a
                                        target="_blank"
                                        href={elem.downloadUrl}
                                        rel="noreferrer"
                                    >
                                        {elem.fileName}
                                    </a>
                                </div>
                                <div className="file-action">
                                    <IconButton
                                        onClick={() => {
                                            setFileNameToDelete(elem.fileName);
                                            setOpenConfirmDeleteDialog(true);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <TextField
                label={id.substring(id.lastIndexOf(".") + 1)}
                variant="outlined"
                onChange={(e) => {
                    _.set(updated_value, _.toPath(id), e.target.value);
                }}
                defaultValue={value === null ? "" : value}
                multiline={value && value.length > 20 ? true : false}
            />
        );
    };

    const save = () => {
        let need_bypass_potential =
            !updated_value?.bot_data?.potential_rent &&
            updated_value?.bot_data?.rent &&
            !current_investment.property_data_updated?.bot_data?.potential_rent;
        const updated = {
            ...current_investment,
            media: {
                ...current_investment.media,
                files: filesList,
            },
            property_data_updated: {
                ...current_investment.property_data_updated,
                ...updated_value,
            },
        };
        if (updated.property_data_updated?.bot_data?.rent === 0) {
            updated.property_data_updated.bot_data = {
                rent: 0,
                rdm: 0,
            };
        } else if (
            updated.property_data_updated?.bot_data?.rent ||
            updated.property_data_updated?.price
        ) {
            const rent = updated.property_data_updated?.bot_data?.rent
                ? updated.property_data_updated?.bot_data?.rent
                : updated.property_data.bot_data.rent;
            const price = updated.property_data_updated?.price
                ? updated.property_data_updated?.price
                : updated.property_data.price;
            let potential_rent_u = updated.property_data_updated?.bot_data
                ?.potential_rent
                ? updated.property_data_updated?.bot_data?.potential_rent
                : updated.property_data.bot_data.potential_rent;
            if (need_bypass_potential) potential_rent_u = parseInt(rent);
            updated.property_data_updated.bot_data = {
                rent: parseInt(rent),
                rdm: ((rent * 12) / (price * 1.125)) * 100,
                potential_rent: potential_rent_u,
            };
        }
        updateInvestment({ id: id, updated: updated });
        navigate("/details/" + id.toString());
    };

    return (
        <>
            {current_investment ? (
                <div className="edit-view">
                    <SaveButton type={1} save={() => save()} />
                    <div className="input-container">
                        {key_to_map.map((elem, id) => {
                            if (
                                elem.includes("media") ||
                                elem.includes("customers") ||
                                elem.includes("createdAt")
                            )
                                return;
                            let title = elem.substring(
                                0,
                                elem.lastIndexOf(".")
                            );
                            if (title === prev || prev.includes(title))
                                title = "";
                            else prev = title;
                            const padding_lvl = elem.split(".").length - 2;
                            const value = _.get(
                                current_investment.property_data,
                                elem
                            );
                            if (title !== "") {
                                return (
                                    <div className="input-block" key={id}>
                                        <span
                                            className={`${
                                                "zabcdefg"[padding_lvl]
                                            }`}
                                        >
                                            {title
                                                .substring(
                                                    title.lastIndexOf(".") + 1
                                                )
                                                .split("_")
                                                .join(" ")}
                                        </span>
                                        <div
                                            className={`input-elem ${
                                                "abcdefg"[padding_lvl]
                                            }`}
                                        >
                                            <RenderInput
                                                id={elem}
                                                value={value}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div
                                    key={id}
                                    className={`input-elem ${
                                        "abcdefg"[padding_lvl]
                                    }`}
                                >
                                    <RenderInput id={elem} value={value} />
                                </div>
                            );
                        })}
                        <div key={id} className={`input-elem`}>
                            <RenderInput id={"files"} value={filesList || []} />
                        </div>
                    </div>
                </div>
            ) : null}
            <FilesDialog
                open={openEditFiles}
                onClose={() => setOpenEditFiles(false)}
                setPropertyFiles={handleSetPropertyFiles}
            />
            <DeleteFileDialog
                open={openConfirmDeleteDialog}
                onClose={() => {
                    setOpenConfirmDeleteDialog(false);
                    setFileNameToDelete(null);
                }}
                fileName={fileNameToDelete}
                handleRemoveFile={handleRemoveFile}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);
