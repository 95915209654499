import React from "react";
import { NumericFormat } from "react-number-format";

import { InputAdornment, TextField } from "@mui/material";

const CustomTextField = (props) => {
    <TextField />;
};

const YieldCalculatorInput = ({
    value,
    setValue,
    className,
    label,
    id,
    prefix,
    disabled,
    showNotCommunicated = false,
}) => {
    const adjustedLabel =
        showNotCommunicated && value === 0
            ? `${label} (non disponible)`
            : label;

    const inputProps = prefix
        ? {
              startAdornment: (
                  <InputAdornment position="start">{prefix}</InputAdornment>
              ),
          }
        : {};

    return (
        <NumericFormat
            className={className}
            label={adjustedLabel}
            id={id}
            value={value} // controlled component
            onValueChange={(values) => {
                const { value } = values;
                setValue(parseFloat(value ? value : "0"));
            }}
            customInput={TextField}
            error={id == "annual-charges-rc" && value == 0 ? true : false}
            allowNegative={false}
            allowedDecimalSeparators={[",", "."]}
            decimalSeparator={","}
            thousandSeparator={"."}
            decimalScale={2}
            fixedDecimalScale={false}
            sx={{ width: "10.5rem" }}
            InputProps={inputProps}
            disabled={disabled}
        />
    );
};

export default YieldCalculatorInput;
