import settings from "../settings";

const getPostalCodeFromCoordinates = async (lat, lng) => {
    const apiKey = settings.googleMapsApiKey;
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${apiKey}`;

    try {
        const response = await fetch(geocodingUrl);
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
            return data.results[0].address_components.find((component) =>
                component.types.includes("postal_code")
            )?.long_name;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error with Geocoding request :", error);
        return null;
    }
};

export default getPostalCodeFromCoordinates;
