import React, { createContext, useReducer } from "react";

const initialState = {
    showModal: false,
    text: "",
    title: "",
    buttonText: "",
    buttonLink: "",
    showButton: false,
    yesButtonText: "",
    noButtonText: "",
    showYesButton: false,
    showNoButton: false,
    confirm: null,
    isEmail: false,
    userEmail: "",
    sendButtonText: "",
    sendAddress: "",
    sendData: {},
    showCloseButton: false,
    closeButtonText: "",
    actionButtonText: "",
    actionButtonOnClick: () => {},
    showActionButton: false,
    showCustomContent: false,
    customContent: null,
};

function modalReducer(state, action) {
    switch (action.type) {
        case "openModal":
            return { ...state, ...action.payload, showModal: true };
        case "closeModal":
            const { confirm } = state;
            return { ...initialState, confirm };
        case "confirm":
            return { ...state, confirm: true };
        case "decline":
            return { ...state, confirm: false };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
}

const ModalContext = createContext();

function ModalProvider({ children }) {
    const [state, dispatch] = useReducer(modalReducer, initialState);

    return (
        <ModalContext.Provider value={{ state, dispatch }}>
            {children}
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalProvider };
