import { favoritesMethods } from "./slices/favoritesSlice";
import { frezedPropertiesMethods } from "./slices/freezedPropertiesSlice";
import { investmentsMethods } from "./slices/investments";
import { appSettingsMethods } from "./slices/appSettingsSlice";
import { agencyPropertiesMethods } from "./slices/agencyPropertiesSlice";


export const mapDispatchToProps = {
    ...investmentsMethods,
    ...favoritesMethods,
    ...frezedPropertiesMethods,
    ...appSettingsMethods,
    ...agencyPropertiesMethods,
}

export const mapStateToProps = state => ({
    investments: state.investments,
    modal: state.modal,
    favorites: state.favorites,
    freezedProperties: state.freezedProperties,
    agencyProperties: state.agencyProperties,
    // appSettings: state.appSettings,
});

export const settingsStateToProps = state => ({
    appSettings: state.appSettings,
});