import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const freezeProperty = createAsyncThunk(
    "freezedProperties/freezeProperty",
    async (propertyId) => {
        // console.log('freeze time');
        const response = await axios.put(`/freeze-property/${propertyId}`);
        // console.log('freeze response : ', response);
        return response.data;
    }
)

export const getFreezedProperties = createAsyncThunk(
    "freezedProperties/getFreezedProperties",
    async () => {
        const response = await axios.get("/me/freezed-properties");

        // console.log('freezed properties : ', response.data);

        return response.data;
    }
);

const frezedPropertiesSlice = createSlice({
    name: "freezedProperties",
    initialState: {
        isLoading: false,
        error: null,
        freezed: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(freezeProperty.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(freezeProperty.fulfilled, (state, action) => {
                state.isLoading = false;
                state.freezed = action.payload;
            })
            .addCase(freezeProperty.rejected, (state, action) => {
                // console.log('payload : ', action.error);
                // for unknow reason, can't catch the message error from back.
                // the message from browser say payload too large and axios error is 403 forbiden...
                state.isLoading = false;
                // ERR_BAD_REQUEST hotfix is use this but the best if use the sended message from back
                state.error = action.error.code;
            })
            .addCase(getFreezedProperties.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFreezedProperties.fulfilled, (state, action) => {
                state.isLoading = false;
                state.freezed = action.payload;
            })
            .addCase(getFreezedProperties.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    },
});

export const frezedPropertiesMethods = {
    getFreezedProperties,
    freezeProperty
}


export default frezedPropertiesSlice;
