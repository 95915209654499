import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import useGoogleMaps from "../../hooks/useGooglleMaps/useGoogleMaps";

Geocode.setApiKey("AIzaSyBwRBPcTL6_4MUGWBSqql-SsJVNVNUM_E0");

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 69px - 20px - 77px)",
    position: "relative",
};

const Map = ({ center, location }) => {
    const { isGoogleLoaded } = useGoogleMaps();

    const [realCenter, setRealCenter] = useState(center);

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        map.setZoom(14);

        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        if (center.lat === 0 && center.lng === 0) {
            Geocode.fromAddress(`${location.country}, ${location.locality}`)
                .then((response) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setRealCenter({
                        lat: lat,
                        lng: lng,
                    });
                })
                .catch((e) => console.log(e));
        } else {
            setRealCenter(center);
        }
    }, [center, location]);

    return isGoogleLoaded && (realCenter.lat !== 0 || realCenter.lng !== 0) ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={realCenter}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={center} />
        </GoogleMap>
    ) : (
        <></>
    );
};

export default React.memo(Map);
