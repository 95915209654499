import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from 'react'
import "./style.scss";
import axios from "../../services/axios";
import { useParams } from 'react-router-dom';
import { toBase64 } from '../../helpers/transform';

const FilesDialog = ({open, onClose, setPropertyFiles}) => {

    const [files, setFiles] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const { id } = useParams();

    const handleChooseFiles = async (e) => {
        if(e?.target?.files?.length === 0) return
        let files = [];
        for(let i = 0; i < e.target.files.length; i++){
            const newFile = {
                fileName: e.target.files[i].name,
                file: await toBase64(e.target.files[i],false),
                type: e.target.files[i].type,
            }
            files.push(newFile)
        }
        setFiles(files)
    }

    const handleSaveFiles = () => {
        setLoading(true)
        let payload = {
            files
        };
        axios
            .post(`/upload-files/${id}`, payload)
            .then((res) => {
                setPropertyFiles(res.data)
                onClose();
                setLoading(false)
                setFiles([])
            }) 
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle>Upload Files</DialogTitle>
            <DialogContent>
                <div className='dialog-container'>
                    <TextField 
                        label={"Files"}
                        type="file"
                        inputProps={{
                            multiple: true,
                            //file types
                            accept: "image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt",
                        }}
                        onChange={(e)=>handleChooseFiles(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div className='files-list'>
                        {files.map((file, id) => (
                            <div key={id} className='file-item'>
                                <p>{file.fileName}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={()=>onClose()}>Cancel</Button>
                <Button disabled={loading} onClick={()=>handleSaveFiles()}>{loading ? "Uploading ... " : "Save"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilesDialog