import React from "react";
// import { Tooltip } from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";
import no_pics from "../../../assets/no_pics.svg";
// import { home_type } from "../../../constants/constant_lists";
import formatNumber from "../../../helpers/formatNumber";
import Countdown from "../../counter/Counter";
// import TrendingDownIcon from "@mui/icons-material/TrendingDown";
// import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
// import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";

/**
 * !!!!!!!!!!!
 * Tooltype here make crash app for unknow reason when user is not logged.
 * !!!!!!!!!!!
 */

const PropertyContentFreezed = ({
    views,
    visible,
    rent,
    rdm,
    certificats,
    pebLetter,
    location,
    netHabitableSurface,
    price,
    subtype,
    pictures,
    creationDate,
    spotlight,
    flagPriceDecrease,
    flagFastSale,
    flagAlreadySold,
    isViewsIsVisible,
    opt_weekday,
    freezedByMe,
    freezedUntil,
}) => {
    return (
        <article
            className={`property-element__content ${
                visible === false && "admin-only"
            }`}
        >
            <header className="property-element__content__image-container">
                <img
                    className={"property-element__image"}
                    alt="la propriété"
                    src={pictures[0]?.largeUrl ? pictures[0].largeUrl : no_pics}
                    // add a blur to image we have not yet blured images to display
                    style={{ filter: "blur(15px)" }}
                />

                <div className="property-element__rent">
                    {rent ? `${formatNumber(rent)}€` : "inconnu"}/mois
                </div>

                {isViewsIsVisible(views) && (
                    <div className="property-element__views">{views} vues</div>
                )}

                {/* <div className="property-element__peb">
                    {certificats?.epcScore !== null && (
                        <img
                            alt={`PEB ${pebLetter}`}
                            className={
                                certificats?.epcScore !== "" ? "large" : ""
                            }
                            src={
                                certificats?.epcScore !== ""
                                    ? `https://static.immoweb.be/pics/peb/peb_${pebLetter}.png`
                                    : "https://static.immoweb.be/fr/pics/certif_nondispo_1.gif"
                            }
                        />
                    )}
                </div> */}
            </header>

            <main className="property-element__infos">
                {/* <div className="property-element__infos__header">
                    <div className="property-element__housing-type">
                        {home_type[subtype] ? home_type[subtype] : "Autre"}
                    </div>

                    <div className="property-element__location">
                        {location.locality}
                        {netHabitableSurface && ` - ${netHabitableSurface}m²`}
                    </div>

                    <div className="property-element__price">
                        {formatNumber(price)}€
                    </div>
                </div> */}

                <div>
                    <div>
                        Cette annonce est "freeze" par un autre utilisateur
                        pendant encore <Countdown timestamp={freezedUntil} />
                        {". "}
                        <a
                            target="_blank"
                            href="https://www.immovestor.be/#comp-ld5qeotk" rel="noreferrer"
                        >
                            En apprendre plus sur le freeze
                        </a>
                    </div>
                </div>

                <div className="property-element__infos__main">
                    <div className="property-element__gross-yield">
                        <div className="property-element__gross-yield__percent">
                            {rent
                                ? `${formatNumber(rdm ? rdm : 0, 1)}%`
                                : "inconnu"}
                        </div>

                        <div className="property-element__gross-yield__text">
                            Rendement brut
                            {/* <Tooltip title="Le rendement brut en pourcent est calculé de la manière suivante: loyer mensuel * 12 / (prix * 1.125) * 100">
                                <InfoIcon
                                    sx={{
                                        fontSize: "1rem",
                                        fill: "var(--color-secondary)",
                                        marginLeft: ".2em",
                                        marginBottom: "-.1em",
                                    }}
                                />
                            </Tooltip> */}
                        </div>
                    </div>

                    <div className="property-element__square-meter-price">
                        {netHabitableSurface && (
                            <>
                                <span>Prix au m²</span>
                                <span>{`${formatNumber(
                                    price / netHabitableSurface
                                )}€`}</span>
                            </>
                        )}
                    </div>
                </div>

                <div className="property-element__infos__footer">
                    <div className="property-element__publication-date">
                        Date de publication :{" "}
                        {new Date(creationDate).toLocaleDateString(
                            `fr-FR`,
                            opt_weekday
                        )}
                    </div>
                </div>

                {/* {
						elem.property_data.building?.condition ?
						<p>Etat du bien: {good_state[elem.property_data.building?.condition]}</p> : null
					} */}
                {/* {
						new Date(elem.publication.lastModificationDate).toDateString() !== new Date(elem.publication.creationDate).toDateString() ?
						<p>Dernière modification: {new Date(elem.publication.lastModificationDate).toLocaleDateString(`fr-FR`, opt_weekday)}</p> : null
					} */}
            </main>
            {/* {spotlight && (
                <div className="spotlight">
                    <StarIcon />
                </div>
            )} */}
        </article>
    );
};

export default PropertyContentFreezed;
