

export const contact_email = `Bonjour,

J’ai découvert votre bien sur Immovestor et ce dernier m'intéresse.

Pouvez-vous me fournir davantage d'informations sur celui-ci afin de convenir d'une éventuelle visite ?

Je vous remercie pour votre attention.

Bien à vous`