import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import Pagination from "../../pagination/Pagination";
import SearchContext, { filter_sort } from "../../../contexts/SearchContext";
import AuthContext from "../../../contexts/AuthContext";
import PropertiesList from "../../propertiesList/PropertiesList";
import Loading from "../../main/loading/Loading";

import empty_icon from "../../../assets/empty_search.svg";
import MapIcon from "@mui/icons-material/Map";

import CategorySelection from "../../categorySelection/CategorySelection";

import "./listView.scss";
import { Button, MenuItem, Select } from "@mui/material";
import ImmovestorButton from "../../immovestorButton/ImmovestorButton";

const ListView = ({
    investments,
    fetchInvestments,
    filteredInvestments,
    currentPage,
    set_map,
}) => {
    const { user } = useContext(AuthContext);
    const { setActualCategory, actualCategory } = useContext(SearchContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [spotlight, setSpotlight] = useState(filter_sort.filter.spotlight);

    currentPage += 1;

    const max_page = investments.max_page + 1;

    useEffect(() => {
        if (!investments.loading) {
            setActualCategory(filter_sort.filter.category);
        }
    }, [investments]);

    const navigate_fetch = () => {
        if (!user.permissions.read_spotlight)
            filter_sort.filter.spotlight = false;

        fetchInvestments(filter_sort);
    };

    const sortInvests = (v) => {
        filter_sort.sort = v;
        filter_sort.page = 0;
        navigate_fetch();
    };

    const changePage = (e) => {
        filter_sort.page = e - 1;
        navigate_fetch();
        window.scrollTo(0, 0);
    };

    const handleSpotlightButton = () => {
        filter_sort.filter.spotlight = !spotlight;
        filter_sort.page = 0;
        setSpotlight(!spotlight);

        fetchInvestments(filter_sort);
    };

    const switchCategory = (category, no_need_fetch = false) => {
        if (no_need_fetch) {
            setActualCategory(category);
            return;
        }
        if (filter_sort.filter.category === category) return;

        filter_sort.filter.category = category;
        filter_sort.page = 0;
        //setActualCategory(category);
        fetchInvestments(filter_sort);
    };

    const RenderList = () => {
        if (!filteredInvestments) {
            return <Loading />;
        }

        if (filteredInvestments.length === 0) {
            return (
                <div className="property-selection__empty">
                    <img
                        className="property-selection__empty__image"
                        src={empty_icon}
                        alt={"pas de résultat"}
                    />
                    <div>
                        <h3>Désolé. Aucun résultat trouvé.</h3>
                        <p>
                            Il n'y a aucun résultat pour ces filtres. Changez
                            vos <Link to="/filters">filtres</Link> et réessayez.
                        </p>
                    </div>
                </div>
            );
        }

        return (
            <PropertiesList
                properties={filteredInvestments}
                loading={investments.loading}
                type={actualCategory}
            />
        );
    };

    return (
        <div className="property-selection">
            {/*<CategorySelection switchCategory={switchCategory} />*/}
            <div className="property-selection__tab">
                {filteredInvestments && filteredInvestments.length ? (
                    <div className="property-selection__pagination-sort-container">
                        <div className={"property-selection__tools"}>
                            <div onClick={set_map} className="map-button">
                                <MapIcon />
                                <span>Map</span>
                            </div>

                            <div className="sort_div">
                                <p>Trier par</p>
                                <Select
                                    labelId={"sort-select"}
                                    className="sort-select"
                                    defaultValue={
                                        searchParams.get("sort")
                                            ? searchParams.get("sort")
                                            : "pert"
                                    }
                                    onChange={(e) =>
                                        sortInvests(e.target.value)
                                    }
                                >
                                    <MenuItem value={"pert"}>
                                        Plus récent
                                    </MenuItem>
                                    <MenuItem value={"price_up"}>
                                        Le moins cher
                                    </MenuItem>
                                    <MenuItem value={"price_down"}>
                                        Le plus cher
                                    </MenuItem>
                                    <MenuItem value={"rent"}>
                                        Le plus grand loyer
                                    </MenuItem>
                                    <MenuItem value={"rdt"}>
                                        Le plus grand rendement
                                    </MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={"property-selection__left-container"}>
                            <Pagination
                                current_page={currentPage}
                                number_of_page={max_page}
                                change_page={(e) => {
                                    changePage(e);
                                }}
                            />

                            <ImmovestorButton
                                // className={`our-selection ${
                                //     !spotlight ? "" : "selected"
                                // }`}
                                className={
                                    "property-selection__spotlight-button"
                                }
                                variant="outlined"
                                onClick={handleSpotlightButton}
                                outlined
                            >
                                {spotlight ? "Tout voir" : "Notre sélection"}
                            </ImmovestorButton>
                        </div>
                    </div>
                ) : null}

                <RenderList />

                {filteredInvestments && filteredInvestments.length ? (
                    <Pagination
                        current_page={currentPage}
                        number_of_page={max_page}
                        change_page={(e) => {
                            changePage(e);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
