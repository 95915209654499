/**
 * Formats a number as a string with a thousand separator and a decimal separator (if the number has decimal places).
 *
 * @param {null|number|string} number - The number to format.
 * @param {number} [decimalPlaces=0] - The number of decimal places to include in the formatted number. Default is 0.
 * @returns {string} A string representing the formatted number.
 *
 * @example
 * formatNumber(102345.567, 2); // Returns "102.345,57"
 * formatNumber(1234.54, 0); // Returns "1.234"
 * formatNumber(1234.54, 3); // Returns "1.234,540"
 * formatNumber(1909898495, 2); // Returns "1.909.898.495"
 * formatNumber(12457, 2); // Returns "12.457"
 *
 * @notes
 * If decimalPlaces is equal to or less than zero, no decimal places will be included in the formatted number.
 */
const formatNumber = (number, decimalPlaces = 0) => {
    const isNegative = number < 0;
    // convert number to number type if it is string and get absolute value
    number = typeof number === 'string' ? parseFloat(number) : Math.abs(number);

    // + at start is used to have a result as number instead of string.
    const roundedNumber = +number.toFixed(decimalPlaces);

    const integerPart = Math.floor(roundedNumber).toString();

    let formattedNumber = "";

    const integerLength = integerPart.length;

    for (let i = 0; i < integerLength; i++) {
        if (i !== 0 && i % 3 === 0) {
            formattedNumber = "." + formattedNumber;
        }
        formattedNumber = integerPart[integerPart.length - i - 1] + formattedNumber;
    }

    if (decimalPlaces > 0) {
        const decimalPart = (number % 1).toFixed(decimalPlaces).substring(2);
        if (decimalPart !== "0".repeat(decimalPlaces)) {
            formattedNumber += "," + decimalPart;
        }
    }

    // Add negative sign back if the original number was negative
    return isNegative ? "-" + formattedNumber : formattedNumber;
};


export default formatNumber;

/**
 * use this export instead of export default to test with formatNumber.test.js
 */
// module.exports = formatNumber;
