import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import "./myAgencyProperties.scss";
import PropertiesList from "../../components/propertiesList/PropertiesList";

/**
 * No check for access permissions since there is checked on App.jsx
 */
const MyAgencyProperties = ({ agencyProperties, getMyAgencyProperties }) => {
    const { user } = useContext(AuthContext);

    const isLoading = agencyProperties.isLoading;

    const properties = agencyProperties.properties
        ? agencyProperties.properties
        : [];

    useEffect(() => {
        getMyAgencyProperties();
    }, [user]);

    return (
        <div className="my-agency-properties-page">
            <h1 className="my-agency-properties-page__title">
                Les annonces de votre agence.
            </h1>

            <PropertiesList properties={properties} loading={isLoading} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAgencyProperties);
