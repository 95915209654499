import React from "react";
import "./pagination.scss";

const Pagination = ({ number_of_page, current_page, change_page }) => {
    const RenderButton = ({ value }) => {
        if (value === "...")
            return (
                <div className="pagination_button pagination_dots">
                    <p>...</p>
                </div>
            );
        if (value === "prev" || value === "next")
            return (
                <div
                    onClick={() =>
                        change_page(
                            value === "prev"
                                ? parseInt(current_page) - 1
                                : parseInt(current_page) + 1
                        )
                    }
                    className={`pagination_button${
                        value === current_page ? " full" : ""
                    }`}
                >
                    {value === "prev" ? (
                        <i className="fas fa-chevron-left"></i>
                    ) : (
                        <i className="fas fa-chevron-right"></i>
                    )}
                </div>
            );
        return (
            <div
                onClick={() => change_page(value)}
                className={`pagination_button${
                    value === current_page ? " full" : ""
                }`}
            >
                {<p>{value}</p>}
            </div>
        );
    };
    return (
        <div className="pagination_container">
            {current_page !== 1 ? <RenderButton value="prev" /> : null}
            {current_page !== 1 ? <RenderButton value={1} /> : null}

            {current_page > 2 ? <RenderButton value={"..."} /> : null}

            {current_page > 2 ? (
                <RenderButton value={current_page - 1} />
            ) : null}
            {current_page > 0 ? <RenderButton value={current_page} /> : null}
            {current_page < number_of_page - 1 ? (
                <RenderButton value={current_page + 1} />
            ) : null}

            {current_page < number_of_page - 2 ? (
                <RenderButton value={"..."} />
            ) : null}

            {current_page !== number_of_page ? (
                <RenderButton value={number_of_page} />
            ) : null}
            {current_page !== number_of_page ? (
                <RenderButton value="next" />
            ) : null}
        </div>
    );
};

export default Pagination;
