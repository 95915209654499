import React, { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";

import "./style.scss";
import { useNavigate } from "react-router-dom";

const StripePricing = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const environment = process.env.REACT_APP_ENVIRONMENT;

    /**
     * Stripe's pricing table id is different in prod and dev.
     */
    const pricingTableId =
        environment === "prod"
            ? "prctbl_1NOgNrBw1cfdvcQ4ZfmBXZ8p"
            : "prctbl_1N6Dt9Bw1cfdvcQ4cXirHFq7";

    useEffect(() => {
        if (!user.type) {
            navigate("/register");
        }
    }, [user]);

    return (
        <div className="stripe">
            <h1>Nos tarifs </h1>

            <stripe-pricing-table
                pricing-table-id={pricingTableId}
                customer-email={user.email}
                publishable-key={publishableKey}
                client-reference-id={user.id}
            ></stripe-pricing-table>
        </div>
    );
};

export default StripePricing;
