import React, { useEffect, useState } from 'react'
import "./styles.scss"

let lastScrollTop = 0;
let yet_click = false;

const titles = [
	"Réinitialiser les filtres",
	"Réinitialiser les critères",
	"Modifier ce document",
	"Réinitialiser les champs"
];


const icons = [
	"fa-trash",
	"fa-trash",
	"fa-trash",
	"fa-trash"
];




const ClearButton = ({save, type=0}) => {

    const [openButton, setOpenButton] = useState(false);
	
	useEffect(() => {
		const open_bot = () => {
			let st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop && window.pageYOffset > 30) {
				setOpenButton(true);
			} else {
				setOpenButton(false);
			}
			lastScrollTop = st <= 0 ? 0 : st;
		}
		window.addEventListener("scroll", open_bot);
	
		return () => {
			window.removeEventListener("scroll", open_bot);
		}
	}, [])

	return (
		<div onClick={() => save()} className={`bot_button clear${openButton ? " open" : ""} ${"aac"[type]}`}>
            <div className='unwrap'>
                <span>{titles[type]}</span>
                <div className='icon_box'>
                    <i className={`fas ${icons[type]}`}></i>
                </div>
            </div>
        </div>
	)
}

export default ClearButton