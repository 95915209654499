import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import axios from "../../services/axios";
import { useParams } from 'react-router-dom';

const DeleteFileDialog = ({open, onClose, fileName, handleRemoveFile}) => {

    const { id } = useParams();

    const [loading, setLoading] = React.useState(false)

    const handleDelete = () => {
        setLoading(true)
        axios.delete(`/remove-file/${id}/${fileName}`).then(()=>{
            onClose();
            handleRemoveFile(fileName)
            setLoading(false)
        })
    }

    return (
        <Dialog
            open={open}
        >
            <DialogTitle>Delete File</DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
                <Button disbaled={loading} onClick={()=>onClose()}>Cancel</Button>
                <Button disabled={loading} onClick={()=>handleDelete()} variant="contained" color="error">{loading ? "Deleting ..." : "Delete"}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteFileDialog