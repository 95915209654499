import getBaseUrl from "../helpers/getBaseUrl";

export const youNeedAplanToSeeHighYieldModalSettings = {
    text: "La revue des biens offrant un rendement supérieur à 5% est réservée aux abonnés Immovestor. Débloquez toutes les fonctionnalités exclusives de notre outil dédié aux investisseurs immobiliers, en vous abonnant dès maintenant ! Ne passez plus à coté de nouvelles pépites…",
    title: "Souscrire à un plan",
    showButton: true,
};

export const youNeedAplanToSeeFlatsharingdModalSettings = {
    text: "La revue des biens de colocation potentielle est réservée aux abonnés Immovestor. Débloquez toutes les fonctionnalités exclusives de notre outil dédié aux investisseurs immobiliers, en vous abonnant dès maintenant ! Ne passez plus à coté de nouvelles pépites…",
    title: "Souscrire à un plan",
    showButton: true,
};

export const youNeedAplanToSeeSpotlight = {
    text: "La revue de notre sélection est réservée aux abonnés Immovestor. Débloquez toutes les fonctionnalités exclusives de notre outil dédié aux investisseurs immobiliers, en vous abonnant dès maintenant ! Ne passez plus à coté de nouvelles pépites…",
    title: "Souscrire à un plan",
    showButton: true,
};

export const youNeedFreezesModlaSettings = {
    text: "Malheureusement, vous n'avez plus de freezes disponibles.",
    title: "Plus de freezes",
};

export const areYouSureToFreezeModalSettings = {
    title: "Bloquer une annonce.",
    showButton: false,
    showYesButton: true,
    showNoButton: true,
};

export const needAssistanceModalSettings = {
    title: "Demander de l'assistance",
    text: "Demander de l'assistance à l'administrateur du site.",
    sendAddress: "/email/assistance",
    showButton: false,
    isEmail: true,
};

export const tooMuchConcurrentFrozenModalSettings = {
    title: "Trop d'annonces bloquées.",
    showButton: false,
    showCloseButton: true,
    closeButtonText: "OK",
};
