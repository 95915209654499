import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { pebs } from "../../constants/constant_lists";
import axios from "../../services/axios";

let pending = [];
const return_keys = (dict, prev) => {
    if (typeof dict === "object" && dict) {
        Object.keys(dict).map((key) => {
            if (typeof dict[key] === "object" && dict[key]) {
                return_keys(dict[key], prev + "." + key);
            } else {
                pending.push(prev + "." + key);
            }
        });
    }
};

export const updated_item = (item) => {
    pending = [];
    return_keys(item.property_data_updated, "");
    pending = pending.map((elem) => elem.substring(1));
    pending.map((e) => {
        _.set(
            item.property_data,
            _.toPath(e),
            _.get(item.property_data_updated, e)
        );
    });
    return item;
};

export const fetchInvestments = createAsyncThunk(
    "investments/fetchInvestments",

    async (filters) => {
        let updated_filters = null;
        if (filters) {
            updated_filters = { ...filters, filter: { ...filters.filter } };

            if (updated_filters.filter.epc_scores !== "") {
                let id = pebs.indexOf(updated_filters.filter.epc_scores);
                const list = [];
                for (let index = 1; index <= id; index++) {
                    list.push(pebs[index]);
                }
                updated_filters.filter.epc_scores = list.join("|");
            }
        }
        const response = await axios.post(
            "/all-propreties",
            updated_filters ? updated_filters : {}
        );

        // console.log("all-properties response: ", response);

        return [response.data, { ...filters, filter: { ...filters.filter } }];
    }
);

export const getInvestment = createAsyncThunk(
    "investments/getInvestment",
    async (id) => {
        const response = await axios.get("/propreties/" + id);
        return response.data;
    }
);

export const updateInvestment = createAsyncThunk(
    "investments/updateInvestment",
    async ({ id, updated }) => {
        const response = await axios.put("/propreties/" + id, updated);
        return response.data;
    }
);

const investmentsSlice = createSlice({
    name: "investments",
    initialState: {
        count_per_pages: 0,
        from_cache: false,
        max_page: 0,
        propreties_count: 0,
        propreties: null,
        all_places: [],
        last_filters: "",
        loading: false,
        first_load: true,
    },
    reducers: {
        setPropertyFiles: (state, action) => {
            const { id, files } = action.payload;
            const property = state.propreties.find((e) => e.id === id);
            if (property) {
                property.media.files = files;
            }
        },
    },
    extraReducers: {
        [fetchInvestments.pending]: (state, action) => {
            return { ...state, loading: true, first_load: false };
        },
        [fetchInvestments.fulfilled]: (state, action) => {
            let [prop, filters] = action.payload;
            const properties = prop.propreties.map((elem) => {
                return updated_item(elem);
            });
            filters.filter["zip_codes"] = [];
            prop.last_filters = JSON.stringify(filters);
            prop.loading = false;
            prop.first_load = false;
            if (prop.propreties && prop.propreties.length) {
                const to_add = [];
                for (let index = 0; index < properties.length; index++) {
                    const property = properties[index];
                    if (
                        prop.propreties.filter((e) => e.id == property.id)
                            .length == 0
                    ) {
                        to_add.push(property);
                    }
                }
                prop.propreties = [...prop.propreties, ...to_add];
            } else {
                prop.propreties = properties;
            }
            return prop;
        },
        [getInvestment.fulfilled]: (state, action) => {
            const exist = state.propreties
                ? state.propreties.filter(
                      (elem) => elem.id === action.payload.id
                  )
                : null;
            if (exist && exist.length) {
                state.propreties.splice(
                    state.propreties.indexOf(exist[0]),
                    1,
                    updated_item(action.payload)
                );
            } else if (state.propreties)
                state.propreties.push(updated_item(action.payload));
            else state.propreties = [updated_item(action.payload)];
        },
        [updateInvestment.fulfilled]: (state, action) => {
            const updated_elem = action.payload;
            const past_elem = state.propreties.filter(
                (elem) => elem.id === updated_elem.id
            );
            if (past_elem.length === 0) {
                state.propreties.push(updated_item(updated_elem));
            } else {
                state.propreties.splice(
                    state.propreties.indexOf(past_elem[0]),
                    1,
                    updated_item(updated_elem)
                );
            }
        },
    },
});

export const investmentsMethods = {
    fetchInvestments,
    getInvestment,
    updateInvestment,
    ...investmentsSlice.actions,
};

export default investmentsSlice;
