import React, { useState, useEffect, useRef} from 'react'
import "./advancedFilters.scss"
import { home_type, good_state, pebs } from '../../constants/constant_lists';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../store/dispatcher';

import white_cross from "../../assets/white_cross.svg"

const AdvancedFilters = ({fetchInvestments, investments, handleCloseAdvanced, filter_sort}) => {

    const [minPrice, setminPrice] = useState(0);
    const [maxPrice, setmaxPrice] = useState(0);
    const [minYield, setminYield] = useState(0);
    const [maxYield, setmaxYield] = useState(0);
    const [minRent, setminRent] = useState(0);
    const [maxRent, setmaxRent] = useState(0);

    const first_render = useRef(true);

	const [selectedHouseType, setSelectedHouseType] = useState("Tous");
	const [selectedHouseCondition, setSelectedHouseCondition] = useState("Tous");
	const [selectedHousePeb, setSelectedHousePeb] = useState("Tous");

    useEffect(() => {
		setminPrice(filter_sort.filter.price_min);
		setmaxPrice(filter_sort.filter.price_max);
		setminYield(filter_sort.filter.rdt_min);
		setmaxYield(filter_sort.filter.rdt_max);
		setminRent(filter_sort.filter.rent_min);
		setmaxRent(filter_sort.filter.rent_max);
		setSelectedHouseType(filter_sort.filter.place_type === "" ? "all" : filter_sort.filter.place_type);
		setSelectedHouseCondition(filter_sort.filter.conditions === "" ? "all" : filter_sort.filter.conditions);
		setSelectedHousePeb(filter_sort.filter.epc_scores === "" ? "Tous" : filter_sort.filter.epc_scores);
        setTimeout(() => {
            first_render.current = false;
        }, 200);
	}, [])

    const handleUpdate = () => {
		filter_sort.filter = {
			"price_min": minPrice,
			"price_max": maxPrice,
			"rdt_min": minYield,
			"rdt_max": maxYield,
			"rent_min": minRent,
			"rent_max": maxRent,
			"count_per_pages": 12,
			"place_type": selectedHouseType === "all" ? "" : selectedHouseType,
			"conditions": selectedHouseCondition === "all" ? "" : selectedHouseCondition,
			"epc_scores": selectedHousePeb === "Tous" ? "" : selectedHousePeb,
			"zip_codes": filter_sort.filter.zip_codes,
		};
        filter_sort.page = 0;
		fetchInvestments(filter_sort);
	}

    useEffect(() => {
        if (!first_render.current)
            handleUpdate();
    }, [minPrice, maxPrice, minYield, maxYield, minRent, maxRent, selectedHouseType,
        selectedHouseCondition,selectedHousePeb])

    const setSaveType = (type) => {
		filter_sort.filter.place_type = (type === "all" ? "" : type);
        filter_sort.page = 0;
		setSelectedHouseType(type);
		fetchInvestments(filter_sort);
	}

    const setSaveCondition = (condition) => {
		filter_sort.filter.conditions = (condition === "all" ? "" : condition);
        filter_sort.page = 0;
		setSelectedHouseCondition(condition);
		fetchInvestments(filter_sort);
	}

    const setSavePeb = (peb) => {
		filter_sort.filter.epc_scores = (peb === "all" ? "" : peb);
		setSelectedHousePeb(peb);
		fetchInvestments(filter_sort);
	}

	return (
		<div className="adv-filters-container">
            <div className='selectors'>
                <div className='type selector'>
                    <span className='filter-title'>Type de bien</span>
                    <select className='filter-dropdown' name="house_type" id="house_type" value={selectedHouseType}
                    onChange={(e) => setSaveType(e.target.value)}>
                        {
                            Object.keys(home_type).map((elem, index) => (
                                <option key={index} value={elem}>{home_type[elem]}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='condition selector'>
                    <span className='filter-title'>Etat du bien</span>
                    <select className='filter-dropdown' name="house_condition" id="house_condition" value={selectedHouseCondition}
                    onChange={(e) => setSaveCondition(e.target.value)}>
                        {
                            Object.keys(good_state).map((elem, index) => (
                                <option key={index} value={elem}>{good_state[elem]}</option>
                            ))
                        }
                    </select>
                </div>
                {/* <div className='budget selector'>
                    <span className='filter-title'>Budget</span>
                    <div className='filter-fields'>
                        <input type="number" placeholder='Min.'/>
                        <div className='sep' />
                        <input type="number" placeholder='Max.'/>
                    </div>
                </div> */}
                <div className='rent selector'>
                    <span className='filter-title'>Loyer</span>
                    <div className='filter-fields'>
                        <input onChange={(e) => setminRent(e.target.value)} type="number" placeholder='Min.'/>
                        <div className='sep' />
                        <input onChange={(e) => setmaxRent(e.target.value)} type="number" placeholder='Max.'/>
                    </div>
                </div>
                {/* <div className='peb selector'>
                    <span className='filter-title'>PEB</span>
                    <select className='filter-dropdown' name="house_peb" id="house_peb" value={selectedHousePeb}
                    onChange={(e) => setSavePeb(e.target.value)}>
                        {
                            pebs.map((elem, index) => (
                                <option key={index} value={elem}>{elem}</option>
                            ))
                        }
                    </select>
                </div> */}
            </div>
            {/* <button className='apply'>Appliquer les filtres <img alt="" src={black_arrow_right}/></button> */}
            <div className='close' onClick={handleCloseAdvanced}><img alt="" src={white_cross}/></div>
        </div>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilters)
