import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Button, TextField } from "@mui/material";
import axios from "../../../services/axios";
import { LoadingButton } from "@mui/lab";
import AuthContext from "../../../contexts/AuthContext";
import ToastContext from "../../../contexts/PopupContext";
import { TOAST_LVL } from "../../../constants/constant_lists";
import { contact_email } from "./email_value";
import { show_location } from "../../../functions/location";

const ContactModal = ({ visible, close, url, investment }) => {
    const { user } = useContext(AuthContext);
    const { setToast } = useContext(ToastContext);
    const [contactValue, setContactValue] = useState({
        phone: "",
        email: user.token !== "" ? user.email : "",
        first_name: user.token !== "" ? user.first_name : "",
        last_name: user.token !== "" ? user.last_name : "",
        message: contact_email,
    });
    const [loading, setLoading] = useState(false);

    const contact = investment.customers.length
        ? investment.customers[0]
        : null;

    const validate = async () => {
        setLoading(false);
        await axios.post("/email/contact", {
            phone: contactValue.phone,
            message: contactValue.message,
            first_name: contactValue.first_name,
            last_name: contactValue.last_name,
            email: contactValue.email,
            investment: {
                id: investment.id,
                url: `https://app.immovestor.be/details/${investment.id}`,
                base_url: investment.url,
                picture: investment.media.pictures.length
                    ? investment.media.pictures[0].largeUrl
                    : "",
                title: investment.property_data.title,
                price: investment.property_data.price,
                address: show_location(investment.property_data.location),
            },
            agency: {
                name: contact ? contact.name : "",
                phone: contact ? contact.phoneNumber : "",
                picture: contact ? contact.logo : "",
                website: contact ? contact.website : "",
                email: contact ? contact.email : "",
            },
            url: url,
        });
        setLoading(true);
        setContactValue({
            phone: "",
            email: user.token !== "" ? user.email : "",
            first_name: user.token !== "" ? user.first_name : "",
            last_name: user.token !== "" ? user.last_name : "",
            message: "",
        });
        setToast.current(
            TOAST_LVL.SUCCESS,
            "Formulaire envoyé",
            "Votre demande de renseignement à bien été envoyée."
        );
        setLoading(false);
        close();
    };

    useEffect(() => {
        if (
            user.token &&
            user.token !== "" &&
            user.email !== contactValue.email
        ) {
            setContactValue({ ...contactValue, email: user.email });
        }
    }, [user]);

    return (
        <Modal
            open={visible}
            onClose={close}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                minHeight: "80vh",
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="center-contact-form">
                <h2>Intéressé par ce bien ?</h2>
                <p>
                    Remplissez ce formulaire afin d'obtenir plus d'informations
                    sur ce bien
                </p>
                <form>
                    <div className="row">
                        <TextField
                            onChange={(e) =>
                                setContactValue({
                                    ...contactValue,
                                    last_name: e.target.value,
                                })
                            }
                            value={contactValue.last_name}
                            size="small"
                            variant="outlined"
                            label="Nom"
                            fullWidth
                        />
                        <TextField
                            onChange={(e) =>
                                setContactValue({
                                    ...contactValue,
                                    first_name: e.target.value,
                                })
                            }
                            value={contactValue.first_name}
                            size="small"
                            variant="outlined"
                            label="Prénom"
                            fullWidth
                        />
                    </div>
                    <TextField
                        onChange={(e) =>
                            setContactValue({
                                ...contactValue,
                                phone: e.target.value,
                            })
                        }
                        value={contactValue.phone}
                        size="small"
                        variant="outlined"
                        label="Numero de téléphone"
                        fullWidth
                    />
                    <TextField
                        onChange={(e) =>
                            setContactValue({
                                ...contactValue,
                                email: e.target.value,
                            })
                        }
                        value={contactValue.email}
                        size="small"
                        variant="outlined"
                        label="E-mail"
                        fullWidth
                    />
                    <TextField
                        onChange={(e) =>
                            setContactValue({
                                ...contactValue,
                                message: e.target.value,
                            })
                        }
                        value={contactValue.message}
                        minRows={3}
                        multiline={true}
                        size="small"
                        variant="outlined"
                        label="Email de contact"
                        fullWidth
                    />
                </form>
                <LoadingButton
                    loading={loading}
                    disabled={
                        contactValue.phone === "" ||
                        contactValue.message === "" ||
                        contactValue.first_name === "" ||
                        contactValue.last_name === ""
                    }
                    onClick={validate}
                    variant="contained"
                >
                    Envoyer
                </LoadingButton>
            </div>
        </Modal>
    );
};

export default ContactModal;
