import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import axios from "../../../services/axios";
import PropertyElem from "../../../components/home/property_elem/PropertyElem";
import AuthContext from "../../../contexts/AuthContext";
import Loading from "../../../components/main/loading/Loading";

const NewByCron = () => {
    const [alertSended, setAlertSended] = useState(false);
    const [loading, setLoading] = useState(false);

    const { addedByCron, setAddedByCron } = useContext(AuthContext);

    const setup = async () => {
        const ret = await axios
            .get("/new-by-cron")
            .then((e) => e.data)
            .catch((e) => null);
        if (ret) {
            setAddedByCron(ret);
        }
    };

    const handleSendAlerts = async () => {
        setLoading(true);
        const res = await axios
            .post("/daily-alert-manual", {})
            .then((e) => e.data)
            .catch((e) => null);
        if (res) {
            setAlertSended(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        setup();
    }, []);

    return (
        <section id="new-by-cron">
            <h1>Nouvelles annonces</h1>
            <div className="new-wrapper">
                {addedByCron.map((elem, id) => (
                    <PropertyElem
                        elem={elem}
                        key={id}
                        index={id}
                        loading={false}
                        ignoreLimit={true}
                    />
                ))}
                {addedByCron.length ? null : (
                    <div className="empty">
                        <p>
                            Toutes les nouvelles annonces ont été traitées. Bien
                            joué !!
                        </p>
                        {loading && <Loading />}
                        {!loading && (
                            <button
                                disabled={alertSended}
                                onClick={() => handleSendAlerts()}
                            >
                                {alertSended
                                    ? "Alertes envoyées"
                                    : "Envoyer les alertes"}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default NewByCron;
