const toBase64 = (file,prefix = true) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(prefix ? reader.result : reader.result.split(",")[1]);
    reader.onerror = reject;
  });
  
  module.exports = {
    toBase64,
  };
  