import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import { MenuItem, Select, TextField } from "@mui/material";

import { Autocomplete } from "@react-google-maps/api";
import getPostalCodeFromCoordinates from "../../../../utils/getPostalCodeFromCoordinates";
import settings from "../../../../settings";

const good_type = {
    HOUSE: "Maison",
    APARTMENT_BLOCK: "Immeuble de rapport",
    MIXED_USE_BUILDING: "Immeuble mixte",
    APARTMENT: "Appartement",
    GARAGE: "Garage",
    OFFICE: "Bureau",
    COMMERCIAL: "Commerce",
    INDUSTRY: "Industrie",
    OTHER: "Other",
};

const Filters = ({ loading, set_filters, isGoogleLoaded, setCoordinates }) => {
    const [filtersState, setFiltersState] = useState({
        goodType: "APARTMENT",
        time: 3,
    });

    const [nbrBedrooms, setNbrBedrooms] = useState("");
    const [peb, setPeb] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [surface, setSurface] = useState("");
    const [address, setAddress] = useState("");

    const autocompleteRef = useRef(null);

    const FilterBlock = ({ title, children }) => {
        return (
            <div className="filter-block">
                <div className="filter-block-header">
                    <h2>{title}</h2>
                </div>
                {children}
            </div>
        );
    };

    const set_main_filters = () => {
        const filters = {
            category: filtersState.goodType,
            nbr_bedrooms:
                nbrBedrooms.split(",")[0] == "" ? [] : nbrBedrooms.split(","),
            peb: peb.split(",")[0] == "" ? [] : peb.toUpperCase().split(","),
            postal_code: postalCode,
            surface: surface.split(",")[0] == "" ? [] : surface.split(","),
            time: filtersState.time,
        };

        set_filters(filters);
    };

    useEffect(() => {
        set_main_filters();
    }, [filtersState, postalCode, nbrBedrooms, peb, surface]);

    const handleKeyDown = (event, setter) => {
        if (event.key === "Enter") {
            setter(event.target.value);
        }
    };

    const onGoogleLoad = useCallback((autocomplete) => {
        autocompleteRef.current = autocomplete;
    }, []);

    const onPlaceChanged = useCallback(async () => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            const { lat, lng } = place.geometry.location;
            const newPostalCode = await getPostalCodeFromCoordinates(
                lat(),
                lng()
            );

            setCoordinates({ lat: lat(), lng: lng() });
            setAddress(place.formatted_address);
            setPostalCode(newPostalCode);
        }
    }, []);

    return (
        <div id="admin-market-data-filters">
            <FilterBlock title={"Type de bien*"}>
                <Select
                    size="small"
                    className="select-filter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filtersState.goodType}
                    onChange={(e) => {
                        setFiltersState({
                            ...filtersState,
                            goodType: e.target.value,
                        });
                    }}
                >
                    {Object.keys(good_type).map((elem, id) => (
                        <MenuItem key={id} value={elem}>
                            {good_type[elem]}
                        </MenuItem>
                    ))}
                </Select>
            </FilterBlock>

            {isGoogleLoaded ? (
                <FilterBlock title={"Adresse"}>
                    <Autocomplete
                        onLoad={onGoogleLoad}
                        onPlaceChanged={onPlaceChanged}
                        restrictions={{
                            country: settings.googleMapSearchCountries,
                        }}
                    >
                        <TextField
                            size="small"
                            label="ex: code postal, adresse, ..."
                            defaultValue={address}
                        />
                    </Autocomplete>
                </FilterBlock>
            ) : null}

            <FilterBlock title={"Nombre de chambres"}>
                <TextField
                    size="small"
                    label="ex: 2,4,5"
                    defaultValue={nbrBedrooms}
                    onBlur={(e) => setNbrBedrooms(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, setNbrBedrooms)}
                />
            </FilterBlock>

            <FilterBlock title={"Peb"}>
                <TextField
                    size="small"
                    label="ex: A++,A+,A"
                    defaultValue={peb}
                    onBlur={(e) => setPeb(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, setPeb)}
                />
            </FilterBlock>

            <FilterBlock title={"Surface"}>
                <div className="explain">
                    <p>
                        {
                            "1: <60, 2: 60<80, 3: 80<100, 4: 100<120, 5: 120<140, 6: 140<160, 7: 160<180, 8: 180<200, 9: >200"
                        }
                    </p>
                </div>
                <TextField
                    size="small"
                    label="ex: 4,5"
                    onBlur={(e) => setSurface(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, setSurface)}
                />
            </FilterBlock>

            <FilterBlock title={"Intervalle de temps"}>
                <Select
                    size="small"
                    className="select-filter"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filtersState.time}
                    onChange={(e) =>
                        setFiltersState({
                            ...filtersState,
                            time: e.target.value,
                        })
                    }
                >
                    {[
                        0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 15, 18, 21, 24, 30, 36,
                    ].map((elem, id) => (
                        <MenuItem key={id} value={elem}>
                            {elem} mois
                        </MenuItem>
                    ))}
                </Select>
            </FilterBlock>
        </div>
    );
};

export default Filters;
