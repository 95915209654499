import React, { useState } from "react";
import { complete_data } from "../../../functions/complete_data";
import DemographicData from "../../demographic/DemographicData";
import Map from "../../map/Map";
import DetailsTop from "../details-top/DetailsTop";

import "./detailsWeb.scss";
import DetailsPrice from "../details-price/DetailsPrice";
import DetailsDescription from "../details-description/DetailsDescription";
import formatNumber from "../../../helpers/formatNumber";
// import SaveButton from "../../filters/SaveButton";
// import BarChartIcon from "@mui/icons-material/BarChart";
// import MapIcon from "@mui/icons-material/Map";
// import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MarketDataInner from "./market-data/MarketDataInner";
import YieldCalculator from "../../yieldCalculator/YieldCalculator";
import { Tab, Tabs } from "@mui/material";

// const Toolkit = ({ set_tool, current_tool }) => {
//     const [showTools, setShowTools] = useState(false);

//     const select = (tool) => {
//         set_tool(tool);
//     };

//     return (
//         <>
//             <div className={`toolkit-absolute ${showTools ? "open" : ""}`}>
//                 <div className="toolkit-center">
//                     <div
//                         className={`button-label ${
//                             current_tool == 2 ? "selected" : ""
//                         }`}
//                     >
//                         <span onClick={() => select(2)} className="label">
//                             Calculateur de rendement net
//                         </span>
//                         <div onClick={() => select(2)} className="button">
//                             <PriceCheckIcon />
//                         </div>
//                     </div>
//                     <div
//                         className={`button-label ${
//                             current_tool == 1 ? "selected" : ""
//                         }`}
//                     >
//                         <span onClick={() => select(1)} className="label">
//                             Données de marché
//                         </span>
//                         <div onClick={() => select(1)} className="button">
//                             <BarChartIcon />
//                         </div>
//                     </div>
//                     <div
//                         className={`button-label ${
//                             current_tool == 0 ? "selected" : ""
//                         }`}
//                     >
//                         <span onClick={() => select(0)} className="label">
//                             Map et données démographiques
//                         </span>
//                         <div onClick={() => select(0)} className="button">
//                             <MapIcon />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <SaveButton type={3} hover={setShowTools} />
//         </>
//     );
// };

const DetailsWeb = ({
    current_investment,
    setActualProperty,
    open_modal,
    isAdmin,
    isAPropertyFromUserAgency,
}) => {
    const data_to_render = complete_data(current_investment, isAdmin);
    const property = current_investment.property_data;
    // const [currentTool, setCurrentTool] = useState(0);

    const [toolTab, setToolTab] = useState(1);

    const handleToolTabChange = (event, newValue) => {
        setToolTab(newValue);
    };

    return (
        <main className="detail-web">
            <section className="detail-web__left">
                <DetailsTop
                    open_modal={open_modal}
                    current_investment={current_investment}
                    isAPropertyFromUserAgency={isAPropertyFromUserAgency}
                    setActualProperty={setActualProperty}
                />

                <DetailsPrice
                    price={`${formatNumber(property.price)}€`}
                    rentPrice={data_to_render["Loyer"]}
                    yieldPercentage={data_to_render["Rdmt brut"]}
                    property={current_investment}
                />

                <DetailsDescription
                    title={property.title}
                    description={property.description}
                    values={data_to_render}
                    openModal={open_modal}
                    isAdmin={isAdmin}
                    currentInvestmentUrl={current_investment.url}
                    investment={current_investment}
                />
            </section>

            <section className="detail-web__right">
                {/* <div className="detail-web__right__tool-selection">
                    <Tabs
                        // className="detail-web__right__tool-selection__tab-selector"
                        value={toolTab}
                        onChange={handleToolTabChange}
                        aria-label="choix de l'outil"
                    >
                        <Tab
                            className="detail-web__right__tool-selection__tab-selector"
                            label="Carte et données démographiques"
                        />
                        <Tab
                            className="detail-web__right__tool-selection__tab-selector"
                            label="Calculateur de rendement"
                        />
                        <Tab
                            className="detail-web__right__tool-selection__tab-selector"
                            label="Données de marché"
                        />
                    </Tabs>
                </div> */}

                <div className="detail-web__right__tool-selection-custo">
                    <div
                        onClick={() => setToolTab(1)}
                        className={`detail-web__right__tool-selection-custo__tab-selector ${
                            toolTab == 1 ? "selected" : ""
                        }`}
                    >
                        <span>Calculateur de rendement</span>
                    </div>
                    <div
                        onClick={() => setToolTab(2)}
                        className={`detail-web__right__tool-selection-custo__tab-selector ${
                            toolTab == 2 ? "selected" : ""
                        }`}
                    >
                        <span>Données de marché</span>
                    </div>
                    <div
                        onClick={() => setToolTab(0)}
                        className={`detail-web__right__tool-selection-custo__tab-selector ${
                            toolTab == 0 ? "selected" : ""
                        }`}
                    >
                        <span>Carte et données démographiques</span>
                    </div>
                </div>

                <div className="detail-web__right__tab">
                    {toolTab === 0 && (
                        <>
                            <DemographicData
                                demographic_data={
                                    current_investment.demographic_data
                                }
                                openModal={open_modal}
                            />

                            <div className="detail-web__right__tab__map">
                                <Map
                                    center={{
                                        lat: property.location.latitude
                                            ? property.location.latitude
                                            : 0,
                                        lng: property.location.longitude
                                            ? property.location.longitude
                                            : 0,
                                    }}
                                    location={
                                        current_investment.property_data
                                            .location
                                    }
                                />
                            </div>
                        </>
                    )}

                    {toolTab === 1 && (
                        // todo : change yield-calculator class name and make it unique to DetailsWeb because for now it's in YieldCalculator, MarketData, etc.
                        <div className="yield-calculator">
                            {/*<h2>Calculateur de rendement net</h2>*/}
                            <YieldCalculator
                                propertyDetailRent={property.bot_data.rent}
                                propertyDetailPrice={property.price}
                                propertyDetailRc={
                                    property.transaction?.sale?.cadastralIncome
                                }
                            />
                        </div>
                    )}

                    {toolTab === 2 && (
                        // todo : change yield-calculator class name and make it unique to DetailsWeb because for now it's in YieldCalculator, MarketData, etc.
                        <div className="yield-calculator">
                            <MarketDataInner
                                current_property={current_investment}
                            />
                        </div>
                    )}
                </div>
            </section>
            {/* 
            {currentTool == 0 ? (
                <section className="detail-web__right">
                    <DemographicData
                        demographic_data={current_investment.demographic_data}
                        openModal={open_modal}
                    />

                    <div className="detail-web__right__map">
                        <Map
                            center={{
                                lat: property.location.latitude
                                    ? property.location.latitude
                                    : 0,
                                lng: property.location.longitude
                                    ? property.location.longitude
                                    : 0,
                            }}
                            location={current_investment.property_data.location}
                        />
                    </div>
                </section>
            ) : currentTool == 1 ? (
                <section className="detail-web__right">
                    <MarketDataInner current_property={current_investment} />
                </section>
            ) : currentTool == 2 ? (
                <section className="detail-web__right">
                    <div className="yield-calculator">
                        <h1>Calculateur de rendement net</h1>
                        <YieldCalculator
                            propertyDetailRent={property.bot_data.rent}
                            propertyDetailPrice={property.price}
                            propertyDetailRc={property.bot_data.rc}
                        />
                    </div>
                </section>
            ) : null}
            <Toolkit set_tool={setCurrentTool} current_tool={currentTool} /> */}
        </main>
    );
};

export default DetailsWeb;
