// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//     showModal: false,
//     text: '',
//     title: '',
//     buttonText: '',
//     buttonLink: '',
//     showButton: false,
// };

// const modalSlice = createSlice({
//     name: 'modal',
//     initialState,
//     reducers: {
//         openModal: (state, action) => {
//             const { payload } = action;
//             Object.assign(state, payload);
//             state.showModal = true;
//         },
//         closeModal: (state) => {
//             Object.assign(state, initialState);
//         },
//     },
// });

// export const { openModal, closeModal } = modalSlice.actions;
// export default modalSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false,
    text: '',
    title: '',
    buttonText: '',
    buttonLink: '',
    showButton: false,
    yesButtonText: '',
    noButtonText: '',
    showYesButton: false,
    showNoButton: false,
    // onYes: null,
    // onNo: null,
    confirm: null,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, action) => {
            const { payload } = action;
            Object.assign(state, payload);
            state.showModal = true;
        },
        closeModal: (state) => {
            // Object.assign(state, initialState);

            // all but confirm on initial state
            const { confirm } = state;
            Object.assign(state, initialState, { confirm });
        },
        confirm: (state) => {
            state.confirm = true;
        },
        decline: (state) => {
            state.confirm = false;
        },
    },
});

export const { openModal, closeModal, confirm, decline } = modalSlice.actions;
export default modalSlice.reducer;
