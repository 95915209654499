import React, { useState, useEffect } from "react";

/**
 * Return a counter till the provided timestamp. The timestamp props need to be in sec.
 *
 * @example :
 *      setEndOfTrial(Math.floor(endOfTrialDate.getTime() / 1000));
 *      <Countdown timestamp={endOfTrial} />
 *
 * @param timestamp - a timestamp of seconds
 * @param className - optional
 * @returns {JSX.Element}
 * @constructor
 */
const Countdown = ({ timestamp, className }) => {
    const calculateTimeLeft = () => {
        let currentDate = Math.floor(Date.now() / 1000); // Current date in seconds
        let difference = timestamp - currentDate; // Difference in seconds

        let output = {};

        if (difference > 0) {
            output = {
                hours: Math.floor(difference / 3600),
                minutes: Math.floor((difference % 3600) / 60),
            };
        }

        return output;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 60000); // update each minutes

        return () => clearTimeout(timer);
    });

    return (
        <span className={className ? className : null}>
            {timeLeft.hours ? `${timeLeft.hours}h et ` : ""}
            {`${timeLeft.minutes}min`}
        </span>
    );
};

export default Countdown;
