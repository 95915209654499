import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import "./style.scss";
import axios from "../../../services/axios";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const NewByUrl = () => {
    const [url, setUrl] = useState("");
    const [currentUrl, setCurrentUrl] = useState("");
    const [loading, setLoading] = useState(false);

    const add_by_url = async () => {
        setCurrentUrl("");
        setLoading(true);
        const ret = await axios
            .post("/add-by-url", { url: url })
            .then((e) => e.data)
            .catch((e) => null);
        if (ret !== null) {
            setCurrentUrl(`/details/${ret}`);
        }
        setLoading(false);
    };

    /**
     * ATTENTION: we block the sending of url on staging environment
     * because there is not CRON in staging.
     * If the send button is allowed, the backend will call the prod CRON,
     * even if we are in staging environment.
     * The prod cron will then call
     * the prod API to add the property.
     * so the new property will
     * be added to the prod database.
     */
    const stagingDisabled = process.env.REACT_APP_ENVIRONMENT !== "prod";

    return (
        <div id="new-by-url">
            {stagingDisabled && <h1>Uniquement disponible en production</h1>}
            <div className="input-div">
                <TextField
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    label="Url"
                    fullWidth
                    type={"text"}
                    variant="outlined"
                />
                <LoadingButton
                    onClick={add_by_url}
                    disabled={stagingDisabled || url === ""}
                    loading={loading}
                    size="large"
                    variant="outlined"
                >
                    Ajouter
                </LoadingButton>
            </div>
            {currentUrl === "" ? null : (
                <Link target={"_blank"} to={currentUrl}>
                    Go check the new page
                </Link>
            )}
        </div>
    );
};

export default NewByUrl;
