import React from "react";
import YieldCalculator from "../../components/yieldCalculator/YieldCalculator";

import "./yieldCalculatorScreen.scss";

const YieldCalculatorScreen = () => {
    return (
        <div className="yield-calculator__screen-container">
            <h1 className="yield-calculator__main-title">
                Calculateur de rendement
            </h1>
            <YieldCalculator />
        </div>
    );
};

export default YieldCalculatorScreen;
