import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Home from "./screens/home/Home";
import Details from "./screens/details/Details";
import { connect } from "react-redux";
import { mapDispatchToProps } from "./store/dispatcher";
import Filters from "./screens/filters/Filters";
import Footer from "./components/footer/Footer";
import EditDetails from "./screens/edit_details/EditDetails";
import Login from "./screens/log/login/Login";

import { initializeApp } from "firebase/app";
import Search from "./screens/search/Search";
import Register from "./screens/log/register/Register";
import Profile from "./screens/profile/Profile";
import Alerts from "./screens/alerts/Alerts";
import { useContext, useEffect } from "react";
import AuthContext from "./contexts/AuthContext";
import AdminHome from "./screens/admin/home/AdminHome";
import NewByUrl from "./screens/admin/new_by_url/NewByUrl";
import NotFound from "./screens/not_found/NotFound";
// import RegisterRedirect from "./screens/log/register/RegisterRedirect";
// import RegisterAgent from "./screens/log/register/RegisterAgent";
import NewByCron from "./screens/admin/new_by_cron/NewByCron";
import UsersReports from "./screens/admin/users_reports/UsersReports";
// import { SearchProvider } from "./contexts/SearchContext";
import MarketData from "./screens/admin/market_data/MarketData";
import Modal from "./components/Modal/Modal";
import hasPermission from "./utils/hasPermission";
import FavoriteProperties from "./screens/favoriteProperties/FavoriteProperties";
import FreezedProperties from "./screens/freezedProperties/FreezedProperties";
// import YieldCalculator from "./components/yieldCalculator/YieldCalculator";
import YieldCalculatorScreen from "./screens/yieldCalculatorScreen/YieldCalculatorScreen";
import AppSettings from "./screens/admin/app_settings/AppSettings";
import MyAgencyProperties from "./screens/myAgencyProperties/MyAgencyProperties";
import StripePricing from "./screens/stripePricing/StripePricing";
import NewByForm from "./screens/newByForm/NewByForm";
import IaIntegrationsScreen from "./screens/ia_integrations/IaIntegrationsScreen";
import ResetPassword from "./screens/log/reset_password/ResetPassword";
import { hotjar } from "react-hotjar";

const firebaseConfig =
    process.env.REACT_APP_ENVIRONMENT != "prod"
        ? {
              apiKey: "AIzaSyCwvf_H7PrHeE79ZUlZTuqjO9-7TI_KlVY",
              authDomain: "immovestor---staging.firebaseapp.com",
              projectId: "immovestor---staging",
              storageBucket: "immovestor---staging.appspot.com",
              messagingSenderId: "952459801952",
              appId: "1:952459801952:web:c54ffad8792e2bd5b79a05",
              measurementId: "G-NY3P5S4JXR",
          }
        : {
              apiKey: "AIzaSyACQz2Bc9Wd94Z398IgJbJlAaXqAO7ajVI",
              authDomain: "immovestor01.firebaseapp.com",
              projectId: "immovestor01",
              storageBucket: "immovestor01.appspot.com",
              messagingSenderId: "564898217636",
              appId: "1:564898217636:web:324a3b0aaa2ccdac7e7031",
              measurementId: "G-JHG1ND9CB3",
          };

const app = initializeApp(firebaseConfig);

const App = ({ getFavoritesProperties }) => {
    const { user } = useContext(AuthContext);

    /**
     * Put this here since if we put in Details or it's child,
     * This trigger infinite re-render.
     */
    useEffect(() => {
        getFavoritesProperties();
    }, [user]);

    useEffect(() => {
        if (
            process.env.REACT_APP_ENVIRONMENT === "prod" &&
            process.env.REACT_APP_HOTJAR_HJID
        ) {
            const hjid = process.env.REACT_APP_HOTJAR_HJID; // the Hotjar id
            const hjsv = 6; // the hotjar version

            hotjar.initialize(hjid, hjsv);

            if (hotjar.initialized()) {
                console.log("***");
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <NavBar />
            <Login />
            <Routes>
                <Route path="/filters" element={<Filters />} />
                <Route path="/search" element={<Search />} />
                <Route path="/" element={<Search />} />
                <Route path="/map" element={<Search />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                {/*<Route path="/register-agent" element={<Register />} />*/}
                {/*<Route path="/register-agent" element={<RegisterAgent />} />*/}
                <Route path="/register-investor" element={<Register />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/details/:id" element={<Details />} />
                <Route path="/edit-details/:id" element={<EditDetails />} />
                <Route path="/pricing" element={<StripePricing />} />
                <Route
                    path="/ia-prediction"
                    element={<IaIntegrationsScreen />}
                />

                {hasPermission(user, "write_newPropertyFromForm") && (
                    <Route path="/new-by-form" element={<NewByForm />} />
                )}
                {hasPermission(user, [
                    "read_yieldCalculator",
                    "write_yieldCalculator",
                ]) && (
                    <Route
                        path="/yield-calculator"
                        element={<YieldCalculatorScreen />}
                    />
                )}

                {hasPermission(user, "read_marketData") && (
                    <Route path="/market-data" element={<MarketData />} />
                )}

                {hasPermission(user, "write_PropertyByUrl") && (
                    <Route path="/new-by-url" element={<NewByUrl />} />
                )}

                {hasPermission(user, "write_newPropertyFromForm") && (
                    <Route path="/new-by-form" element={<NewByForm />} />
                )}

                {user.permissions.read_favoritesProperties && (
                    <Route
                        path="/favorites-properties"
                        element={<FavoriteProperties />}
                    />
                )}

                {user.permissions.read_FreezeProperty && (
                    <Route
                        path="/freezed-properties"
                        element={<FreezedProperties />}
                    />
                )}

                {hasPermission(user, "read_myAgencyProperty") && (
                    <Route
                        path="/my-agency-properties"
                        element={<MyAgencyProperties />}
                    />
                )}

                {!user.isAdmin ? null : (
                    <>
                        <Route path="/admin" element={<AdminHome />} />
                        <Route
                            path="/admin/new-by-url"
                            element={<NewByUrl />}
                        />
                        <Route
                            path="/admin/new-by-cron"
                            element={<NewByCron />}
                        />
                        <Route
                            path="/admin/reports"
                            element={<UsersReports />}
                        />
                        <Route
                            path="/admin/market-data"
                            element={<MarketData />}
                        />
                        <Route
                            path="/admin/app-settings"
                            element={<AppSettings />}
                        />
                    </>
                )}
                <Route path="/home" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
            <Modal />
        </BrowserRouter>
    );
};

export default connect(null, mapDispatchToProps)(App);
