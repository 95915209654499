import React from 'react'
import { Link } from 'react-router-dom'
import not_found from "../../assets/404-page-not-found.gif"
import "./style.scss"

const NotFound = () => {
  return (
    <div id="not-found-page">
      <img alt="Gif showing 404 with a cactus instead of a 0" src={not_found} />
      <div className='not-found-text'>
        <h1>Page Not Found</h1>
        <p>La page que vous recherchez n'est plus disponnible, nous en sommes navré.</p>
        <Link to={"/"}>Retour à la page d'accueil</Link>
      </div>
    </div>
  )
}

export default NotFound