import { OverlayView } from "@react-google-maps/api";

import "./marketDataMarker.scss";

const MarketDataMarker = ({ property, onHover }) => {
    return (
        <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
                lat: property.latitude,
                lng: property.longitude,
            }}
        >
            <div className="market-data-marker" onMouseEnter={onHover}>
                {/*<div className="market-data-mrker__rent">{property.rent} €</div>*/}
            </div>
        </OverlayView>
    );
};

export default MarketDataMarker;
