import formatNumber from "../../../helpers/formatNumber";

const PropertyElemHeader = ({
    rent,
    views,
    certificats,
    pebLetter,
    pictures,
    no_pics,
    isViewsIsVisible,
    isRentIsVisible = true,
}) => {
    return (
        <header className="property-element__content__image-container">
            {isRentIsVisible && (
                <div className="property-element__rent">
                    {rent ? `${formatNumber(rent)}€` : "inconnu"}/mois
                </div>
            )}

            {isViewsIsVisible(views) && (
                <div className="property-element__views">{views} vues</div>
            )}

            {certificats?.epcScore !== null && certificats?.epcScore && (
                <div className="property-element__peb">
                    <img
                        alt={`PEB ${pebLetter}`}
                        className={certificats?.epcScore !== "" ? "large" : ""}
                        src={
                            certificats?.epcScore !== ""
                                ? `https://static.immoweb.be/pics/peb/peb_${pebLetter}.png`
                                : "https://static.immoweb.be/fr/pics/certif_nondispo_1.gif"
                        }
                    />
                </div>
            )}

            <img
                className={"property-element__image"}
                alt="la propriété"
                src={pictures[0]?.mediumUrl ? pictures[0].mediumUrl : no_pics}
            />
        </header>
    );
};

export default PropertyElemHeader;
