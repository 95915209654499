import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthContext from "../../contexts/AuthContext";
import "./favoriteProperties.scss";
import PropertiesList from "../../components/propertiesList/PropertiesList";
import { getFavoritesProperties } from "../../store/slices/favoritesSlice";

/**
 * No check for access permissions since there is checked on App.jsx
 */
const FavoriteProperties = ({favorites, getFavoritesProperties}) => {
    const { user } = useContext(AuthContext);

    const isLoading = favorites.isLoading;

    const favoritesProperties = favorites.favoritesProperties ? favorites.favoritesProperties : [];

    useEffect(() => {
        getFavoritesProperties();
    }, [user]);

    return (
        <div className="favorites-properties-page">
            <h1 className="favorites-properties-page__title">
                Vos annonces favorites
            </h1>

            <PropertiesList
                properties={favoritesProperties}
                loading={isLoading}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteProperties);
