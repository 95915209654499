import { configureStore } from '@reduxjs/toolkit';
import investmentsSlice from './slices/investments';
import modalReducer from './slices/modalSlice';
import favoritesReducer from "./slices/favoritesSlice";
import freezedPropertiesReducer from "./slices/freezedPropertiesSlice";
import appSettingsReducer from "./slices/appSettingsSlice";
import agencyPropertiesReducer from "./slices/agencyPropertiesSlice";

export const store = configureStore({
	reducer: {
		investments: investmentsSlice.reducer,
		modal: modalReducer,
		favorites: favoritesReducer.reducer,
		freezedProperties: freezedPropertiesReducer.reducer,
		appSettings: appSettingsReducer.reducer,
		agencyProperties: agencyPropertiesReducer.reducer
	}
});
