import { demographic_data } from "../data/demographic"

const set_search_str = (value) => {
	return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

const demographic_updated = [];

for (let index = 0; index < demographic_data.length; index++) {
    const element = demographic_data[index];
    demographic_updated.push({
        ...element,
        ps: set_search_str(element["p"]),
        as: set_search_str(element["a"]),
        ns: set_search_str(element["n"]),
        z: element["z"]
    });
}

export const precision_search = (filters) => {
    const search_results = {
        provinces: {},
        districts: {},
        localities: {},
    }
    for (let index = 0; index < filters.zip_codes.length; index++) {
        const element = filters.zip_codes[index];
        const getted = demographic_updated.filter(elem => elem.ns === set_search_str(element))
        if (!getted.length)
            continue;
        if (!search_results.localities[getted[0].n]) {
            search_results.localities[getted[0].n] = [getted[0].z]
        } else {
            search_results.localities[getted[0].n].push(getted[0].z)
        }
    }
    for (let index = 0; index < filters.provinces.length; index++) {
        const element = filters.provinces[index];
        const getted = demographic_updated.filter(elem => elem.ps === set_search_str(element))
        if (!getted.length)
            continue;
        search_results.provinces[getted[0].p] = [getted[0].z];
        if (getted.length > 1) {
            for (let i = 1; i < getted.length; i++) {
                const el = getted[i];
                search_results.provinces[el.p].push(el.z)
            }
        }
    }
    for (let index = 0; index < filters.districts.length; index++) {
        const element = filters.districts[index];
        const getted = demographic_updated.filter(elem => elem.as === set_search_str(element))
        if (!getted.length)
            continue;
        search_results.districts[getted[0].a] = [getted[0].z];
        if (getted.length > 1) {
            for (let i = 1; i < getted.length; i++) {
                const el = getted[i];
                search_results.districts[el.a].push(el.z)
            }
        }
    }
    return search_results;
}

export const search = (search_str) => {
    search_str = set_search_str(search_str);

    const search_results = {
        provinces: {},
        districts: {},
        localities: {},
    }

    if (search_str === "")
        return search_results;

    demographic_updated.forEach(elem => {
        if (elem.ps.includes(search_str)) {
            if (!search_results.provinces[elem.p]) {
                search_results.provinces[elem.p] = [elem.z]
            } else {
                search_results.provinces[elem.p].push(elem.z)
            }
        }

        if (elem.as.includes(search_str)) {
            if (!search_results.districts[elem.a]) {
                search_results.districts[elem.a] = [elem.z]
            } else {
                search_results.districts[elem.a].push(elem.z)
            }
        }

        if (elem.ns.includes(search_str)) {
            if (!search_results.localities[elem.n]) {
                search_results.localities[elem.n] = [elem.z]
            } else {
                search_results.localities[elem.n].push(elem.z)
            }
        }

        if (elem.z.toString()  === search_str) {
            if (!search_results.localities[elem.n]) {
                search_results.localities[elem.n] = [elem.z]
            } else {
                search_results.localities[elem.n].push(elem.z)
            }
        }
    })

    return search_results;
}
