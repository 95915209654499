import React, { useState } from "react";
import axios from "../../services/axios";
import "./style.scss";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { peb_scores, home_type_for_ia } from "../../constants/constant_lists";

const verif_key =
    "SWxAhi4w8B6XhhvtYn28j23hrHgm6fz8FCaY9cQytDAxPAz2u27HyvjyH7X6uSvC4TTQ4QNfW9vbPHajaKQkXrNjZaMRYPAyAs9zcG6T4HUuFosUUSftP3anXQ4s";

const FilterBlock = ({ title, children }) => {
    return (
        <div className="filter-block">
            <div className="filter-block-header">
                <h2>{title}</h2>
            </div>
            {children}
        </div>
    );
};

function IaIntegrations() {
    const [netHabitableSurface, setNetHabitableSurface] = useState("");
    const [location_postalCode, setLocationPostalCode] = useState("");
    const [bedroomCount, setBedroomCount] = useState("");
    const [certificats_epcScore, setCertificats_epcScore] = useState("");
    const [type, setType] = useState("");
    const [prediction, setPrediction] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            netHabitableSurface: parseFloat(netHabitableSurface),
            location_postalCode: parseFloat(location_postalCode),
            bedroomCount: parseFloat(bedroomCount),
            certificats_epcScore: certificats_epcScore,
            type: type,
        };

        try {
            const response = await axios.post("/predict_rent", formData);
            setPrediction(response.data.predicted_rent);
        } catch (error) {
            console.error("Une erreur s'est produite:", error);
        }
    };

    return (
        <div className="filters-view input-data">
            <form onSubmit={handleSubmit}>
                <div className="data-center">
                    <FilterBlock title={"Nombre de chambres:"}>
                        <div className="bedroomCount-inputs">
                            <TextField
                                value={bedroomCount}
                                type="number"
                                label="Nombre de chambres"
                                onChange={(e) =>
                                    setBedroomCount(e.target.value)
                                }
                                id="outlined-basic"
                                variant="outlined"
                            ></TextField>
                        </div>
                    </FilterBlock>

                    <FilterBlock title={"Surface:"}>
                        <div className="netHabitableSurface-inputs">
                            <TextField
                                value={netHabitableSurface}
                                type="number"
                                label="Surface"
                                onChange={(e) =>
                                    setNetHabitableSurface(e.target.value)
                                }
                                id="outlined-basic"
                                variant="outlined"
                            ></TextField>
                        </div>
                    </FilterBlock>

                    <FilterBlock title={"Code postal:"}>
                        <div className="locationPostalCode-inputs">
                            <TextField
                                value={location_postalCode}
                                type="number"
                                label="Code postal"
                                onChange={(e) =>
                                    setLocationPostalCode(e.target.value)
                                }
                                id="outlined-basic"
                                variant="outlined"
                            ></TextField>
                        </div>
                    </FilterBlock>

                    <FilterBlock title={"Score EPC:"}>
                        <div className="certificats_epcScore-inputs">
                            <FormControl variant="outlined">
                                <InputLabel id="certificats_epcScore-label">
                                    Score EPC
                                </InputLabel>
                                <Select
                                    labelId="certificats_epcScore-label"
                                    id="certificats_epcScore"
                                    value={certificats_epcScore}
                                    onChange={(e) =>
                                        setCertificats_epcScore(e.target.value)
                                    }
                                    label="Score EPC"
                                >
                                    {peb_scores.map((score) => (
                                        <MenuItem key={score} value={score}>
                                            {score}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </FilterBlock>

                    <FilterBlock title={"Type:"}>
                        <div className="type-inputs">
                            <FormControl variant="outlined">
                                <InputLabel id="type-label">Type</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="type-select"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    label="Type"
                                >
                                    {home_type_for_ia.map((score) => (
                                        <MenuItem key={score} value={score}>
                                            {score}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </FilterBlock>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="predict-button" type="submit">
                        Prédire
                    </button>
                </div>
            </form>

            {prediction !== null && (
                <div className="prediction-result">
                    <h2>Résultat de la prédiction:</h2>
                    <p>{prediction}</p>
                </div>
            )}
        </div>
    );
}

export default IaIntegrations;
