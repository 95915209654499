export const editable_data = [
    "bot_data.rent",
    "bot_data.potential_rent",
    "bot_data.convertible_attic",
    "certificats.epcScore",
    "price",
    "transaction.sale.cadastralIncome",
    "title",
    "type",
    "subtype",
    "description",
    "roomCount",
    "bedroomCount",
    "toiletsCount",
    "bathroomCount",
    "basement.exist",
    "basement.surface",
    "garden.exist",
    "garden.surface",
    "terrace.exists",
    "terrace.surface",
    "terrace.orientation",
    "attic.exist",
    "attic.surface",
    "balcony.exist",
    "building.constructionYear",
    "building.streetFacadeWidth",
    "building.facadeCount",
    "building.floorCount",
    "building.condition",
    "netHabitableSurface",
    "parking.closed_box",
    "parking.outdoor",
    "parking.indoor",
    "garden.orientation",
    "constructionPermit.urbanPlanningInformation",
    "constructionPermit.isObtained",
    "constructionPermit.hasPlotDivisionAuthorization",
    "constructionPermit.hasObligationToConstruct",
    "constructionPermit.totalBuildableGroundFloorSurface",
    "constructionPermit.floodZoneIconUrl",
    "constructionPermit.hasPossiblePriorityPurchaseRight",
    "constructionPermit.floodZoneType",
    "constructionPermit.isBreachingUrbanPlanningRegulation",
    "constructionPermit.constructionType",
    "land.sewerConnection",
    "land.isFlat",
    "land.hasGasWaterElectricityConnection",
    "land.surface",
    "land.isFacingStreet",
    "land.latestUseDesignation",
    "land.landWidth",
    "land.isWooded",
    "land.hasPlotToRear",
    "land.rearLand",
    "location.latitude",
    "location.propertyName",
    "location.placeName",
    "location.region",
    "location.province",
    "location.street",
    "location.distance",
    "location.regionCode",
    "location.approximated",
    "location.number",
    "location.district",
    "location.locality",
    "location.postalCode",
    "location.hasSeaView",
    "location.country",
    "location.type",
    "location.longitude",
    "location.box",
    "location.floor",
];
