

export const show_location = (location) => {
    if (!location)
        return ""
    if (!location.street) {
        return `${location.postalCode} - ${location.locality}`
    } else {
        return `${location.street} | ${location.postalCode} - ${location.locality}`
    }
}