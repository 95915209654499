import {
    Button,
    ClickAwayListener,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./mainFilters.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import { useRef } from "react";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { connect } from "react-redux";
import { useEffect } from "react";
import { pebs } from "../../../constants/constant_lists";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";
import ImmovestorButton from "../../immovestorButton/ImmovestorButton";

const show_values = (value1, value2, unit) => {
    let val1 = `${value1}`;
    let val2 = `${value2}`;
    if (value1 >= 1000) {
        val1 = `${value1 / 1000}K`;
    }
    if (value2 >= 1000) {
        val2 = `${value2 / 1000}k`;
    }
    if (value1 >= 1000000) {
        val1 = `${value1 / 1000000}M`;
    }
    if (value2 >= 1000000) {
        val2 = `${value2 / 1000000}M`;
    }
    if (value2 === 0) {
        return `Min.${val1}${unit}`;
    } else if (value1 === 0) {
        return `Max.${val2}${unit}`;
    }
    return `${val1}${unit} - ${val2}${unit}`;
};

const MainFilters = ({
    fetchInvestments,
    investments,
    advancedFilters,
    handleOpenAdvanced,
    filter_sort,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [spotlight, setSpotlight] = useState(filter_sort.filter.spotlight);

    const navigate = useNavigate();

    const priceMin = useRef(filter_sort.filter.price_min);
    const priceMax = useRef(filter_sort.filter.price_max);
    const rendMin = useRef(filter_sort.filter.rdt_min);
    const rendMax = useRef(filter_sort.filter.rdt_max);
    const [peb, setPeb] = useState("Tous");
    const [category, setCategory] = useState(filter_sort.filter.category);

    const { user } = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl2(null);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAnchorEl(null);
        setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    };

    const close = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
    };

    const setSavePeb = (peb) => {
        filter_sort.filter.epc_scores = peb;
        filter_sort.page = 0;
        setPeb(peb);
        fetchInvestments(filter_sort);
    };

    const setSaveCategory = (category) => {
        filter_sort.filter.category = category;
        filter_sort.page = 0;
        setCategory(category);
        fetchInvestments(filter_sort);
    };

    useEffect(() => {
        priceMin.current = filter_sort.filter.price_min;
        priceMax.current = filter_sort.filter.price_max;
        rendMin.current = filter_sort.filter.rdt_min;
        rendMax.current = filter_sort.filter.rdt_max;
        setPeb(filter_sort.filter.epc_scores);
    }, [investments]);

    const handleSave = (is_rend) => {
        if (!is_rend) {
            filter_sort.filter.price_min = priceMin.current;
            filter_sort.filter.price_max = priceMax.current;
        } else {
            filter_sort.filter.rdt_min = rendMin.current;
            filter_sort.filter.rdt_max = rendMax.current;
        }
        filter_sort.page = 0;
        setAnchorEl(null);
        setAnchorEl2(null);
        fetchInvestments(filter_sort);
    };

    const reset = () => {
        if (anchorEl2) {
            priceMin.current = 0;
            priceMax.current = 0;
            handleSave(false);
        } else if (anchorEl) {
            rendMin.current = 0;
            rendMax.current = 0;
            handleSave(true);
        }
    };

    const handleSpotlightButton = () => {
        filter_sort.filter.spotlight = !spotlight;
        filter_sort.page = 0;
        setSpotlight(!spotlight);

        fetchInvestments(filter_sort);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);

    return (
        <ClickAwayListener onClickAway={close}>
            <div id="main-filters" className="filters-cont">
                <Select
                    className="peb-select"
                    labelId="demo-simple-select-label"
                    value={peb === "" ? "Tous" : peb}
                    onChange={(e) => setSavePeb(e.target.value)}
                >
                    {pebs.map((elem, index) => (
                        <MenuItem className="peb-item" key={index} value={elem}>
                            {elem === "Tous" ? "PEB minimum" : elem}
                        </MenuItem>
                    ))}
                </Select>

                <Button
                    onClick={handleClick}
                    fullWidth
                    variant="outlined"
                    endIcon={<ArrowDropDownIcon />}
                    className={"search__button"}
                >
                    {rendMax.current !== 0 || rendMin.current !== 0
                        ? show_values(rendMin.current, rendMax.current, "%")
                        : "Rendement"}
                </Button>
                <Button
                    onClick={handleClick2}
                    fullWidth
                    variant="outlined"
                    endIcon={<ArrowDropDownIcon />}
                    className={"search__button"}
                >
                    {priceMax.current !== 0 || priceMin.current !== 0
                        ? show_values(priceMin.current, priceMax.current, "€")
                        : "Prix"}
                </Button>

                <Select
                    className="peb-select"
                    value={category}
                    onChange={(e) => setSaveCategory(e.target.value)}
                >
                    <MenuItem
                        className="peb-item"
                        key={"classic"}
                        value={"is_classic"}
                    >
                        Locations classiques
                    </MenuItem>

                    <MenuItem
                        className="peb-item"
                        key={"category"}
                        value={"is_colloc"}
                    >
                        Colocations potentielles
                    </MenuItem>
                </Select>

                {/* {	advancedFilters &&
						<Button className='selector' variant="outlined" onClick={handleOpenAdvanced} >{window.innerWidth > 600 ? "Plus de filtres" : "Filtres"}</Button>
					} */}

                {advancedFilters && (
                    <Button
                        className="selector"
                        variant="outlined"
                        onClick={() => navigate("/filters")}
                        className={"search__button"}
                    >
                        {window.innerWidth > 600
                            ? "Plus de filtres"
                            : "Filtres"}
                    </Button>
                )}

                {/*/!*{user.permissions.read_spotlight && (*!/*/}
                {/*<Button*/}
                {/*    className={`our-selection ${!spotlight ? "" : "selected"}`}*/}
                {/*    variant="outlined"*/}
                {/*    onClick={handleSpotlightButton}*/}
                {/*    className={"search__button"}*/}
                {/*>*/}
                {/*    {spotlight ? "Tout voir" : "Notre sélection"}*/}
                {/*</Button>*/}
                {/*/!*)}*!/*/}

                <Popper
                    sx={{ zIndex: 2, maxWidth: "80vw" }}
                    open={open}
                    anchorEl={anchorEl}
                >
                    <div className="popper-home-filter-content">
                        <h2>Rendement du bien</h2>
                        <div className="value-header">
                            <TextField
                                defaultValue={rendMin.current}
                                onChange={(e) =>
                                    (rendMin.current = e.target.value)
                                }
                                type={"number"}
                                size="small"
                                variant="outlined"
                                label="Minimum (%)"
                            />
                            <TextField
                                defaultValue={rendMax.current}
                                onChange={(e) =>
                                    (rendMax.current = e.target.value)
                                }
                                type={"number"}
                                size="small"
                                variant="outlined"
                                label="Maximum (%)"
                            />
                        </div>
                        <div className="value-validate">
                            <Button onClick={reset} variant="text">
                                Réinitialiser
                            </Button>
                            <Button
                                onClick={() => handleSave(true)}
                                variant="contained"
                            >
                                Valider
                            </Button>
                        </div>
                    </div>
                </Popper>
                <Popper
                    sx={{ zIndex: 2, maxWidth: "80vw" }}
                    open={open2}
                    anchorEl={anchorEl2}
                >
                    <div className="popper-home-filter-content">
                        <h2>Prix du bien</h2>
                        <div className="value-header">
                            <TextField
                                defaultValue={priceMin.current}
                                onChange={(e) =>
                                    (priceMin.current = e.target.value)
                                }
                                type={"number"}
                                size="small"
                                variant="outlined"
                                label="Minimum"
                            />
                            <TextField
                                defaultValue={priceMax.current}
                                onChange={(e) =>
                                    (priceMax.current = e.target.value)
                                }
                                type={"number"}
                                size="small"
                                variant="outlined"
                                label="Maximum"
                            />
                        </div>
                        <div className="value-validate">
                            <Button
                                onClick={reset}
                                variant="text"
                                className={"search__button"}
                            >
                                Réinitialiser
                            </Button>
                            <Button
                                onClick={() => handleSave(false)}
                                variant="contained"
                                className={"search__button"}
                            >
                                Valider
                            </Button>
                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFilters);
