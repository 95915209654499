import React, { useContext, useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { LockClock as LockedIcon } from "@mui/icons-material";
import { LockOpen as UnlockedIcon } from "@mui/icons-material";

import AuthContext from "../../contexts/AuthContext";
import { ModalContext } from "../../contexts/ModalContext";
import {
    areYouSureToFreezeModalSettings,
    tooMuchConcurrentFrozenModalSettings,
    youNeedFreezesModlaSettings,
} from "../../constants/constant_modals";
import { useTools } from "../../contexts/ToolsContext";

import axios from "../../services/axios";
import { propertiesSettings } from "../../constants/constant_properties";

const FreezeButton = ({ property }) => {
    const { user, getProfile } = useContext(AuthContext);
    const { state, dispatch } = useContext(ModalContext);

    const [concurrentFreeze, setConcurrentFreeze] = useState(0);

    const { freezeProperty } = useTools();

    const now = Math.floor(Date.now() / 1000);
    const [isFreezedByMe, setIsFreezedByMe] = useState(
        property.freezedByMe && property.freezedUntil > now
    );

    const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const environment = process.env.REACT_APP_ENVIRONMENT;

    /**
     * Stripe's button id is different in prod and dev.
     */
    const buyButtonId =
        environment === "prod"
            ? "buy_btn_1NVE1rBw1cfdvcQ4NLer44GY"
            : "buy_btn_1NVE3nBw1cfdvcQ47VtL7mkC";

    const StripeButton = () => {
        return (
            <stripe-buy-button
                buy-button-id={buyButtonId}
                publishable-key={publishableKey}
                customer-email={user.email}
                client-reference-id={user.id}
            ></stripe-buy-button>
        );
    };

    const handleClick = () => {
        if (concurrentFreeze >= propertiesSettings.maxConcurrentFrozenByUser) {
            // user have too much concurrent frozen properties
            dispatch({
                type: "openModal",
                payload: {
                    ...tooMuchConcurrentFrozenModalSettings,
                    text: `Vous ne pouvez bloquer que ${
                        propertiesSettings.maxConcurrentFrozenByUser
                    } annonce${
                        // add plural or not
                        propertiesSettings.maxConcurrentFrozenByUser > 1
                            ? "s"
                            : ""
                    } en même temps.`,
                },
            });
        } else if (
            !isFreezedByMe &&
            user &&
            (user.freezeLeft < 1 || !user.freezeLeft)
        ) {
            // user have not enough freezes left
            dispatch({
                type: "openModal",
                payload: {
                    ...youNeedFreezesModlaSettings,
                    customContent: <StripeButton />,
                    showCustomContent: true,
                },
            });
        } else if (!isFreezedByMe && user && user.freezeLeft > 0) {
            // user can freeze : ask confirmation before apply
            dispatch({
                type: "openModal",
                payload: {
                    ...areYouSureToFreezeModalSettings,
                    text: `Il vous reste ${user.freezeLeft} freeze${
                        user.freezeLeft > 1 ? "s" : ""
                    }. Etes-vous sûr de vouloir bloquer cette annonce?`,
                },
            });
        }
    };

    useEffect(() => {
        const getFreezedProperties = async () => {
            const response = await axios.get("/me/freezed-properties");

            return response.data;
        };

        getFreezedProperties().then((frozenProperties) => {
            if (frozenProperties && frozenProperties.length) {
                setConcurrentFreeze(frozenProperties.length);
            } else {
                setConcurrentFreeze(0);
            }
        });
    }, [user]);

    useEffect(() => {
        if (state.confirm) {
            // if user have click on yesButton
            freezeProperty(property.id);
            setIsFreezedByMe(true);
            dispatch({ type: "decline" });
        }
    }, [state.confirm]); // state.confirm is triggered by modal yesButton or noButton

    return (
        <Tooltip
            title={
                "Bloquer cette annonce : rendez cette annonce invisible pour les autres utilisateurs durant 72h. Vous serez alors le seul utilisateur à pouvoir voir cette annonce sur Immovestor durant cette période.."
            }
        >
            <IconButton onClick={handleClick}>
                {isFreezedByMe ? <LockedIcon /> : <UnlockedIcon />}
            </IconButton>
        </Tooltip>
    );
};

export default FreezeButton;
