import hasPermission from "./hasPermission";

const isPropertyIsFrozen = (user, property) => {
    let propertyIsFromUserAgency = false;

    if (property.agencyId && user && user.agencyId) {
        propertyIsFromUserAgency = property.agencyId === user.agencyId;
    }

    const isFreezed =
        // frozen by an other user or not from my agency
        (property.freezedUntil &&
            !property.freezedByMe &&
            !propertyIsFromUserAgency) ||
        // frozen by me but I have not permission to see it anymore, Eg. : I've quit my plan
        (property.freezedUntil &&
            (property.freezedByMe || propertyIsFromUserAgency) &&
            !hasPermission(user, "read_FreezeProperty"))
            ? true
            : false;

    return isFreezed;
};

export default isPropertyIsFrozen;
