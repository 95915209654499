import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const updateFavorite = createAsyncThunk(
    "favorites/updateFavorite",
    async (propertyId) => {
        const response = await axios.put(`/users/favorite/${propertyId}`);
        return response.data;
    }
);

export const getFavoritesProperties = createAsyncThunk(
    "favorites/getFavoritesProperties",
    async () => {
        const response = await axios.get("/me/favorites-properties");
        return response.data;
    }
);

const favoritesSlice = createSlice({
    name: "favorites",
    initialState: {
        isLoading: false,
        error: null,
        favoritesProperties: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateFavorite.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateFavorite.fulfilled, (state, action) => {
                // console.log('fulfilled');
                // state.isLoading = false;
                // state.favoritesProperties = action.payload;
            })
            .addCase(updateFavorite.rejected, (state, action) => {
                // console.log('rejected');
                state.isLoading = false;
                state.error = action.error;
            })
            .addCase(getFavoritesProperties.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getFavoritesProperties.fulfilled, (state, action) => {
                state.isLoading = false;
                state.favoritesProperties = action.payload;
            })
            .addCase(getFavoritesProperties.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error;
            });
    },
});


export const favoritesMethods = {
    getFavoritesProperties,
    updateFavorite
}

export default favoritesSlice;
