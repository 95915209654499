import {
    Checkbox,
    /*Button,*/ FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import ClearButton from "../../components/filters/ClearButton";
import SaveButton from "../../components/filters/SaveButton";
import {
    chauffage_type,
    good_state,
    good_state_without_all,
    home_type,
    home_type_without_all,
    peb_scores,
    TOAST_LVL,
    categories_without_all,
} from "../../constants/constant_lists";
import { mapDispatchToProps } from "../../store/dispatcher";
import axios from "../../services/axios";
import "../filters/style.scss";
import "./newByForm.scss";
import ToastContext from "../../contexts/PopupContext";
import ImagesUpload from "../../components/imagesUpload/ImagesUpload";
import FormControlLabel from "@mui/material/FormControlLabel";
import NumericInputOnChange from "../../components/NumericInput/NumericInputOnChange ";
import Loading from "../../components/main/loading/Loading";
import loading from "../../components/main/loading/Loading";

const SelectInput = ({ labelId, label, children }) => {
    return (
        <FormControl>
            <InputLabel id={labelId}>{label}</InputLabel>
            {children}
        </FormControl>
    );
};

const InputsGroup = ({ label, children }) => {
    return (
        <div className={"new-by-form__inputs-group__container"}>
            <h2>{label}</h2>
            <div className={"new-by-form__inputs-group__inputs"}>
                {children}
            </div>
        </div>
    );
};

const defaultPropertyValues = () => ({
    property_data: {
        basement: {
            exist: null,
        },
        bot_data: {
            convertible_attic: null,
            rent: null,
            potential_rent: null,
        },
        parking: {
            outdoor: null,
            closed_box: null,
            indoor: null,
        },
        installations: {
            hasLift: null,
        },
        description: null,
        subtype: null,
        title: null,
        balcony: {
            exist: null,
        },
        building: {
            condition: null,
            constructionYear: null,
            floorCount: null,
            facadeCount: null,
        },
        bedroomCount: null,
        price: null,
        toiletsCount: null,
        land: {
            surface: null,
        },
        terrace: {
            exist: null,
        },
        energy: {
            heatingType: null,
            hasPhotovoltaicPanels: null,
            hasThermicPanels: null,
        },
        roomCount: null,
        bathroomCount: null,
        attic: {
            exist: null,
        },
        constructionPermit: {
            totalBuildableGroundFloorSurface: null,
        },
        location: {
            country: "Belgique",
            postalCode: null,
            latitude: null,
            locality: null,
            number: null,
            province: null,
            street: null,
            district: null,
            region: null,
            floor: null,
            longitude: null,
        },
        garden: {
            exist: null,
        },
        certificats: {
            epcScore: null,
        },
        netHabitableSurface: null,
        transaction: {
            sale: {
                cadastralIncome: null,
            },
        },
    },
    categories: {
        is_colloc: null,
        is_classic: null,
        is_airbnb: null,
    },
});

const NewByForm = () => {
    const [images, setImages] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [uploading, setUploading] = useState(false);

    const { setToast } = useContext(ToastContext);

    const [property, setProperty] = useState(defaultPropertyValues);

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        let updatedCategories = {};

        if (selectedCategory === "Airbnb") {
            updatedCategories = {
                is_colloc: false,
                is_classic: false,
                is_airbnb: true,
            };
        } else if (selectedCategory === "Collocations") {
            updatedCategories = {
                is_colloc: true,
                is_classic: false,
                is_airbnb: false,
            };
        } else if (selectedCategory === "Classique") {
            updatedCategories = {
                is_colloc: false,
                is_classic: true,
                is_airbnb: false,
            };
        }

        setSelectedCategory(selectedCategory);
        setProperty({
            ...property,
            categories: updatedCategories,
        });
    };

    const reset = () => {
        setProperty(defaultPropertyValues);
        const selectedCategory = null;
        setSelectedCategory(selectedCategory);
    };

    const validateForm = () => {
        const newEmptyFields = [];

        if (
            property.property_data.subtype === null ||
            property.property_data.subtype === ""
        ) {
            newEmptyFields.push("form-type");
        }

        if (
            property.property_data.price === null ||
            property.property_data.price === ""
        ) {
            newEmptyFields.push("form-price");
        }

        if (
            property.property_data.bot_data.rent === null ||
            property.property_data.bot_data.rent === ""
        ) {
            newEmptyFields.push("form-rent");
        }

        if (
            property.property_data.location.postalCode === null ||
            property.property_data.location.postalCode === ""
        ) {
            newEmptyFields.push("form-postal-code");
        }

        if (
            property.property_data.title === null ||
            property.property_data.title === ""
        ) {
            newEmptyFields.push("form-title");
        }

        if (
            property.property_data.description === null ||
            property.property_data.description === ""
        ) {
            newEmptyFields.push("form-description");
        }

        return !newEmptyFields.length > 0;
    };

    const handleSave = async () => {
        let propertyToSave = { ...property };
        propertyToSave.images = [];

        setUploading(true);

        if (!validateForm()) {
            setToast.current(
                TOAST_LVL.ERROR,
                "Champ requis manquant",
                "Veuillez remplir tous les champs requis."
            );
            return;
        }

        for (let [index, image] of images.entries()) {
            propertyToSave.images.push(image.data_url);
        }

        const ret = await axios
            .post("/property/add-by-form", propertyToSave)
            .then((e) => e.data)
            .catch((e) => {
                console.log(e);
                return null;
            });

        setUploading(false);

        if (ret !== null) {
            setToast.current(
                TOAST_LVL.SUCCESS,
                "enregistrement réussi",
                "votre annonce a été sauvegardée avec succès."
            );
        } else {
            setToast.current(
                TOAST_LVL.ERROR,
                "enregistrement échoué",
                "votre annonce n'a pas pu être sauvegardée. veuillez réessayer."
            );
        }
    };

    const textFieldStyle = { width: "90vw", maxWidth: "20rem" };

    // console.log("property: ", property);

    return (
        <div className="new-by-form__container">
            {uploading && (
                <div className={"new-by-form__upload-widget-container"}>
                    <Loading />
                </div>
            )}

            <div className="new-by-form__header">
                <h1>Poster une nouvelle annonce :</h1>
            </div>

            <InputsGroup label={"Prix et loyer"}>
                <NumericInputOnChange
                    value={
                        property.property_data.price !== null
                            ? property.property_data.price
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...property,
                            property_data: {
                                ...property.property_data,
                                price: e.target.value,
                            },
                        }))
                    }
                    id="form-price"
                    label="Prix de vente (requis)"
                    prefix={"€"}
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.bot_data.rent !== null
                            ? property.property_data.bot_data.rent
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                bot_data: {
                                    ...prevProperty.property_data.bot_data,
                                    rent: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-rent"
                    label="Loyer mensuel (requis)"
                    prefix={"€"}
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.bot_data.potential_rent !== null
                            ? property.property_data.bot_data.potential_rent
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                bot_data: {
                                    ...prevProperty.property_data.bot_data,
                                    potential_rent: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-potential-rent"
                    label="Loyer mensuel potentiel"
                    prefix={"€"}
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.transaction.sale
                            .cadastralIncome !== null
                            ? property.property_data.transaction.sale
                                  .cadastralIncome
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                transaction: {
                                    ...prevProperty.property_data.transaction,
                                    sale: {
                                        ...prevProperty.property_data
                                            .transaction.sale,
                                        cadastralIncome: e.target.value,
                                    },
                                },
                            },
                        }))
                    }
                    id="form-cadastral-income"
                    label="Revenu cadastral"
                    prefix={"€"}
                    sx={textFieldStyle}
                />
            </InputsGroup>

            <InputsGroup label={"Description"}>
                <TextField
                    value={
                        property.property_data.title !== null
                            ? property.property_data.title
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                title: e.target.value,
                            },
                        }))
                    }
                    id="form-title"
                    label="Titre (requis)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.description !== null
                            ? property.property_data.description
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                description: e.target.value,
                            },
                        }))
                    }
                    id="form-description"
                    label="Description (requis)"
                    variant="outlined"
                    multiline
                    sx={textFieldStyle}
                />

                <SelectInput
                    labelId={"form-category"}
                    label={"Catégorie (requis)"}
                >
                    <Select
                        label={"Catégorie (requis)"}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        id={"form-category"}
                        labelId={"form-category"}
                        style={textFieldStyle}
                    >
                        {categories_without_all.map((category, index) => (
                            <MenuItem key={index} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                </SelectInput>

                <SelectInput
                    labelId={"form-type"}
                    label={"Type de bien (requis)"}
                >
                    <Select
                        label={"Type de bien (requis)"}
                        value={property.property_data.subtype}
                        onChange={(e) =>
                            setProperty((prevProperty) => ({
                                ...prevProperty,
                                property_data: {
                                    ...prevProperty.property_data,
                                    subtype: e.target.value,
                                },
                            }))
                        }
                        id={"form-type"}
                        labelId={"form-type"}
                        style={textFieldStyle}
                    >
                        {Object.keys(home_type_without_all).map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {home_type[elem]}
                            </MenuItem>
                        ))}
                    </Select>
                </SelectInput>

                <SelectInput labelId={"form-state"} label={"Etat du bien"}>
                    <Select
                        value={property.property_data.building.condition}
                        label="Etat du bien"
                        onChange={(e) =>
                            setProperty((prevProperty) => ({
                                ...prevProperty,
                                property_data: {
                                    ...prevProperty.property_data,
                                    building: {
                                        ...prevProperty.property_data.building,
                                        condition: e.target.value,
                                    },
                                },
                            }))
                        }
                        id={"form-state"}
                        labelId={"form-state"}
                        style={textFieldStyle}
                    >
                        {Object.keys(good_state_without_all).map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {good_state[elem]}
                            </MenuItem>
                        ))}
                    </Select>
                </SelectInput>
            </InputsGroup>

            <InputsGroup label={"Adresse"}>
                <TextField
                    value={
                        property.property_data.location.country !== null
                            ? property.property_data.location.country
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    country: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-country"
                    label="Pays"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.postalCode !== null
                            ? property.property_data.location.postalCode
                            : ""
                    }
                    onChange={(e) => {
                        let value = e.target.value;
                        value = isNaN(value) ? null : value;
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    postalCode: value,
                                },
                            },
                        }));
                    }}
                    id="form-postal-code"
                    label="Code postal (requis)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.locality !== null
                            ? property.property_data.location.locality
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    locality: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-locality"
                    label="Localité"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.street !== null
                            ? property.property_data.location.street
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    street: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-street"
                    label="Rue"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.number !== null
                            ? property.property_data.location.number
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    number: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-number"
                    label="Numéro"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.province !== null
                            ? property.property_data.location.province
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    province: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-province"
                    label="Province"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.location.region !== null
                            ? property.property_data.location.region
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    region: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-region"
                    label="Région"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.location.latitude !== null
                            ? property.property_data.location.latitude
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    latitude: e.target.valueAsNumber,
                                },
                            },
                        }))
                    }
                    id="form-latitude"
                    label="Latitude"
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.location.longitude !== null
                            ? property.property_data.location.longitude
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    longitude: e.target.valueAsNumber,
                                },
                            },
                        }))
                    }
                    id="form-longitude"
                    label="Longitude"
                    sx={textFieldStyle}
                />

                {/*<SelectInput label={"Panneaux solaires"}>*/}
                {/*    <Select*/}
                {/*        value={property.property_data.energy.hasThermicPanels}*/}
                {/*        label="Panneaux solaires"*/}
                {/*        onChange={(e) =>*/}
                {/*            setProperty((prevProperty) => ({*/}
                {/*                ...prevProperty,*/}
                {/*                property_data: {*/}
                {/*                    ...prevProperty.property_data,*/}
                {/*                    energy: {*/}
                {/*                        ...prevProperty.property_data.energy,*/}
                {/*                        hasThermicPanels: e.target.value,*/}
                {/*                    },*/}
                {/*                },*/}
                {/*            }))*/}
                {/*        }*/}
                {/*    >*/}
                {/*        <MenuItem value={false}>Non</MenuItem>*/}
                {/*        <MenuItem value={true}>Oui</MenuItem>*/}
                {/*    </Select>*/}
                {/*</SelectInput>*/}
            </InputsGroup>

            <InputsGroup label={"caractéristiques"}>
                <TextField
                    value={
                        property.property_data.location.floor !== null
                            ? property.property_data.location.floor
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                location: {
                                    ...prevProperty.property_data.location,
                                    floor: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-floor"
                    label="Etage du bien"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.building.floorCount !== null
                            ? property.property_data.building.floorCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                building: {
                                    ...prevProperty.property_data.building,
                                    floorCount: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-floor-quantity"
                    label="Nombre d'étage(s)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.building.facadeCount !== null
                            ? property.property_data.building.facadeCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                building: {
                                    ...prevProperty.property_data.building,
                                    facadeCount: e.target.valueAsNumber,
                                },
                            },
                        }))
                    }
                    id="form-facade-count"
                    label="Nombre de façade(s)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.building.constructionYear !==
                        null
                            ? property.property_data.building.constructionYear
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) => {
                        let value = e.target.value;
                        value = isNaN(value) ? null : value;

                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                building: {
                                    ...prevProperty.property_data.building,
                                    constructionYear: value,
                                },
                            },
                        }));
                    }}
                    id="form-construction-year"
                    label="Année de construction"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.netHabitableSurface !== null
                            ? property.property_data.netHabitableSurface
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                netHabitableSurface: e.target.value,
                            },
                        }))
                    }
                    id="form-surface"
                    label="Surface"
                    prefix={"M²"}
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.parking.indoor !== null
                            ? property.property_data.parking.indoor
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                parking: {
                                    ...prevProperty.property_data.parking,
                                    indoor: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-indoor-parkings"
                    label="Nombre de parking(s) intérieur(s)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.parking.outdoor !== null
                            ? property.property_data.parking.outdoor
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                parking: {
                                    ...prevProperty.property_data.parking,
                                    outdoor: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-outdoor-parkings"
                    label="Nombre de parking(s) extérieur(s)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.parking.closed_box !== null
                            ? property.property_data.parking.closed_box
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                parking: {
                                    ...prevProperty.property_data.parking,
                                    closed_box: e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-closed-parkings"
                    label="Nombre de parking(s) clos"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.bedroomCount !== null
                            ? property.property_data.bedroomCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                bedroomCount: e.target.value,
                            },
                        }))
                    }
                    id="form-bedroom-count"
                    label="Nombre de chambre(s)"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.roomCount !== null
                            ? property.property_data.roomCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                roomCount: e.target.value,
                            },
                        }))
                    }
                    id="form-room-count"
                    label="Nombre de pièces"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.toiletsCount !== null
                            ? property.property_data.toiletsCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                toiletsCount: e.target.value,
                            },
                        }))
                    }
                    id="form-toilet-count"
                    label="Nombre de toilettes séparées"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <TextField
                    value={
                        property.property_data.bathroomCount !== null
                            ? property.property_data.bathroomCount
                            : ""
                    }
                    type={"number"}
                    inputProps={{
                        min: 0,
                        step: 1,
                    }}
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                bathroomCount: e.target.value,
                            },
                        }))
                    }
                    id="form-bathroom-count"
                    label="Nombre de salle(s) de bain"
                    variant="outlined"
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.constructionPermit
                            .totalBuildableGroundFloorSurface !== null
                            ? property.property_data.constructionPermit
                                  .totalBuildableGroundFloorSurface
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                constructionPermit: {
                                    ...prevProperty.property_data
                                        .constructionPermit,
                                    totalBuildableGroundFloorSurface:
                                        e.target.value,
                                },
                            },
                        }))
                    }
                    id="form-construct-surface"
                    label="Surface constructible/construite"
                    prefix={"M²"}
                    sx={textFieldStyle}
                />

                <NumericInputOnChange
                    value={
                        property.property_data.land.surface !== null
                            ? property.property_data.land.surface
                            : ""
                    }
                    onChange={(e) =>
                        setProperty((prevProperty) => ({
                            ...prevProperty,
                            property_data: {
                                ...prevProperty.property_data,
                                land: {
                                    ...prevProperty.property_data.land,
                                    surface: e.target.checked,
                                },
                            },
                        }))
                    }
                    id="form-outdoor-surface"
                    label="Surface extérieure"
                    prefix={"M²"}
                    sx={textFieldStyle}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={property.property_data.basement.exist}
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        basement: {
                                            ...prevProperty.property_data
                                                .basement,
                                            exist: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Cave"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={property.property_data.attic.exist}
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        attic: {
                                            ...prevProperty.property_data.attic,
                                            exist: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Grenier"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                property.property_data.bot_data
                                    .convertible_attic
                            }
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        bot_data: {
                                            ...prevProperty.property_data
                                                .bot_data,
                                            convertible_attic: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Grenier aménageable"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={property.property_data.balcony.exist}
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        balcony: {
                                            ...prevProperty.property_data
                                                .balcony,
                                            exist: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Balcon"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={property.property_data.terrace.exist}
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        terrace: {
                                            ...prevProperty.property_data
                                                .terrace,
                                            exist: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Terrasse"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={property.property_data.garden.exist}
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        garden: {
                                            ...prevProperty.property_data
                                                .garden,
                                            exist: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Jardin"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                property.property_data.installations.hasLift
                            }
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        installations: {
                                            ...prevProperty.property_data
                                                .installations,
                                            hasLift: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Ascenseur"
                />
            </InputsGroup>

            <InputsGroup labelId={"form-heating-type"} label={"Energie"}>
                <SelectInput label={"Chauffage"}>
                    <Select
                        value={property.property_data.energy.heatingType}
                        label="typeChauffage"
                        onChange={(e) =>
                            setProperty((prevProperty) => ({
                                ...prevProperty,
                                property_data: {
                                    ...prevProperty.property_data,
                                    energy: {
                                        ...prevProperty.property_data.energy,
                                        heatingType: e.target.checked,
                                    },
                                },
                            }))
                        }
                        style={textFieldStyle}
                        id={"form-heating-type"}
                        labelId={"form-heating-type"}
                    >
                        {chauffage_type.map((elem, index) => (
                            <MenuItem key={index} value={elem}>
                                {elem}
                            </MenuItem>
                        ))}
                    </Select>
                </SelectInput>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                property.property_data.energy
                                    .hasPhotovoltaicPanels
                            }
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        energy: {
                                            ...prevProperty.property_data
                                                .energy,
                                            hasPhotovoltaicPanels:
                                                e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Panneaux photovoltaïques"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                property.property_data.energy.hasThermicPanels
                            }
                            onChange={(e) =>
                                setProperty((prevProperty) => ({
                                    ...prevProperty,
                                    property_data: {
                                        ...prevProperty.property_data,
                                        energy: {
                                            ...prevProperty.property_data
                                                .energy,
                                            hasThermicPanels: e.target.checked,
                                        },
                                    },
                                }))
                            }
                        />
                    }
                    label="Panneaux solaires"
                />

                <SelectInput labelId={"form-peb"} label={"PEB"}>
                    <Select
                        value={property.property_data.certificats.epcScore}
                        label="PEB"
                        onChange={(e) =>
                            setProperty((prevProperty) => ({
                                ...prevProperty,
                                property_data: {
                                    ...prevProperty.property_data,
                                    certificats: {
                                        ...prevProperty.property_data
                                            .certificats,
                                        epcScore: e.target.value,
                                    },
                                },
                            }))
                        }
                        style={textFieldStyle}
                        id={"form-peb"}
                        labelId={"form-peb"}
                    >
                        {peb_scores.map((elem, index) => (
                            <MenuItem key={index} value={elem}>
                                {elem}
                            </MenuItem>
                        ))}
                    </Select>
                </SelectInput>
            </InputsGroup>

            <InputsGroup label={"Photos"}>
                <div className="new-by-form__image-upload">
                    <ImagesUpload images={images} setImages={setImages} />
                </div>
            </InputsGroup>

            <ClearButton save={reset} type={3} />
            <SaveButton save={handleSave} type={4} />
        </div>
    );
};

export default connect(null, mapDispatchToProps)(NewByForm);
