import settings from "../settings";

const getCoordinatesFromPostalCode = async (postalCode) => {
    const apiKey = settings.googleMapsApiKey;
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${apiKey}`;

    try {
        const response = await fetch(geocodingUrl);
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
            return data.results[0].geometry.location;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error with Geocoding request :", error);
        return null;
    }
};

export default getCoordinatesFromPostalCode;
