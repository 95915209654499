import React, { useEffect, useMemo, useState } from "react";
import {
    TextField,
    Radio,
    FormControlLabel,
    RadioGroup,
    FormControl,
    InputAdornment,
} from "@mui/material";
import formatNumber from "../../helpers/formatNumber";
import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorClassicLoan = ({
    monthlyRent,
    setMonthlyRent,
    yieldCalculatorSettings,
    investTotal,
    bring,
    bulletLoanAmount,
    classicLoanAmount,
    setClassicLoanAmount,
}) => {
    const [value, setValue] = useState("classic-loan-1");
    const [inputRate, setInputRate] = useState(0);
    const [inputYears, setInputYears] = useState(0);

    const { rate25Years, rate20Years, rate15Years } = useMemo(
        () => ({
            rate25Years: yieldCalculatorSettings?.classicLoan25YearsRate || 0,
            rate20Years: yieldCalculatorSettings?.classicLoan20YearsRate || 0,
            rate15Years: yieldCalculatorSettings?.classicLoan15YearsRate || 0,
        }),
        [yieldCalculatorSettings]
    );

    const [rate, setRate] = useState(0);
    const [years, setYears] = useState(25);

    const handleChange = (event) => {
        setValue(event.target.value);
        switch (event.target.value) {
            case "classic-loan-1":
                setRate(rate25Years);
                setYears(25);
                break;
            case "classic-loan-2":
                setRate(rate20Years);
                setYears(20);
                break;
            case "classic-loan-3":
                setRate(rate15Years);
                setYears(15);
                break;
            case "classic-loan-4":
                setRate(inputRate);
                setYears(inputYears);
                break;
            default:
                break;
        }
    };

    // const handleClassicLoanAmountChange = (event) => {
    //     const value = event.target.value;
    //     const parsed = Number.parseFloat(value, 10); // inputs are strings
    //
    //     setClassicLoanAmount(Number.isNaN(parsed) ? 0 : parsed); // sometimes parsed is NaN
    // };

    const handleInputChange = (event) => {
        if (event.target.name === "classic-loan-rate") {
            setInputRate(parseFloat(event.target.value));
            setRate(parseFloat(event.target.value));
        } else if (event.target.name === "classic-loan-years") {
            setInputYears(parseFloat(event.target.value));
            setYears(parseFloat(event.target.value));
        }
    };

    const calculateMonthlyRent = () => {
        if (rate === 0 || years === 0) return 0;

        const monthlyRate = rate / 100 / 12;
        const months = years * 12;
        const denominator = Math.pow(1 + monthlyRate, months) - 1;
        const monthlyRent =
            classicLoanAmount * (monthlyRate + monthlyRate / denominator);

        return monthlyRent;
    };

    useEffect(() => {
        setMonthlyRent(calculateMonthlyRent());
    }, [rate, years, classicLoanAmount]);

    useEffect(() => {
        setClassicLoanAmount(investTotal - bring - bulletLoanAmount);
    }, [investTotal, bring, bulletLoanAmount]);

    useEffect(() => {
        if (
            yieldCalculatorSettings &&
            yieldCalculatorSettings.classicLoan25YearsRate
        ) {
            setRate(yieldCalculatorSettings.classicLoan25YearsRate);
        }
    }, [yieldCalculatorSettings]);

    return (
        <div className="yield-calculator__classic-loan">
            <h2>Crédit classique</h2>

            <YieldCalculatorInput
                value={classicLoanAmount}
                setValue={setClassicLoanAmount}
                className={"yield-calculator__input"}
                label={"Montant crédit classique"}
                id={"yield-calculator-loan-amount"}
                prefix={"€"}
            />

            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="choix"
                    name="choix"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="classic-loan-1"
                        control={<Radio />}
                        label={`${rate25Years}% sur 25 ans (300 mensualités)`}
                    />
                    <FormControlLabel
                        value="classic-loan-2"
                        control={<Radio />}
                        label={`${rate20Years}% sur 20 ans (240 mensualités)`}
                    />
                    <FormControlLabel
                        value="classic-loan-3"
                        control={<Radio />}
                        label={`${rate15Years}% sur 15 ans (180 mensualités)`}
                    />
                    <FormControlLabel
                        value="classic-loan-4"
                        control={<Radio />}
                        label={
                            <div>
                                <TextField
                                    className="yield-calculator__input"
                                    name="classic-loan-rate"
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={value !== "classic-loan-4"}
                                    label="Taux"
                                    placeholder="%"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ width: "10.5rem" }}
                                />

                                <TextField
                                    className="yield-calculator__input"
                                    name="classic-loan-years"
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={value !== "classic-loan-4"}
                                    label="Années"
                                    placeholder="Années"
                                    sx={{ width: "10.5rem" }}
                                />
                            </div>
                        }
                    />
                </RadioGroup>
            </FormControl>

            <div className="yield-calculator__total">
                Remboursement crédit classique annuel :{" "}
                <span>{`${formatNumber(monthlyRent * 12, 0)}€`}</span>
            </div>

            <div className="yield-calculator__total">
                Remboursement crédit classique mensuel :{" "}
                <span>{`${formatNumber(monthlyRent, 0)}€`}</span>
            </div>

            <div className="yield-calculator__total">
                Coût total du crédit classique :{" "}
                <span>{`${formatNumber(monthlyRent * 12 * years, 0)}€`}</span>
            </div>
        </div>
    );
};

export default YieldCalculatorClassicLoan;
