import React from "react";
import no_pics from "../../../assets/no_pics.svg";
import { home_type } from "../../../constants/constant_lists";
import formatNumber from "../../../helpers/formatNumber";
import PropertyElemHeader from "./PropertyElemHeader";
import PropertyElemStateIcons from "./PropertyElemStateIcons";

import "./propertyElemContentIsColloc.scss";

const PropertyContentIsColloc = ({
    views,
    visible,
    rent,
    // rdm,
    certificats,
    pebLetter,
    location,
    netHabitableSurface,
    price,
    subtype,
    pictures,
    creationDate,
    spotlight,
    flagPriceDecrease,
    flagFastSale,
    flagAlreadySold,
    isViewsIsVisible,
    opt_weekday,
    bedroomCount,
    rc,
    freezeByMe,
    favoriteOfMe,
}) => {
    return (
        <article
            className={`property-element__content ${
                visible === false && "admin-only"
            }`}
        >
            <PropertyElemHeader
                rent={rent}
                views={views}
                certificats={certificats}
                pebLetter={pebLetter}
                pictures={pictures}
                no_pics={no_pics}
                isViewsIsVisible={isViewsIsVisible}
                isRentIsVisible={false}
            />

            <main className="property-element__flatsharing__infos">
                <div className="property-element__flatsharing__infos__header">
                    <div className="property-element__flatsharing__housing-type">
                        {home_type[subtype] ? home_type[subtype] : "Autre"}
                    </div>

                    <div className="property-element__flatsharing__location">
                        {location.locality}
                        {netHabitableSurface && ` - ${netHabitableSurface}m²`}
                    </div>

                    <div className="property-element__flatsharing__price">
                        {formatNumber(price)}€
                    </div>

                    <div className="property-element__flatsharing__square-meter-price">
                        {netHabitableSurface && (
                            <>
                                <span>Prix au m² : </span>
                                <span>{`${formatNumber(
                                    price / netHabitableSurface
                                )}€`}</span>
                            </>
                        )}
                    </div>
                </div>

                <div className={"property-element__flatsharing__infos__bottom"}>
                    <div className="property-element__flatsharing__infos__main">
                        <div className="property-element__flatsharing__flatsharing-infos">
                            {bedroomCount ? (
                                <div className="property-element__flatsharing__flatsharing-infos__text">
                                    {bedroomCount +
                                        (bedroomCount > 1
                                            ? " chambres"
                                            : " chambre")}
                                </div>
                            ) : null}

                            {rc ? (
                                <div className="property-element__flatsharing__flatsharing-infos__text">
                                    RC : {rc} €
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="property-element__flatsharing__infos__footer">
                        <div className="property-element__flatsharing__publication-date">
                            Date de publication :{" "}
                            {new Date(creationDate).toLocaleDateString(
                                `fr-FR`,
                                opt_weekday
                            )}
                        </div>
                    </div>
                </div>

                {/* {
						elem.property_data.building?.condition ?
						<p>Etat du bien: {good_state[elem.property_data.building?.condition]}</p> : null
					} */}
                {/* {
						new Date(elem.publication.lastModificationDate).toDateString() !== new Date(elem.publication.creationDate).toDateString() ?
						<p>Dernière modification: {new Date(elem.publication.lastModificationDate).toLocaleDateString(`fr-FR`, opt_weekday)}</p> : null
					} */}
            </main>
            <PropertyElemStateIcons
                spotlight={spotlight}
                flagPriceDecrease={flagPriceDecrease}
                flagFastSale={flagFastSale}
                flagAlreadySold={flagAlreadySold}
                freezeByMe={freezeByMe}
                favoriteOfMe={favoriteOfMe}
            />
        </article>
    );
};

export default PropertyContentIsColloc;
