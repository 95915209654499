import React from "react";

import "./noAccessScreen.scss";
import ImmovestorButton from "../../components/immovestorButton/ImmovestorButton";

const NoAccessScreen = ({ link, buttonName, message }) => {
    return (
        <div className="main_view--no-access">
            <p>{message}</p>
            <ImmovestorButton href={link}>{buttonName}</ImmovestorButton>
        </div>
    );
};

export default NoAccessScreen;
