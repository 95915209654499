import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import './videoPlayer.scss';
import '../../styles/components/buttons.scss';

import playIcon from './assets/play_arrow_FILL0_wght400_GRAD0_opsz48.svg';
import pauseIcon from './assets/pause_FILL0_wght400_GRAD0_opsz48.svg';
import unMuteIcon from './assets/volume_up_FILL0_wght400_GRAD0_opsz48.svg';
import muteIcon from './assets/volume_off_FILL0_wght400_GRAD0_opsz48.svg';


const VideoPlayer = ({url}) => {

    const DEFAULT_VOLUME = .8;

    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(false);

    const handlePlayClick = () => setPlay(!play);

    const handleMuteClick = () => setMute(!mute);

    return (
        <div className={'video-player__container'}>

            <div className={'video-player__video'}>
                <ReactPlayer
                    url={url}
                    playing={play}
                    volume={DEFAULT_VOLUME}
                    muted={mute}
                    width='100%'
                    height='100%'
                />
            </div>

            <div className={'video-player__controls'}>

                <button
                    className='video-player__controls__button immo-button immo-button--primary'
                    onClick={handlePlayClick}
                >
                    <img
                        src={ play ? pauseIcon : playIcon }
                        alt={ play ? 'mettre la vidéo en pause' : 'lire la vidéo'}
                    />
                </button>

                <button
                    className={'video-player__controls__button  immo-button immo-button--primary'}
                    onClick={handleMuteClick}
                >
                    <img
                        src={ mute ? unMuteIcon : muteIcon }
                        alt={ mute ? 'remettre le son' : 'couper le son'}
                    />
                </button>
            </div>

        </div>
    )

}

export default VideoPlayer;
