import React, { useState } from "react";
import "./style.scss";
import Filters from "../../../components/admin/market_data/filters/Filters";
import axios from "axios";
import ShowData from "../../../components/admin/market_data/show/ShowData";
import useGoogleMaps from "../../../hooks/useGooglleMaps/useGoogleMaps";

const MarketData = () => {
    const [marketData, setMarketData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [coordinates, setCoordinates] = useState(null);

    const { isGoogleLoaded } = useGoogleMaps();

    const search = async (filters) => {
        setLoading(true);
        const data = {};
        await axios
            .post("https://immovestor.timour.me/api/statistic-data", filters, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                data.average_rent = response.data.average_rent;
                data.number_of_records = response.data.number_of_records;
            })
            .catch((error) => {
                return null;
            });

        await axios
            .post(
                "https://immovestor.timour.me/api/statistic-data/properties",
                filters,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                data.properties = response.data.properties;
            })
            .catch((error) => {
                return null;
            });

        setMarketData(data);

        setLoading(false);
    };

    return (
        <div className="market-data__container">
            <Filters
                set_filters={search}
                loading={loading}
                isGoogleLoaded={isGoogleLoaded}
                setCoordinates={setCoordinates}
            />
            <div className="market-data__results">
                <ShowData
                    data={marketData}
                    isGoogleLoaded={isGoogleLoaded}
                    coordinates={coordinates}
                />
            </div>
        </div>
    );
};

export default MarketData;
