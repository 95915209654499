import React, { useEffect, useState } from "react";
import formatNumber from "../../helpers/formatNumber";
import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorInvest = ({
    propertyPrice,
    setPropertyPrice,
    investTotal,
    setInvestTotal,
    yieldCalculatorSettings,
}) => {
    const [registrationFeeRate, setRegistrationFeeRate] = useState(0);
    const [otherFees, setOtherFees] = useState(500);
    const [renovationCost, setRenovationCost] = useState(0);
    const [registrationPrice, setRegistrationPrice] = useState(0);

    useEffect(() => {
        setRegistrationPrice(
            propertyPrice * (parseFloat(registrationFeeRate) / 100)
        );

        const total =
            parseFloat(propertyPrice) +
            parseFloat(registrationPrice) +
            parseFloat(otherFees) +
            parseFloat(renovationCost);

        setInvestTotal(total);
    }, [
        propertyPrice,
        registrationFeeRate,
        otherFees,
        renovationCost,
        registrationPrice,
    ]);

    useEffect(() => {
        setRegistrationFeeRate(
            yieldCalculatorSettings
                ? yieldCalculatorSettings.registrationFeeRate
                : 0
        );
    }, [yieldCalculatorSettings]);

    return (
        <div className="yield-calculator__invest">
            <h2 className="yield-calculator__invest__title">Investissement</h2>

            <YieldCalculatorInput
                value={propertyPrice}
                setValue={setPropertyPrice}
                className={"yield-calculator__input"}
                label={"Prix du bien"}
                id={"yield-calculator-price"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={registrationFeeRate}
                setValue={setRegistrationFeeRate}
                className={"yield-calculator__input"}
                label={"Frais d’enregistrement"}
                id={"yield-calculator-registration-fee-rate"}
                prefix={"%"}
            />

            <YieldCalculatorInput
                value={otherFees}
                setValue={setOtherFees}
                className={"yield-calculator__input"}
                label={"Autres frais"}
                id={"yield-calculator-other-fees"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={renovationCost}
                setValue={setRenovationCost}
                className={"yield-calculator__input"}
                label={"Travaux"}
                id={"yield-calculator-renovation-cost"}
                prefix={"€"}
            />

            <div className="yield-calculator__total">
                Total des frais d'enregistrement :{" "}
                <span>{formatNumber(registrationPrice)} €</span>
            </div>

            <div className="yield-calculator__total">
                Total des investissements :{" "}
                <span>{formatNumber(investTotal)} €</span>
            </div>
        </div>
    );
};

export default YieldCalculatorInvest;
