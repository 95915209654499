import React from "react";
import formatNumber from "../../helpers/formatNumber";
import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorRent = ({ mensualRent, setMensualRent }) => {
    return (
        <div className="yield-calculator__rent-container">
            <h2>Loyer</h2>
            <YieldCalculatorInput
                value={mensualRent}
                setValue={setMensualRent}
                className={"yield-calculator__input"}
                label={"Loyer mensuel"}
                id={"yield-calculator-rent"}
                prefix={"€"}
            />
            <div className="yield-calculator__total">
                Loyer annuel :{" "}
                <span>{`${formatNumber(mensualRent * 12)} €`}</span>
            </div>
        </div>
    );
};

export default YieldCalculatorRent;
