import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/PopupContext';

import { ThemeProvider } from "@mui/material";
import muiTheme from "./muiTheme";
import { SearchProvider } from './contexts/SearchContext';
import { ToolsProvider } from './contexts/ToolsContext';
import { ModalProvider } from './contexts/ModalContext';

// here is the doc for theming mui :
// https://mui.com/material-ui/customization/color/
const theme = muiTheme;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<AuthProvider>
			<ToolsProvider>
				<SearchProvider>
					<ModalProvider>
						<ToastProvider>
							<ThemeProvider theme={theme}>
								<App />
							</ThemeProvider>
						</ToastProvider>
					</ModalProvider>
				</SearchProvider>
			</ToolsProvider>
		</AuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
