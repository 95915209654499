import React, { useEffect, useState } from "react";
import "./detailsDescription.scss";
import ReactShowMoreText from "react-show-more-text";
// import "../../../styles/components/buttons.scss";
import DisplayFilesDialog from "./displayFilesDialog";
import ImmovestorButton from "../../immovestorButton/ImmovestorButton";

const DetailsDescription = ({
    title,
    description,
    values,
    openModal,
    isAdmin,
    currentInvestmentUrl,
    investment,
}) => {
    const contact = investment.customers.length
        ? investment.customers[0]
        : null;
    const [contactByPhone, setContactByPhone] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [openFilesDialog, setOpenFilesDialog] = useState(false);

    useEffect(() => {
        const userAgent =
            typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        setIsMobile(/Mobi/i.test(userAgent));
    }, []);

    const handleClick = () => {
        if (isMobile) {
            window.location.href = `tel:${contact.phoneNumber}`;
        } else {
            setContactByPhone(true);
        }
    };

    return (
        <section className="description-detail">
            {title && <h3 className="description-detail__title">{title}</h3>}

            <ReactShowMoreText
                more="Lire la suite"
                less="Lire moins"
                lines={4}
                className="description-detail__text"
                anchorClass="description-detail__anchor"
                expanded={false}
            >
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </ReactShowMoreText>

            <div className="files-container">
                {isAdmin ? (
                    <a
                        target={"_blank"}
                        href={currentInvestmentUrl}
                        rel="noreferrer"
                        className="description-detail__anchor"
                    >
                        Voir sur immoweb
                    </a>
                ) : null}
                {investment?.media?.files?.length >= 0 && (
                    <div
                        rel="noreferrer"
                        className="description-detail__anchor"
                        onClick={() => setOpenFilesDialog(true)}
                    >
                        Voir les documents
                    </div>
                )}
            </div>

            <div className="description-detail__list">
                <ul>
                    {Object.entries(values).map((item, id) => {
                        return (
                            <li key={id}>
                                <span>{item[0]}</span>
                                <span>{item[1]}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {!contact ? null : (
                <div className="agency-contact">
                    {contact.website ? (
                        <a
                            href={contact.website}
                            target="_blank"
                            className="agency-data"
                            rel="noreferrer"
                        >
                            <img src={contact.logo} />
                            <span>{contact.name}</span>
                        </a>
                    ) : (
                        <div className="agency-data">
                            <img src={contact.logo} />
                            <span>{contact.name}</span>
                        </div>
                    )}
                    <div className="agency-actions">
                        <ImmovestorButton
                            onClick={() => openModal("contact")}
                            className="description-detail__more-infos-button"
                        >
                            Contacter par email
                        </ImmovestorButton>
                        {contact.phoneNumber ? (
                            contactByPhone ? (
                                <ImmovestorButton
                                    className="description-detail__more-infos-button"
                                    grey={true}
                                >
                                    {contact.phoneNumber}
                                </ImmovestorButton>
                            ) : (
                                <ImmovestorButton
                                    onClick={handleClick}
                                    className="description-detail__more-infos-button"
                                    grey={true}
                                >
                                    Contacter par telephone
                                </ImmovestorButton>
                            )
                        ) : null}
                    </div>
                </div>
            )}

            {!isAdmin && (
                <ImmovestorButton
                    onClick={() => openModal("report")}
                    className="description-detail__anomaly-button"
                    // color="secondary"
                    outlined={true}
                >
                    Signaler un anomalie
                </ImmovestorButton>
            )}
            <DisplayFilesDialog
                open={openFilesDialog}
                onClose={() => setOpenFilesDialog(false)}
                files={investment?.media?.files || []}
            />
        </section>
    );
};

export default DetailsDescription;
