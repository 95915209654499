import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./propertyElem.scss";
import AuthContext from "../../../contexts/AuthContext";
// import { useDispatch } from "react-redux";
import {
    youNeedAplanToSeeFlatsharingdModalSettings,
    youNeedAplanToSeeHighYieldModalSettings,
    youNeedAplanToSeeSpotlight,
} from "../../../constants/constant_modals";
import PropertyElemContent from "./PropertyElemContent";
import { propertiesSettings } from "../../../constants/constant_properties";
import { openModal } from "../../../store/slices/modalSlice";
import PropertyContentFreezed from "./PropertyElemContentFreezed";
// import { Tooltip } from "@mui/material";
import PropertyContentIsColloc from "./PropertyElemContentIsColloc";
import { ModalContext } from "../../../contexts/ModalContext";
import isPropertyIsFrozen from "../../../utils/isPropertyIsFrozen";
import hasPermission from "../../../utils/hasPermission";
import getSubscribeButtonText from "../../../helpers/getSubscribeButtonText";
import getSubscribeButtonLink from "../../../helpers/getSubscribeButtonLink";

const PropertyElem = ({ elem, index, loading, type, ignoreLimit }) => {
    const { user } = useContext(AuthContext);
    const { dispatch: modalDispatch } = useContext(ModalContext);

    // No idea why there is a limit of 20! - 01-08-2023
    if ((!ignoreLimit && index > 20) || !elem.property_data || !elem)
        return null;

    let opt_weekday = { year: "numeric", month: "numeric", day: "numeric" };

    const {
        categories,
        id,
        views,
        visible,
        property_data: {
            bot_data: { rent, rdm, rc },
            certificats,
            location,
            netHabitableSurface,
            price,
            subtype,
            bedroomCount,
        },
        media: { pictures },
        publication: { creationDate } = { creationDate: null },
        spotlight,
        freezedByMe,
        freezedUntil,
        favoriteOfMe,
        flagPriceDecrease,
        flagFastSale,
        flagAlreadySold,
        agencyId,
    } = elem;

    const isFrozen = isPropertyIsFrozen(user, elem);

    let pebLetter = certificats?.epcScore;

    if (pebLetter === "A++" || pebLetter === "A+") pebLetter = "A";

    const isViewsIsVisible = (views) => {
        return views !== null && views > 0 && user.isAdmin;
    };

    const isHighYield = rdm >= propertiesSettings.fremiumPlanMaxYield;
    const isFlatsharing = categories?.is_colloc;

    const handleNotAllowedToSeeClick = () => {
        if (isHighYield)
            modalDispatch({
                type: "openModal",
                payload: {
                    ...youNeedAplanToSeeHighYieldModalSettings,
                    buttonText: getSubscribeButtonText(user),
                    buttonLink: getSubscribeButtonLink(user),
                },
            });

        if (isFlatsharing)
            modalDispatch({
                type: "openModal",
                payload: {
                    ...youNeedAplanToSeeFlatsharingdModalSettings,
                    buttonText: getSubscribeButtonText(user),
                    buttonLink: getSubscribeButtonLink(user),
                },
            });

        if (spotlight)
            modalDispatch({
                type: "openModal",
                payload: {
                    ...youNeedAplanToSeeSpotlight,
                    buttonText: getSubscribeButtonText(user),
                    buttonLink: getSubscribeButtonLink(user),
                },
            });
    };

    const renderFreezedContent = (
        <>
            <PropertyContentFreezed
                views={views}
                visible={visible}
                rent={rent}
                rdm={rdm}
                certificats={certificats}
                pebLetter={pebLetter}
                location={location}
                netHabitableSurface={netHabitableSurface}
                price={price}
                subtype={subtype}
                pictures={pictures}
                creationDate={creationDate}
                spotlight={spotlight}
                flagPriceDecrease={flagPriceDecrease}
                flagFastSale={flagFastSale}
                flagAlreadySold={flagAlreadySold}
                isViewsIsVisible={isViewsIsVisible}
                opt_weekday={opt_weekday}
                freezedByMe={freezedByMe}
                freezedUntil={freezedUntil}
            />
        </>
    );

    const componentTypes = {
        // here, if type = "is_classic", PropertyElemContent is rendered
        is_classic: PropertyElemContent,
        is_colloc: PropertyContentIsColloc,
        // is_rbnb: PropertyContentIsRbnb, //future RBNB component
        default: PropertyElemContent,
    };

    const ComponentToRender = componentTypes[type] || componentTypes["default"];

    const renderContent = (
        <>
            <ComponentToRender
                views={views}
                visible={visible}
                rent={rent}
                rdm={rdm}
                certificats={certificats}
                pebLetter={pebLetter}
                location={location}
                netHabitableSurface={netHabitableSurface}
                price={price}
                subtype={subtype}
                pictures={pictures}
                creationDate={creationDate}
                spotlight={spotlight}
                flagPriceDecrease={flagPriceDecrease}
                flagFastSale={flagFastSale}
                flagAlreadySold={flagAlreadySold}
                isViewsIsVisible={isViewsIsVisible}
                opt_weekday={opt_weekday}
                bedroomCount={bedroomCount}
                rc={rc}
                freezeByme={freezedByMe}
                favoriteOfMe={favoriteOfMe}
            />
        </>
    );

    return (
        <li className={`property-element__container ${loading && "loading"}`}>
            {isFrozen ? (
                <div key={index}>{renderFreezedContent}</div>
            ) : (isHighYield && !hasPermission(user, "read_highYield")) ||
              (isFlatsharing && !hasPermission(user, "read_flatsharing")) ||
              (spotlight && !hasPermission(user, "read_spotlight")) ? (
                // yield too high or is a flatsharing property
                // so open modal to suggest buy plan when click
                <div onClick={handleNotAllowedToSeeClick} key={index}>
                    {renderContent}
                </div>
            ) : (
                // can link to details because user have permission to
                <Link
                    className="property-element__link"
                    to={`/details/${id}`}
                    key={index}
                >
                    {renderContent}
                </Link>
            )}
        </li>
    );
};

export default PropertyElem;
