/**
 * Generates filters object from the current URL query parameters.
 *
 * @param defaultFilter - The default filters used when there is no param in url.
 *
 * @returns {object} - An object representing filters applied to the URL.
 */
const generateFiltersFromUrl = (defaultFilter) => {
    const url = window.location.href.substring(
        window.location.href.indexOf("?")
    );
    const urlParams = new URLSearchParams(url);
    const keys = urlParams.keys();

    /**
     * define starting filters
     */
    const filterSort = {
        filter: {
            price_min: defaultFilter?.filter?.price_min || 0,
            price_max: defaultFilter?.filter?.price_max || 0,
            bedroom_min: defaultFilter?.filter?.bedroom_min || 0,
            bedroom_max: defaultFilter?.filter?.bedroom_max || 0,
            rdt_min: defaultFilter?.filter?.rdt_min || 0,
            rdt_max: defaultFilter?.filter?.rdt_max || 0,
            rent_min: defaultFilter?.filter?.rent_min || 0,
            rent_max: defaultFilter?.filter?.rent_max || 0,
            count_per_pages: defaultFilter?.filter?.count_per_pages || 12,
            place_type: defaultFilter?.filter?.place_type || "",
            conditions: defaultFilter?.filter?.conditions || "",
            epc_scores: defaultFilter?.filter?.epc_scores || "",
            spotlight: defaultFilter?.filter?.spotlight || false,
            zip_codes: defaultFilter?.filter?.zip_codes || [],
            category: defaultFilter?.filter?.category || "",
            agencyId: defaultFilter?.filter?.agencyId || null,
        },
        zip_codes: defaultFilter?.zip_codes || [],
        districts: defaultFilter?.districts || [],
        provinces: defaultFilter?.provinces || [],
        page: defaultFilter?.page || 0,
        sort: defaultFilter?.sort || "pert",
    };

    const getPostal = (urlParams, key) => {
        return decodeURIComponent(urlParams.get(key)).split(",");
    };

    for (const key of keys) {
        // if (key.includes("http") || key === "zip_codes") {
        switch (key) {
            case "sort":
                filterSort.sort = urlParams.get(key);
                break;
            case "page":
                filterSort.page = parseInt(urlParams.get(key)) - 1;
                break;
            case "post":
                filterSort.zip_codes = getPostal(urlParams, key);
                break;
            case "districts":
                filterSort.districts = getPostal(urlParams, key);
                break;
            case "provinces":
                filterSort.provinces = getPostal(urlParams, key);
                break;
            case "penny-house":
                filterSort.filter = {
                    price_min: 0,
                    price_max: 125000,
                    rdt_min: 0,
                    rdt_max: 0,
                    rent_min: 0,
                    rent_max: 0,
                    count_per_pages: 12,
                    place_type: "",
                    conditions: "",
                    zip_codes: [],
                    spotlight: false,
                };
                break;
            case "spotlight-set":
                filterSort.filter = {
                    price_min: 0,
                    price_max: 0,
                    rdt_min: 0,
                    rdt_max: 0,
                    rent_min: 0,
                    rent_max: 0,
                    count_per_pages: 12,
                    place_type: "",
                    conditions: "",
                    zip_codes: [],
                    spotlight: urlParams.get(key) === "true",
                };
                break;
            case "epc_scores":
                filterSort.filter[key] = decodeURIComponent(urlParams.get(key));
                break;
            case "spotlight":
                filterSort.filter[key] = urlParams.get(key) === "true";
                break;
            default:
                filterSort.filter[key] = urlParams.get(key);

                if (!isNaN(filterSort.filter[key])) {
                    filterSort.filter[key] = parseInt(filterSort.filter[key]);
                }
        }
        // }
    }

    return filterSort;
};

export default generateFiltersFromUrl;
