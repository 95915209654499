import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import "./freezedProperties.scss";
import PropertiesList from "../../components/propertiesList/PropertiesList";

/**
 * No check for access permissions since there is checked on App.jsx
 */
const FreezedProperties = ({ getFreezedProperties, freezedProperties }) => {
    const { user } = useContext(AuthContext);

    const isLoading = freezedProperties.isLoading;
    const freezedPropertiesList = freezedProperties.freezed
        ? freezedProperties.freezed
        : [];

    useEffect(() => {
        getFreezedProperties();
    }, [user]);

    return (
        <div className="freezed-properties-page">
            <h1 className="freezed-properties-page__title">
                Vos annonces bloquées
            </h1>

            <PropertiesList
                properties={freezedPropertiesList}
                loading={isLoading}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FreezedProperties);
