/**
 * Modal component for interactions that displays a customizable modal with props in ModalContext.
 *
 * YesButton set confirm to true in ModalContext
 * noButton set confirm to false in ModalContext
 * button is a button with a link to an other url
 * closeButton just close the modal
 *
 * !!! buttonText, buttonLink and showButton are now for link button. Should be renamed in something better.
 *
 *
 * showModal : show modal or not
 * text : the displayed text paragraph in the modal
 * title : the title that appear in the modal
 * buttonText : the text on the link button. By default : "Voir"
 * buttonLink : the link of the link button
 * showButton : show or not the link button
 * yesButtonText: text of the yes button. By default : "Oui"
 * noButtonText: text of the no button. By default : "Non"
 * closeButtonText: text of the close button. By default : "Fermer"
 * showYesButton : show or not the yes button
 * showNoButton : show or not the no button
 * showCloseButton : show or not the close button
 */
import React, { useCallback, useContext, useState } from "react";
import { Modal as MuiModal, Box, IconButton, TextField } from "@mui/material";

import axios from "../../services/axios";

import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineSend } from "react-icons/ai";

import { ModalContext } from "../../contexts/ModalContext";
import ToastContext from "../../contexts/PopupContext";
import { TOAST_LVL } from "../../constants/constant_lists";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";

import "./modal.scss";

import "./modal.scss";
import ImmovestorButton from "../immovestorButton/ImmovestorButton";

const Modal = () => {
    const { state, dispatch } = useContext(ModalContext);
    const { setToast } = useContext(ToastContext);

    const {
        showModal,
        text,
        title,
        buttonText,
        buttonLink,
        showButton,
        yesButtonText,
        noButtonText,
        showYesButton,
        showNoButton,
        isEmail,
        userEmail,
        sendButtonText,
        sendAddress,
        sendData,
        showCloseButton,
        closeButtonText,
        actionButtonText,
        actionButtonOnClick,
        showActionButton,
        showCustomContent,
        customContent,
    } = state;

    const renderButtonText = buttonText || "Voir";
    const renderSendButtonText = sendButtonText || "Envoyer";

    const [sending, setSending] = useState(false);
    const [email, setEmail] = useState(userEmail || "");
    const [message, setMessage] = useState("");

    const handleClose = () => {
        dispatch({ type: "closeModal" });
    };

    const handleYes = () => {
        dispatch({ type: "confirm" });
        handleClose();
    };

    const handleNo = () => {
        dispatch({ type: "decline" });
        handleClose();
    };

    const handleAction = actionButtonOnClick
        ? actionButtonOnClick
        : handleClose;

    const handleSend = async () => {
        setSending(true);
        try {
            await axios.post(sendAddress, { ...sendData, email, message });
            setToast.current(
                TOAST_LVL.SUCCESS,
                "Message envoyé",
                "Votre message nous a bien été envoyé."
            );
        } catch (error) {
            setToast.current(
                TOAST_LVL.ERROR,
                "Erreur",
                "Votre message ne nous est pas parvenu."
            );
        }

        setSending(false);

        handleClose();
    };

    useEffect(() => {
        setEmail(userEmail);
    }, [userEmail]);

    return (
        <MuiModal open={showModal} onClose={handleClose}>
            <Box className="modal-content">
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 10,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {title && <h6 className="modal-content__title">{title}</h6>}
                <p className="modal-content__text">{text}</p>

                {isEmail && (
                    <div className="modal-content__mail-container">
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                            margin="normal"
                        />
                    </div>
                )}

                <div className="modal-content__buttons-container">
                    {showButton && (
                        <ImmovestorButton href={buttonLink}>
                            {renderButtonText}
                        </ImmovestorButton>
                    )}
                    {showYesButton && (
                        <ImmovestorButton onClick={handleYes}>
                            {yesButtonText || "Oui"}
                        </ImmovestorButton>
                    )}
                    {showNoButton && (
                        <ImmovestorButton onClick={handleNo}>
                            {noButtonText || "Non"}
                        </ImmovestorButton>
                    )}
                    {showCloseButton && (
                        <ImmovestorButton onClick={handleClose}>
                            {closeButtonText || "Fermer"}
                        </ImmovestorButton>
                    )}
                    {showActionButton && (
                        <ImmovestorButton onClick={handleAction}>
                            {actionButtonText || "Oui"}
                        </ImmovestorButton>
                    )}
                    {isEmail && (
                        <LoadingButton
                            onClick={handleSend}
                            endIcon={<AiOutlineSend />}
                            loading={sending}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>{renderSendButtonText}</span>
                        </LoadingButton>
                    )}
                    {showCustomContent && customContent}
                </div>
            </Box>
        </MuiModal>
    );
};

export default Modal;
