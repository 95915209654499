import React, { useContext, useEffect, useState } from "react";
// import ReactPlayer from 'react-player/lazy'

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import "./home.scss";
// import empty_icon from "../../assets/empty_search.svg"
import { Button } from "@mui/material";
import SearchComponent from "../../components/home_invest/SearchComponent";
// import Loading from '../../components/main/loading/Loading'
import MainFilters from "../../components/home/MainFilters/MainFilters";
import white_arrow_right from "../../assets/white_arrow_right.svg";
// import AuthContext from '../../contexts/AuthContext'
// import PropertyElem from '../../components/home/property_elem/PropertyElem'
import generateUrlFromFilters from "../../helpers/generateUrlFromFilters";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
// import Slide from '@mui/material/Slide';
import axios from "../../services/axios";
import HomeCarousel from "../../components/home/HomeCarousel/HomeCarousel";
import { defaultFilterSort, filter_sort } from "../../contexts/SearchContext";
import AuthContext from "../../contexts/AuthContext";
import hasPermission from "../../utils/hasPermission";
// import { openModal } from '../../store/slices/modalSlice'
// import { youNeedAplanModalSettings } from '../../constants/constant_modals'
// import AuthContext from '../../contexts/AuthContext'

const forceFilterToSpotlight = (filter_sort) => {
    filter_sort.filter.spotlight = true;

    return filter_sort;
};

/**
 * This is used in Filters.jsx, but it seems that Filters.jsx screen is not used anymore.
 */

const Home = ({ investments, fetchInvestments }) => {
    const navigate = useNavigate();
    const [selection, setSelection] = useState([]);

    const { user } = useContext(AuthContext);

    /**
     * This demontrate how to show modal
     */
    // const dispatch = useDispatch();
    // useEffect(() => {
    // 	  dispatch(
    // 		  openModal(youNeedAplanModalSettings)
    // 		);
    // }, [dispatch]);

    const search_zip = (zips, others) => {
        filter_sort.zip_codes = others.zip_codes;
        filter_sort.provinces = others.provinces;
        filter_sort.districts = others.districts;
        filter_sort.filter.zip_codes = zips;
    };

    const handleClick = () => {
        if (JSON.stringify(filter_sort) != JSON.stringify(defaultFilterSort))
            fetchInvestments(filter_sort);
        navigate(generateUrlFromFilters(filter_sort, "/search?"));
    };

    const setup = async () => {
        const invest = await axios.post("/all-propreties", {
            filter: {
                spotlight: true,
            },
        });
        if (invest.status == 200) {
            setSelection(invest.data.propreties);
        }
    };

    useEffect(() => {
        setup();
    }, []);

    return (
        <section className="home-page">
            <div className="home_center">
                <div className="home">
                    <div className="subtitle">
                        Vous recherchez un bien immobilier de rendement ?
                    </div>
                    <div className="title">
                        Immovestor déniche pour vous, les plus belles pépites du
                        marché
                    </div>
                    <div className="filters">
                        <SearchComponent
                            filters={{
                                zip_codes: filter_sort.zip_codes,
                                districts: filter_sort.districts,
                                provinces: filter_sort.provinces,
                            }}
                            search_zip={search_zip}
                        />
                        <MainFilters filter_sort={filter_sort} />
                    </div>
                    <Button
                        className="goSearch"
                        onClick={handleClick}
                        fullWidth
                        variant="outlined"
                        endIcon={
                            <img src={white_arrow_right} alt={"chercher"} />
                        }
                    >
                        Accéder aux biens de rendement
                    </Button>
                </div>
            </div>
            <div className="hook_text">
                <VideoPlayer url={"video/homeVideo_Pitch_V4.mp4"} />
                <div className="separation"></div>
                <div className="title">
                    Un Cash flow directement après achat ... <br />
                    ... des performances brutes supérieures à 5%
                </div>
                <div className="subtitle">ça vous tente ?</div>
                {/* hide visual separation when cannot show spotlight */}
                {user.permissions.read_spotlight && <div className="separation"></div>}
            </div>
            {user.permissions.read_spotlight && (
                <div className="spotlight">
                    <div className="title">Notre sélection</div>
                    {selection.length === 0 ? (
                        <div className="empty-search"></div>
                    ) : (
                        <HomeCarousel
                            filteredInvestments={selection}
                            loading={false}
                        />
                    )}
                </div>
            )}
        </section>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
