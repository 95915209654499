import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react'
import { precision_search, search } from '../../functions/search'
// import SearchIcon from '@mui/icons-material/Search';
import "./searchComponent.scss"
// import generateFiltersFromUrl from '../../helpers/generateFiltersFromUrl';

let timout = null;

const SearchResult = ({results, add_result}) => {
	const prov = Object.keys(results.provinces);
	const district = Object.keys(results.districts);
	const localities = Object.keys(results.localities);

	if (!prov.length && !district.length && !localities.length)
		return null;

	return (
		<div className='search-results'>
			<div className='container'>
			{
				district.length ?
				<div className='search-block'>
					<span>Arrondissement</span>
					{
						district.map((elem, id) => (
							<div onClick={() => add_result(`${elem} (Arrondissement)`, results.districts[elem], "district", elem)} key={id} className='clickable-block'>
								<span>{elem} (Arrondissement)</span>
							</div>
						))
					}
				</div> : null
			}
			{
				prov.length ?
				<div className='search-block'>
					<span>Province</span>
					{
						prov.map((elem, id) => (
							<div onClick={() => add_result(`${elem} (Province)`, results.provinces[elem], "province", elem)} key={id} className='clickable-block'>
								<span>{elem} (Province)</span>
							</div>
						))
					}
				</div> : null
			}
			{
				localities.length ?
				<div className='search-block'>
					<span>Localite</span>
					{
						localities.map((elem, id) => (
							<div onClick={() => add_result(`${elem} (${results.localities[elem][0]})`, results.localities[elem], "postal", `${elem}`)} key={id} className='clickable-block'>
								<span>{elem} ({results.localities[elem][0]})</span>
							</div>
						))
					}
				</div> : null
			}
			</div>
		</div>
	)
}

const generate_zips_load = (filters) => {
	const prec = precision_search(filters);

	const prec_v = [
		...Object.keys(prec.localities).map((elem) => ({
			label: `${elem} (${prec.localities[elem][0]})`,
			zips: prec.localities[elem],
			type: "postal",
			value: `${elem}`,
		})),
		...Object.keys(prec.provinces).map((elem) => ({
			label: `${elem} (Province)`,
			zips: prec.provinces[elem],
			type: "province",
			value: elem,
		})),
		...Object.keys(prec.districts).map((elem) => ({
			label: `${elem} (Arrondissement)`,
			zips: prec.districts[elem],
			type: "district",
			value: elem,
		})),
	];

	return prec_v;
}

const Search = ({search_zip, filters, reset}) => {
	const [searchResult, setSearchResult] = useState(null);
	const [selectedResults, setSelectedResults] = useState([]);
	const no_reload = useRef(false);
	const first_load = useRef(true);
	const input = useRef(null);

	useEffect(() => {
		if (selectedResults.length && !no_reload.current)
			handleSubmit(selectedResults);
		no_reload.current = false;
	}, [selectedResults])

	useEffect(() => {
		if (reset) {
			setSelectedResults([]);
		}
	}, [reset])

	const add_result = (label, zips, type, value) => {
		setSearchResult(search(""));
		input.current.value = ""
		if (selectedResults.filter(elem => elem.label === label).length === 0)
			setSelectedResults([...selectedResults, {label: label, zips: zips, type: type, value: value}])
	}

	const handleKeyDown = (e) => {
		clearTimeout(timout);
		timout = setTimeout(() => {
			setSearchResult(search(e.target.value));
		}, 300);
	}

	const remove_selected = (label) => {
		const removed = [...selectedResults];
		const to_rem = removed.filter(elem => elem.label === label);
		if (to_rem.length) {
			removed.splice(removed.indexOf(to_rem[0]), 1);
		}
		setSelectedResults(removed);
		handleSubmit(removed);
	}

	const handleSubmit = (selectedResults) => {
		const all_zip = [];
		const others = {
			zip_codes: [],
			districts: [],
			provinces: [],
		}
		for (let index = 0; index < selectedResults.length; index++) {
			const element = selectedResults[index];
			if (element.type === "postal") {
				others.zip_codes.push(element.value);
			} else if (element.type === "district") {
				others.districts.push(element.value)
			} else if (element.type === "province") {
				others.provinces.push(element.value)
			}
			for (let zip_id = 0; zip_id < element.zips.length; zip_id++) {
				const zip = element.zips[zip_id];
				if (!all_zip.includes(zip))
					all_zip.push(zip.toString())
			}
		}
		search_zip(all_zip, others);
	}

	if (first_load.current && filters.zip_codes.length + filters.districts.length + filters.provinces.length != 0) {
		first_load.current = false;
		no_reload.current = true;
		setSelectedResults(generate_zips_load(filters));
	}

	return (
		<div className='main-search'>
			<form onClick={() => input.current.focus()}>
				{
					selectedResults.map((elem, id) => (
						<div className='selected-item' onClick={() => remove_selected(elem.label)} key={id}>
							<span>{elem.label}</span>
						</div>
					))
				}
				<input
					placeholder={
						selectedResults.length ? "Un autre lieu ?" : "Entrez une ville ou un code postal"
					}
					ref={input}
					onKeyDown={handleKeyDown}
					type="text"
				/>
			</form>
			{
				searchResult ?
				<SearchResult add_result={add_result} results={searchResult}/>
				: null
			}
		</div>
	)
}

export default Search
