import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import "./search.scss";
import SearchComponent from "../../components/home_invest/SearchComponent";
import MainFilters from "../../components/home/MainFilters/MainFilters";
import AdvancedFilters from "../../components/AdvancedFilters/AdvancedFilters";
import generateUrlFromFilters from "../../helpers/generateUrlFromFilters";
import ListView from "../../components/search/ListView/ListView";
import MapView from "../../components/search/MapView/MapView";
import { filter_sort } from "../../contexts/SearchContext";
import ImmovestorButton from "../../components/immovestorButton/ImmovestorButton";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import AuthContext from "../../contexts/AuthContext";

import SortIcon from "@mui/icons-material/Sort";

const Search = ({ investments, fetchInvestments }) => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const [searchParams, setSearchParams] = useSearchParams();

    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [mapOpen, setMapOpen] = useState(
        window.location.href.includes("/map")
    );
    // const [reloadSearch, setreloadSearch] = useState(0);

    const [openCriteria, setOpenCriteria] = useState(false);

    // console.log("filter sort: ", filter_sort);

    const search_zip = (zips, others) => {
        filter_sort.zip_codes = others.zip_codes;
        filter_sort.provinces = others.provinces;
        filter_sort.districts = others.districts;
        filter_sort.filter.zip_codes = zips;
        filter_sort.page = 0;
        fetchInvestments(filter_sort);
    };

    const filteredInvestments = investments.propreties;

    useEffect(() => {
        if (mapOpen) {
            document.getElementById("footer").style.display = "none";
        } else {
            document.getElementById("footer").style.display = "flex";
        }
    }, [mapOpen]);

    useEffect(() => {
        if (!investments.loading && !investments.first_load) {
            const next_url = generateUrlFromFilters(filter_sort, "/search?");
            let href = window.location.href;
            href = href.substring(href.indexOf("/#/") + 2);
            if (next_url !== href) {
                navigate(next_url);
            }
        }
    }, [investments]);

    const handleOpenCriteria = () => {
        setOpenCriteria(!openCriteria);
    };

    const handleMapOpen = () => {
        setMapOpen(true);
        navigate({
            pathname: "/map",
            search: `?${window.location.href.split("?")[1]}`,
        });
    };

    const navigate_fetch = () => {
        if (!user.permissions.read_spotlight)
            filter_sort.filter.spotlight = false;

        fetchInvestments(filter_sort);
    };

    const sortInvests = (v) => {
        filter_sort.sort = v;
        filter_sort.page = 0;
        navigate_fetch();
    };

    return (
        <section className={`search-page ${mapOpen ? "map" : ""}`}>
            {mapOpen ? null : (
                <div className="first">
                    <div className={"search-page__mobile-buttons-container"}>
                        <ImmovestorButton
                            className={`search-page__criteria-button ${
                                openCriteria ? "open" : "closed"
                            }`}
                            onClick={handleOpenCriteria}
                            outlined
                            grey
                        >
                            Critères
                        </ImmovestorButton>

                        <ImmovestorButton
                            className={`search-page__criteria-button`}
                            onClick={handleMapOpen}
                            outlined
                            grey
                        >
                            Map
                        </ImmovestorButton>

                        <div className="search-page__search-container">
                            {/*<p*/}
                            {/*    className={*/}
                            {/*        "search-page__search-container__label"*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    Trier par*/}
                            {/*</p>*/}
                            <Select
                                labelId="sort-select-label"
                                className="search-page__select"
                                defaultValue={
                                    searchParams.get("sort")
                                        ? searchParams.get("sort")
                                        : "pert"
                                }
                                onChange={(e) => sortInvests(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SortIcon />
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value={"pert"}>Plus récent</MenuItem>
                                <MenuItem value={"price_up"}>
                                    Le moins cher
                                </MenuItem>
                                <MenuItem value={"price_down"}>
                                    Le plus cher
                                </MenuItem>
                                <MenuItem value={"rent"}>
                                    Le plus grand loyer
                                </MenuItem>
                                <MenuItem value={"rdt"}>
                                    Le plus grand rendement
                                </MenuItem>
                            </Select>
                        </div>
                    </div>

                    <div
                        className={`filters ${
                            openCriteria ? "open" : "closed"
                        }`}
                    >
                        <SearchComponent
                            filters={{
                                zip_codes: filter_sort.zip_codes,
                                districts: filter_sort.districts,
                                provinces: filter_sort.provinces,
                            }}
                            search_zip={search_zip}
                        />
                        <MainFilters
                            advancedFilters
                            handleOpenAdvanced={() => setOpenAdvanced(true)}
                            filter_sort={filter_sort}
                        />
                    </div>
                    {/* <Button fullWidth variant='outlined' startIcon={<img src={search_icon} />}>Rechercher</Button> */}
                </div>
            )}
            {openAdvanced && (
                <div className="advanced-filters">
                    <AdvancedFilters
                        handleCloseAdvanced={() => setOpenAdvanced(false)}
                        filter_sort={filter_sort}
                    />
                </div>
            )}
            {mapOpen ? (
                <MapView
                    set_list={() => {
                        setMapOpen(false);
                        navigate({
                            pathname: "/search",
                            search: `?${window.location.href.split("?")[1]}`,
                        });
                    }}
                    investments={investments.all_places}
                />
            ) : (
                <div className="spotlight">
                    <ListView
                        currentPage={filter_sort.page}
                        set_map={handleMapOpen}
                        filteredInvestments={filteredInvestments}
                    />
                    {/* {
						!filteredInvestments || (filteredInvestments.length === 0 && investments.loading === true) || filteredInvestments.length === 0  ?
						<EmptyView
							currentPage={1}
							filteredInvestments={filteredInvestments}
							set_map={() => {
								setMapOpen(true);
								navigate({
									pathname: "/map", search: `?${window.location.href.split("?")[1]}`
								})
							}}
						/> :
						<ListView
							currentPage={filter_sort.page}
							set_map={() => {
								setMapOpen(true);
								navigate({
									pathname: "/map", search: `?${window.location.href.split("?")[1]}`
								})
							}}
							filteredInvestments={filteredInvestments}
						/>
					} */}
                </div>
            )}
        </section>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
