import React from "react";
import PropertyElem from "../home/property_elem/PropertyElem";
import "./PropertiesList.scss";

const PropertiesList = ({ properties, loading, type = "is_classic" }) => {
    return (
        <ul className="properties-list">
            {!properties
                ? null
                : properties.map((elem, index) => (
                      <PropertyElem
                          elem={elem}
                          key={index}
                          index={index}
                          loading={loading}
                          type={type}
                      />
                  ))}
        </ul>
    );
};

export default PropertiesList;
