import React, { useRef } from 'react'
import PropertyElem from '../property_elem/PropertyElem'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./style.scss"

const HomeCarousel = ({filteredInvestments, loading}) => {
    const translation = useRef(0);

    const move = (is_right) => {
        const slider = document.getElementById("selection-slider");
        const slider_content = document.getElementById("slider-transformer");
        const right_arrow = document.getElementById("right-arrow");
        const left_arrow = document.getElementById("left-arrow");

        if (is_right) {
            translation.current += (slider.offsetWidth - 200 < 328 ? 328 : slider.offsetWidth - 200);
            if (translation.current > slider_content.offsetWidth - slider.offsetWidth + 80) {
                translation.current = slider_content.offsetWidth - slider.offsetWidth  + 80;
                right_arrow.classList.add("hide");
                left_arrow.classList.remove("hide");
            } else {
                right_arrow.classList.remove("hide");
                left_arrow.classList.remove("hide");
            }
            slider_content.style.transform = `translateX(-${translation.current}px)`
        } else {
            translation.current -= (slider.offsetWidth - 200 < 328 ? 328 : slider.offsetWidth - 200);
            if (translation.current < 0) {
                translation.current = 0;
                left_arrow.classList.add("hide");
                right_arrow.classList.remove("hide");
            } else {
                left_arrow.classList.remove("hide");
                right_arrow.classList.remove("hide");
            }
            slider_content.style.transform = `translateX(-${translation.current}px)`
        }
    }

    return (
        <div id="home-slider">
            <div id="left-arrow" onClick={() => move(false)} className='arrow-container hide'>
                <ArrowBackIosNewIcon/>
            </div>
            <div id="selection-slider" className="selection-slider">
                <ul id="slider-transformer" className='selection-slider-content'>
                    {
                        !filteredInvestments ? null :
                        filteredInvestments.map((elem, index) => (
                            <PropertyElem elem={elem} key={index} index={index} loading={loading} />
                        ))
                    }
                </ul>
            </div>
            <div id="right-arrow" onClick={() => move(true)} className='arrow-container forward'>
                <ArrowForwardIosIcon/>
            </div>
        </div>
    )
}

export default HomeCarousel