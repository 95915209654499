import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import "./profile.scss";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";

var opt_weekday = { year: "numeric", month: "long", day: "numeric" };

const AccountType = ({ type }) => {
    let textTodisplay;

    switch (type) {
        case "admin":
            textTodisplay = "Administrateur";
            break;
        case "agent":
            textTodisplay = "Agent immobilier";
            break;
        case "investor":
            textTodisplay = "Investisseur";
            break;
        case "start":
            textTodisplay = "Starter";
            break;
        default:
            textTodisplay = "Starter";
    }

    return (
        <div className={`account-type ${type}`}>
            <span>{textTodisplay}</span>
        </div>
    );
};

const RenewText = ({ user }) => {
    const { getProfile } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const toggle_activation = async () => {
        setLoading(true);
        const ret = await axios
            .put("/subscription/toggle-activation")
            .then((e) => e.data)
            .catch((e) => null);
        await new Promise((r) => setTimeout(r, 1000));

        await getProfile();
        setLoading(false);
        console.log(ret);
    };

    if (!user.subscription) {
        return (
            <div>
                <p>
                    Visitez la <Link to="/pricing">liste de nos plans</Link>{" "}
                    pour profiter a 100% des fonctionnalites d'immovestor.
                </p>
            </div>
        );
    }
    const auto_renew = !user.subscription.cancel_at_period_end;
    if (user.subscription.status == "canceled") {
        return (
            <div>
                <p>
                    Votre abonnement est valable jusqu'au{" "}
                    {new Date(
                        user.subscription.current_period_end * 1000
                    ).toLocaleDateString(`fr-FR`, opt_weekday)}{" "}
                    non inclus et sera renouvelé automatiquement.
                </p>
                <Button></Button>
            </div>
        );
    }
    return (
        <div className="subscription">
            <p>
                Votre abonnement est valable jusqu'au{" "}
                {new Date(
                    user.subscription.current_period_end * 1000
                ).toLocaleDateString(`fr-FR`, opt_weekday)}{" "}
                non inclus et{" "}
                <strong>
                    {auto_renew
                        ? "sera renouvelé automatiquement"
                        : "ne sera pas renouvelé automatiquement"}
                </strong>
                .
            </p>
            <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={toggle_activation}
            >
                {auto_renew
                    ? "Ne pas renouvler l'abonnement a la fin de la periode"
                    : "Renouvler l'abonnement a la fin de la periode"}
            </LoadingButton>
        </div>
    );
};

const Profile = () => {
    const { user, logout, updateProfile } = useContext(AuthContext);
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
    });

    const reset = () => {
        setUserData({
            first_name: user.first_name,
            last_name: user.last_name,
        });
    };

    const handleUpdateUser = async () => {
        updateProfile(userData);
    };

    const validate_user_data = () => {
        return (
            (userData.first_name === user.first_name &&
                userData.last_name === user.last_name) ||
            userData.first_name === "" ||
            userData.last_name === ""
        );
    };

    useEffect(() => {
        if (!user.token || user.token === "") {
            navigate("/");
        }
    }, [user]);

    return (
        <div id="profile">
            <div className="main-view-center">
                <h1>Mon profil</h1>
                <p>
                    Vous pouvez trouver ici les informations liées à votre
                    compte, notamment les informations de paiement.
                </p>
                <div className="settings-block">
                    <h2>Type de compte</h2>
                    <AccountType type={user.role} />
                    <div className="subscription-status">
                        <h3>Abonnement</h3>
                        <RenewText user={user} />
                    </div>
                </div>
                <div className="settings-block">
                    <h2>Informations personnelles</h2>
                    <form>
                        <TextField
                            value={userData.first_name}
                            onChange={(e) =>
                                setUserData({
                                    ...userData,
                                    first_name: e.target.value,
                                })
                            }
                            label="Prénom"
                            size="small"
                            type="text"
                            variant="outlined"
                        />
                        <TextField
                            value={userData.last_name}
                            onChange={(e) =>
                                setUserData({
                                    ...userData,
                                    last_name: e.target.value,
                                })
                            }
                            label="Nom"
                            size="small"
                            type="text"
                            variant="outlined"
                        />
                    </form>
                    <div className="confirm-div">
                        <Button variant="text" onClick={reset}>
                            Réinitialiser
                        </Button>
                        <Button
                            variant="contained"
                            disabled={validate_user_data()}
                            onClick={handleUpdateUser}
                        >
                            Sauvegarder
                        </Button>
                    </div>
                </div>
                <div className="center-full-width">
                    <Button onClick={logout} variant="outlined" color="error">
                        déconnexion
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
