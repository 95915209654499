// import { async } from "@firebase/util";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TOAST_LVL } from "../../../constants/constant_lists";
import AuthContext from "../../../contexts/AuthContext";
import ToastContext from "../../../contexts/PopupContext";
import "./style.scss";

const Register = () => {
    const { register } = useContext(AuthContext);
    const { setToast } = useContext(ToastContext);
    const navigate = useNavigate();

    const [registerData, setRegisterData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
    });
    const [loading, setLoading] = useState(false);

    const register_edit = async () => {
        setLoading(true);
        const ret = await register(
            registerData.email,
            registerData.password,
            registerData.first_name,
            registerData.last_name
        );
        if (ret !== "ok") {
            setToast.current(
                TOAST_LVL.ERROR,
                "Erreur de connexion",
                "Cette adresse e-mail est déjà associée à un compte. Veuillez vous connecter ou utiliser une autre adresse e-mail pour vous inscrire."
            );
            setLoading(false);
        } else {
            setLoading(false);
            // navigate("/pricing");
            // navigate("/search");
            navigate("/alerts");
        }
    };

    const valid_input = () => {
        return (
            registerData.first_name.length > 0 &&
            registerData.last_name.length > 0 &&
            registerData.email.length > 0 &&
            registerData.password.length >= 8 &&
            registerData.password_confirmation === registerData.password &&
            registerData.email.includes("@") &&
            !registerData.email.startsWith("@") &&
            !registerData.email.endsWith("@")
        );
    };

    return (
        <div id="register">
            <h1>Créer un compte</h1>
            <form>
                <TextField
                    required
                    onChange={(e) =>
                        setRegisterData({
                            ...registerData,
                            last_name: e.target.value,
                        })
                    }
                    value={registerData.last_name}
                    autoComplete="family-name"
                    size="small"
                    fullWidth
                    label="Nom"
                />
                <TextField
                    required
                    onChange={(e) =>
                        setRegisterData({
                            ...registerData,
                            first_name: e.target.value,
                        })
                    }
                    value={registerData.first_name}
                    autoComplete="given-name"
                    size="small"
                    fullWidth
                    label="Prénom"
                />
                <TextField
                    required
                    type={"email"}
                    onChange={(e) =>
                        setRegisterData({
                            ...registerData,
                            email: e.target.value,
                        })
                    }
                    value={registerData.email}
                    autoComplete="email"
                    size="small"
                    fullWidth
                    label="Email"
                />
                <TextField
                    required
                    type={"password"}
                    onChange={(e) =>
                        setRegisterData({
                            ...registerData,
                            password: e.target.value,
                        })
                    }
                    value={registerData.password}
                    autoComplete="password"
                    size="small"
                    fullWidth
                    label="Mot de passe"
                />
                <TextField
                    required
                    type={"password"}
                    onChange={(e) =>
                        setRegisterData({
                            ...registerData,
                            password_confirmation: e.target.value,
                        })
                    }
                    value={registerData.password_confirmation}
                    autoComplete="password"
                    size="small"
                    fullWidth
                    label="Confirmation du mot de passe"
                />
                <LoadingButton
                    loading={loading}
                    disabled={!valid_input()}
                    variant="contained"
                    onClick={register_edit}
                >
                    Créer mon compte
                </LoadingButton>
            </form>
        </div>
    );
};

export default Register;
