import React from "react";
import IaIntegrations from "../../components/iaIntegrations/IaIntegrations";

import "./style.scss";

const IaIntegrationsScreen = () => {
    return (
        <div className="IaIntegrationsScreen__screen-container">
            <h1 className="IaIntegrationsScreen__main-title"> </h1>
            <IaIntegrations />
        </div>
    );
};

export default IaIntegrationsScreen;
