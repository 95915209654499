import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const AppYieldSettings = ({ yieldSettings, updateYieldCalculatorSettings }) => {
    const [inputsYieldSettings, setInputYieldSettings] =
        useState(yieldSettings);

    useEffect(() => {
        if (yieldSettings) setInputYieldSettings(yieldSettings);
    }, [yieldSettings]);

    const handleChange = (event, field) => {
        setInputYieldSettings({
            ...inputsYieldSettings,
            [field]: parseFloat(event.target.value),
        });
    };

    const handleSave = () => {
        updateYieldCalculatorSettings(inputsYieldSettings);
    };

    return (
        <>
            {inputsYieldSettings && (
                <>
                    <h2>Calculateur de rendement</h2>
                    <h3>Investissement</h3>
                    <TextField
                        className="app-settings__input"
                        label="Frais d'enregistrement"
                        id="app-settings_registartion-fee"
                        type="number"
                        value={inputsYieldSettings.registrationFee}
                        onChange={(event) =>
                            handleChange(event, "registrationFee")
                        }
                    />
                    <h3>Prime d'assurance</h3>
                    <TextField
                        className="app-settings__input"
                        label="De 0 à 250.000 €"
                        id="app-settings_insurance_first-step"
                        type="number"
                        value={inputsYieldSettings.insuranceFirstStep}
                        onChange={(event) =>
                            handleChange(event, "insuranceFirstStep")
                        }
                    />
                    <TextField
                        className="app-settings__input"
                        label="De 250.001 à 750.000€"
                        id="app-settings_insurance_second-step"
                        type="number"
                        value={inputsYieldSettings.insuranceSecondStep}
                        onChange={(event) =>
                            handleChange(event, "insuranceSecondStep")
                        }
                    />
                    <TextField
                        className="app-settings__input"
                        label="De 750.001 à 1.500.000€"
                        id="app-settings_insurance_third-step"
                        type="number"
                        value={inputsYieldSettings.insuranceThirdStep}
                        onChange={(event) =>
                            handleChange(event, "insuranceThirdStep")
                        }
                    />
                    <TextField
                        className="app-settings__input"
                        label="Plus de 1.500.000€"
                        id="app-settings_insurance-fourth-step"
                        type="number"
                        value={inputsYieldSettings.insuranceFourthStep}
                        onChange={(event) =>
                            handleChange(event, "insuranceFourthStep")
                        }
                    />
                    <h3>Crédit classique</h3>
                    <TextField
                        className="app-settings__input"
                        label="Taux à 15 ans"
                        id="app-settings_classic-loan_15-rate"
                        type="number"
                        value={inputsYieldSettings.classicLoan15YearsRate}
                        onChange={(event) =>
                            handleChange(event, "classicLoan15YearsRate")
                        }
                    />
                    <TextField
                        className="app-settings__input"
                        label="Taux à 20 ans"
                        id="app-settings_classic-loan_20-rate"
                        type="number"
                        value={inputsYieldSettings.classicLoan20YearsRate}
                        onChange={(event) =>
                            handleChange(event, "classicLoan20YearsRate")
                        }
                    />
                    <TextField
                        className="app-settings__input"
                        label="Taux à 25 ans"
                        id="app-settings_classic-loan_25-rate"
                        type="number"
                        value={inputsYieldSettings.classicLoan25YearsRate}
                        onChange={(event) =>
                            handleChange(event, "classicLoan25YearsRate")
                        }
                    />
                    <h3>Terme fixe (bullet)</h3>
                    <TextField
                        className="app-settings__input"
                        label="Taux crédit bullet"
                        id="app-settings_bullet-loan_rate"
                        type="number"
                        value={inputsYieldSettings.bulletLoanRate}
                        onChange={(event) =>
                            handleChange(event, "bulletLoanRate")
                        }
                    />
                    <Button variant="outlined" onClick={() => handleSave()}>
                        Sauvegarder
                    </Button>
                </>
            )}
        </>
    );
};

export default AppYieldSettings;
