import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react'
import "./style.scss"
import { Button, TextField } from '@mui/material';
import axios from "../../../services/axios"
import { LoadingButton } from '@mui/lab';
import AuthContext from '../../../contexts/AuthContext';
import ToastContext from '../../../contexts/PopupContext';
import { TOAST_LVL } from '../../../constants/constant_lists';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const ReportModal = ({visible, close, url, elem_id}) => {

	const {user} = useContext(AuthContext);
	const {setToast} = useContext(ToastContext);
	const [contactValue, setContactValue] = useState({
		email: user.token !== "" ? user.email : "",
		message: "",
		type: "",
	});
	const [loading, setLoading] = useState(false);

	const validate = async () => {
		setLoading(false);
		await axios.post("/propreties/report", {
			email: contactValue.email,
			description: contactValue.message,
			type: contactValue.type,
			property_id: elem_id,
			url: url
		})
		setLoading(true);
		setContactValue({
			email: "",
			message: ""
		});
		setToast.current(TOAST_LVL.SUCCESS, "Anomalie reportée", "Merci de nous avoir informé de cette anomalie.")
		setLoading(false);
		close();
	}

	useEffect(() => {
	  if (user.token && user.token !== "" && user.email !== contactValue.email) {
		setContactValue({...contactValue, email: user.email})
	  }
	}, [user])
	

	return (
		<Modal
			open={visible}
			onClose={close}
			sx={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", minHeight: "80vh"}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className='center-report-form'>
				<h2>Signaler une anomalie</h2>
				<p>Souhaitez-vous signaler une erreur dans cette annonce ou informer que cette propriété est déjà vendue ou louée ? Si tel est le cas, votre remarque sera transmise à notre service clientèle. Nous vous remercions pour votre retour.</p>
				<FormControl className='radio-input'>
					<FormLabel id="demo-radio-buttons-group-label">Type d'anomalie</FormLabel>
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						name="radio-buttons-group"
						value={contactValue.type}
						onChange={(e) => setContactValue({...contactValue, type: e.target.value})}
					>
						<FormControlLabel value="price" control={<Radio />} label="Le prix n'est pas correct" />
						<FormControlLabel value="yet-sold" control={<Radio />} label="Ce bien est déjà vendu ou loué" />
						<FormControlLabel value="pictures" control={<Radio />} label="Problème sur une ou plusieurs photos" />
						<FormControlLabel value="other" control={<Radio />} label="Autre anomalie" />
					</RadioGroup>
				</FormControl>
				
				<form>
					<TextField onChange={(e) => setContactValue({...contactValue, email: e.target.value})} value={contactValue.email} size='small' variant='outlined' label="Email" fullWidth />
					<TextField onChange={(e) => setContactValue({...contactValue, message: e.target.value})} value={contactValue.message} minRows={3} multiline={true} size='small' variant='outlined' label="Description de l'anomalie" fullWidth />
				</form>
				<LoadingButton loading={loading} disabled={contactValue.email === "" || contactValue.message === ""} onClick={validate} variant='contained'>Reporter</LoadingButton>
			</div>
		</Modal>
	)
}

export default ReportModal