import settings from "../settings";

/**
 * Checks if the user has the given permission or array of permissions.
 * @param {Object} user - The user object.
 * @param {string|string[]} permission - The permission or an array of permissions to check for.
 * @returns {boolean} - Returns true if the user has the permission(s) and it is set to true, false otherwise.
 */
const hasPermission = (user, permission) => {
    if (!Array.isArray(permission) && typeof permission !== "string") {
        return false;
    }

    const permissions = Array.isArray(permission) ? permission : [permission];

    return permissions.every(
        (perm) =>
            user.permissions.hasOwnProperty(perm) &&
            user.permissions[perm] === true
    );
};

export default hasPermission;

/**
 * tests
 */
// const user = {
//     permissions: {
//         read_demographicData: true,
//         edit_demographicData: false,
//         test_permission: true
//     }
// };

// console.log(hasPermission(user, "read_demographicData")); // Output: true
// console.log(hasPermission(user, "edit_demographicData")); // Output: false
// console.log(hasPermission(user, ["read_demographicData", "edit_demographicData"])); // Output: false
// console.log(hasPermission(user, ["read_demographicData", "non_existent_permission"])); // Output: false
// console.log(hasPermission(user, ["read_demographicData"])); // Output: true
// console.log(hasPermission(user, ["read_demographicData", "test_permission"])); // Output: true
