import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
    palette: {
        primary: {
            light: '#FFC802',
            main: '#FFC802',
            dark: '#FFC802',
            contrastText: '#fff',
        },
        // secondary: {
        // 	light: '#ff7961',
        // 	main: '#f44336',
        // 	dark: '#ba000d',
        // 	contrastText: '#000',
        // },
    },
});

export default muiTheme;
