import { OverlayView } from '@react-google-maps/api'
import React, { memo, useEffect, useRef } from 'react'
import "./marker.scss"

function closestPowerOf10(num) {
    const exponent = Math.floor(Math.log10(num));
    const lowerPower = Math.pow(10, exponent);
    const upperPower = Math.pow(10, exponent + 1);
    if (Math.abs(num - lowerPower) < Math.abs(num - upperPower)) {
      return exponent;
    } else {
      return exponent + 1;
    }
}

const show_price = (value1) => {
	let val1 = `${value1}`;
    if (value1 >= 1000000) {
        let exp = 2 - closestPowerOf10(value1 / 1000000)
        if (exp < 0)
            exp = 0
		val1 = `${(value1 / 1000000).toFixed(exp)}M`;
	} else if (value1 >= 1000) {
        let exp = 2 - closestPowerOf10(value1 / 1000)
        if (exp < 0)
            exp = 0
		val1 = `${(value1 / 1000).toFixed(exp)}K`;
	}
	
	
	return `${val1}`
}

const CustomMarker = memo(({property, short, select}) => {
    const real_center = {
        lat: property.lat,
        lng: property.lng
    }
    return (
        <OverlayView
            position={real_center}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div onClick={() => select(property.id)} className={`custom-marker-view ${short ? "short" : ""}`}>
                <span>{show_price(property.price)} | {property.rdt?.toFixed(1)}%</span>
            </div>
        </OverlayView>
    )
})

export default CustomMarker