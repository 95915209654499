import React from "react";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import no_pics from "../../../assets/no_pics.svg";
import { home_type } from "../../../constants/constant_lists";
import formatNumber from "../../../helpers/formatNumber";
import PropertyElemHeader from "./PropertyElemHeader";
import PropertyElemStateIcons from "./PropertyElemStateIcons";

const PropertyContent = ({
    views,
    visible,
    rent,
    rdm,
    certificats,
    pebLetter,
    location,
    netHabitableSurface,
    price,
    subtype,
    pictures,
    creationDate,
    spotlight,
    flagPriceDecrease,
    flagFastSale,
    flagAlreadySold,
    isViewsIsVisible,
    opt_weekday,
    freezeByme,
    favoriteOfMe,
}) => {
    return (
        <article
            className={`property-element__content ${
                visible === false && "admin-only"
            }`}
        >
            <PropertyElemHeader
                rent={rent}
                views={views}
                certificats={certificats}
                pebLetter={pebLetter}
                pictures={pictures}
                no_pics={no_pics}
                isViewsIsVisible={isViewsIsVisible}
            />
            <main className="property-element__infos">
                <div className="property-element__infos__header">
                    <div className="property-element__housing-type">
                        {home_type[subtype] ? home_type[subtype] : "Autre"}
                    </div>

                    <div className="property-element__location">
                        {location.locality}
                        {netHabitableSurface && ` - ${netHabitableSurface}m²`}
                    </div>

                    <div className="property-element__price">
                        {formatNumber(price)}€
                    </div>
                </div>

                <div className="property-element__infos__main">
                    <div className="property-element__gross-yield">
                        <div className="property-element__gross-yield__percent">
                            {rent
                                ? `${formatNumber(rdm ? rdm : 0, 1)}%`
                                : "inconnu"}
                        </div>

                        <div className="property-element__gross-yield__text">
                            Rendement brut
                            <Tooltip title="Le rendement brut en pourcent est calculé de la manière suivante: loyer mensuel * 12 / (prix * 1.125) * 100">
                                <InfoIcon
                                    sx={{
                                        fontSize: "1rem",
                                        fill: "var(--color-secondary)",
                                        marginLeft: ".2em",
                                        marginBottom: "-.1em",
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div className="property-element__square-meter-price">
                        {netHabitableSurface && (
                            <>
                                <span>Prix au m²</span>
                                <span>{`${formatNumber(
                                    price / netHabitableSurface
                                )}€`}</span>
                            </>
                        )}
                    </div>
                </div>

                <div className="property-element__infos__footer">
                    <div className="property-element__publication-date">
                        Date de publication :{" "}
                        {new Date(creationDate).toLocaleDateString(
                            `fr-FR`,
                            opt_weekday
                        )}
                    </div>
                </div>

                {/* {
						elem.property_data.building?.condition ?
						<p>Etat du bien: {good_state[elem.property_data.building?.condition]}</p> : null
					} */}
                {/* {
						new Date(elem.publication.lastModificationDate).toDateString() !== new Date(elem.publication.creationDate).toDateString() ?
						<p>Dernière modification: {new Date(elem.publication.lastModificationDate).toLocaleDateString(`fr-FR`, opt_weekday)}</p> : null
					} */}
            </main>

            <PropertyElemStateIcons
                spotlight={spotlight}
                flagPriceDecrease={flagPriceDecrease}
                flagFastSale={flagFastSale}
                flagAlreadySold={flagAlreadySold}
                freezeByMe={freezeByme}
                favoriteOfMe={favoriteOfMe}
            />
        </article>
    );
};

export default PropertyContent;
