import React from "react";
import { NumericFormat } from "react-number-format";
import { InputAdornment, TextField } from "@mui/material";

const NumericInputOnChange = ({
    value,
    onChange,
    className,
    label,
    id,
    prefix,
    disabled,
    sx = { width: "10.5rem" },
}) => {
    const inputProps = prefix
        ? {
              startAdornment: (
                  <InputAdornment position="start">{prefix}</InputAdornment>
              ),
          }
        : {};

    return (
        <NumericFormat
            className={className}
            label={label}
            id={id}
            value={value} // controlled component
            onValueChange={(values) => {
                const { value } = values;
                onChange({
                    target: {
                        name: id,
                        value: parseFloat(value ? value : "0"),
                    },
                });
            }}
            customInput={TextField}
            allowNegative={false}
            allowedDecimalSeparators={[",", "."]}
            decimalSeparator={","}
            thousandSeparator={"."}
            decimalScale={2}
            fixedDecimalScale={false}
            sx={sx}
            InputProps={inputProps}
            disabled={disabled}
        />
    );
};

export default NumericInputOnChange;
