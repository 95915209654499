import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import "./style.scss";
import Loading from "../../../components/main/loading/Loading";

const AdminHome = () => {

    const [alertSended, setAlertSended] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSendAlerts = async () => {
        setLoading(true);
        const res = await axios
        .post("/daily-alert-manual",{})
        .then((e) => e.data)
        .catch((e) => null);
        if(res){
            setAlertSended(true);
            setLoading(false);
        }
    }

    const navigate = useNavigate();
    return (
        <div id="admin-home">
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/new-by-url")}
            >
                Ajouter avec une url
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/new-by-cron")}
            >
                Ajouté avec le cron
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/reports")}
            >
                Anomalies
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/market-data")}
            >
                Donnees de marche
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/app-settings")}
            >
                Paramètres de l'application
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate("/admin/rent-prediction-vs-rent")}
            >
                Annonces à corriger
            </Button>
            {loading && <Loading/>}
            {!loading && <Button
                variant="outlined"
                onClick={() => handleSendAlerts()}
                disabled={alertSended}
            >
                {alertSended ? "Alertes envoyées" : "Envoyer les alertes"}
            </Button>}
        </div>
    );
};

export default AdminHome;
