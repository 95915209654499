import { MenuItem, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClearButton from "../../components/filters/ClearButton";
import SaveButton from "../../components/filters/SaveButton";
import SearchComponent from "../../components/home_invest/SearchComponent";
import { good_state, home_type, pebs } from "../../constants/constant_lists";
import { mapDispatchToProps } from "../../store/dispatcher";
import "./style.scss";
import SearchContext, { filter_sort } from "../../contexts/SearchContext";
import AuthContext from "../../contexts/AuthContext";
import hasPermission from "../../utils/hasPermission";
import axios from "../../services/axios";

let minPrice = 0;
let maxPrice = 0;
let minYeld = 0;
let maxYeld = 0;
let minRent = 0;
let maxRent = 0;
let minBedroom = 0;
let maxBedroom = 0;
let maxCadastralRevenue = 0;

const Filters = ({ fetchInvestments }) => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const [resetSearch, setResetSearch] = useState(0);

    const [agencies, setAgencies] = useState(null);

    const [filtersState, setFiltersState] = useState({
        convertible_attic: null,
        has_lift: null,
        peb: "Tous",
        goodType: "all",
        goodState: "all",
        agencyId: "all",
    });

    const { actualCategory } = useContext(SearchContext);

    useEffect(() => {
        const fetchAgencies = async () => {
            const response = await axios.get("/agencies/get-agencies");
            return response.data;
        };

        minPrice = filter_sort.filter.price_min;
        maxPrice = filter_sort.filter.price_max;
        minYeld = filter_sort.filter.rdt_min;
        maxYeld = filter_sort.filter.rdt_max;
        minRent = filter_sort.filter.rent_min;
        maxRent = filter_sort.filter.rent_max;
        maxCadastralRevenue = filter_sort.filter.cadastral_income;
        minBedroom = filter_sort.filter.bedroom_min;
        minBedroom = filter_sort.filter.bedroom_max;

        setFiltersState({
            convertible_attic: filter_sort.filter.convertible_attic,
            has_lift: filter_sort.filter.has_lift,
            peb:
                filter_sort.filter.epc_scores === ""
                    ? "Tous"
                    : filter_sort.filter.epc_scores,
            goodType:
                filter_sort.filter.place_type === ""
                    ? "all"
                    : filter_sort.filter.place_type,
            goodState:
                filter_sort.filter.conditions === ""
                    ? "all"
                    : filter_sort.filter.conditions,
            agencyId:
                filter_sort.filter.agencyId === "" ||
                filter_sort.filter.agencyId === null
                    ? "all"
                    : filter_sort.filter.agencyId,
        });

        if (hasPermission(user, "read_agencies")) {
            fetchAgencies().then((agenciesData) => {
                let newAgenciesData = [
                    { id: "all", name: "Toutes" },
                    ...agenciesData,
                ];
                setAgencies(newAgenciesData);
            });
        }
    }, [user]);

    const FilterBlock = ({ title, children }) => {
        return (
            <div className="filter-block">
                <div className="filter-block-header">
                    <h2>{title}</h2>
                </div>
                {children}
            </div>
        );
    };

    const handleSave = () => {
        filter_sort.filter = {
            price_min: minPrice,
            price_max: maxPrice,
            rdt_min: minYeld,
            rdt_max: maxYeld,
            rent_min: minRent,
            rent_max: maxRent,
            count_per_pages: 12,
            place_type:
                filtersState.goodType === "all" ? "" : filtersState.goodType,
            conditions:
                filtersState.goodState === "all" ? "" : filtersState.goodState,
            epc_scores: filtersState.peb === "Tous" ? "" : filtersState.peb,
            zip_codes: filter_sort.filter.zip_codes,
            category: actualCategory,
            convertible_attic: filtersState.convertible_attic,
            has_lift: filtersState.has_lift,
            cadastral_income: maxCadastralRevenue,
            bedroom_min: minBedroom,
            bedroom_max: maxBedroom,
            agencyId:
                filtersState.agencyId === "all" ? null : filtersState.agencyId,
            // agencyId: "k5hfJ4kizvtPz7dfnjMy",
        };
        filter_sort.page = 0;
        navigate("/search");
        fetchInvestments(filter_sort);
    };

    const reset = () => {
        minPrice = 0;
        maxPrice = 0;
        minYeld = 0;
        maxYeld = 0;
        minRent = 0;
        maxRent = 0;
        minBedroom = 0;
        maxBedroom = 0;
        maxCadastralRevenue = 0;
        filter_sort.zip_codes = [];
        filter_sort.provinces = [];
        filter_sort.districts = [];
        filter_sort.filter.zip_codes = [];
        setResetSearch(resetSearch + 1);
        setFiltersState({
            goodState: "all",
            goodType: "all",
            peb: "Tous",
            convertible_attic: null,
            has_lift: null,
            agencyId: "all",
        });
    };

    const search_zip = (zips, others) => {
        filter_sort.zip_codes = others.zip_codes;
        filter_sort.provinces = others.provinces;
        filter_sort.districts = others.districts;
        filter_sort.filter.zip_codes = zips;
    };

    return (
        <div className="filters-view">
            <div className="filters-center">
                <div className="filters-header">
                    <h1>Recherche avancée</h1>
                </div>
                <SearchComponent
                    reset={resetSearch}
                    filters={{
                        zip_codes: filter_sort.zip_codes,
                        districts: filter_sort.districts,
                        provinces: filter_sort.provinces,
                    }}
                    search_zip={search_zip}
                />
                {agencies && agencies.length > 0 && (
                    <FilterBlock title={"Agence"}>
                        <Select
                            size="small"
                            className="select-filter"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filtersState.agencyId}
                            onChange={(e) =>
                                setFiltersState({
                                    ...filtersState,
                                    agencyId: e.target.value,
                                })
                            }
                        >
                            {agencies &&
                                agencies.map((agency, index) => {
                                    return (
                                        <MenuItem key={index} value={agency.id}>
                                            {agency.name}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FilterBlock>
                )}
                <FilterBlock title={"Type de bien"}>
                    <Select
                        size="small"
                        className="select-filter"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filtersState.goodType}
                        onChange={(e) =>
                            setFiltersState({
                                ...filtersState,
                                goodType: e.target.value,
                            })
                        }
                    >
                        {Object.keys(home_type).map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {home_type[elem]}
                            </MenuItem>
                        ))}
                    </Select>
                </FilterBlock>
                <FilterBlock title={"Budget"}>
                    <h3>Prix</h3>
                    <div className="price-inputs">
                        <TextField
                            size="small"
                            defaultValue={minPrice !== 0 ? minPrice : ""}
                            onChange={(e) => (minPrice = e.target.value)}
                            id="outlined-basic"
                            label="Minimum"
                            variant="outlined"
                        />
                        <TextField
                            size="small"
                            defaultValue={maxPrice !== 0 ? maxPrice : ""}
                            onChange={(e) => (maxPrice = e.target.value)}
                            id="outlined-basic"
                            label="Maximum"
                            variant="outlined"
                        />
                    </div>
                </FilterBlock>
                <FilterBlock title={"Loyer"}>
                    <h3>Loyer mensuel</h3>
                    <div className="price-inputs">
                        <TextField
                            size="small"
                            defaultValue={minRent !== 0 ? minRent : ""}
                            onChange={(e) => (minRent = e.target.value)}
                            id="outlined-basic"
                            label="Minimum"
                            variant="outlined"
                        />
                        <TextField
                            size="small"
                            defaultValue={maxRent !== 0 ? maxRent : ""}
                            onChange={(e) => (maxRent = e.target.value)}
                            id="outlined-basic"
                            label="Maximum"
                            variant="outlined"
                        />
                    </div>
                </FilterBlock>
                <FilterBlock title={"Rendement brut"}>
                    <h3>Rendement</h3>
                    <div className="price-inputs">
                        <TextField
                            size="small"
                            defaultValue={minYeld !== 0 ? minYeld : ""}
                            onChange={(e) => (minYeld = e.target.value)}
                            id="outlined-basic"
                            label="Minimum (%)"
                            variant="outlined"
                        />
                        <TextField
                            size="small"
                            defaultValue={maxYeld !== 0 ? maxYeld : ""}
                            onChange={(e) => (maxYeld = e.target.value)}
                            id="outlined-basic"
                            label="Maximum (%)"
                            variant="outlined"
                        />
                    </div>
                </FilterBlock>
                <FilterBlock title={"Revenu cadastral"}>
                    <h3>Revenu cadastral</h3>
                    <div className="price-inputs">
                        <TextField
                            size="small"
                            defaultValue={
                                maxCadastralRevenue !== 0
                                    ? maxCadastralRevenue
                                    : ""
                            }
                            onChange={(e) =>
                                (maxCadastralRevenue = e.target.value)
                            }
                            id="outlined-basic"
                            label="Maximum"
                            variant="outlined"
                        />
                    </div>
                </FilterBlock>
                <FilterBlock title={"PEB"}>
                    <Select
                        size="small"
                        className="select-filter"
                        labelId="demo-simple-select-label"
                        value={filtersState.peb}
                        onChange={(e) =>
                            setFiltersState({
                                ...filtersState,
                                peb: e.target.value,
                            })
                        }
                    >
                        {pebs.map((elem, index) => (
                            <MenuItem key={index} value={elem}>
                                {/*{elem}*/}
                                {elem === "Tous" ? "PEB minimum" : elem}
                            </MenuItem>
                        ))}
                    </Select>
                </FilterBlock>
                <FilterBlock title={"Infrastructure"}>
                    <h3>Nombre de chambre</h3>
                    <div className="price-inputs">
                        <TextField
                            size="small"
                            defaultValue={minBedroom !== 0 ? minBedroom : ""}
                            onChange={(e) => (minBedroom = e.target.value)}
                            id="outlined-basic"
                            label="Minimum"
                            variant="outlined"
                        />
                        <TextField
                            size="small"
                            defaultValue={maxBedroom !== 0 ? maxBedroom : ""}
                            onChange={(e) => (maxBedroom = e.target.value)}
                            id="outlined-basic"
                            label="Maximum"
                            variant="outlined"
                        />
                    </div>
                    <h3 className="margin-top">Grenier aménageable</h3>
                    <Select
                        size="small"
                        className="select-filter"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                            filtersState.convertible_attic == null
                                ? "null"
                                : filtersState.convertible_attic
                        }
                        onChange={(e) =>
                            setFiltersState({
                                ...filtersState,
                                convertible_attic:
                                    e.target.value == "null"
                                        ? null
                                        : e.target.value,
                            })
                        }
                    >
                        <MenuItem value={"null"}>Peu importe</MenuItem>
                        <MenuItem value={true}>Oui</MenuItem>
                    </Select>
                    <h3 className="margin-top">Ascenceur</h3>
                    <Select
                        size="small"
                        className="select-filter"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                            filtersState.has_lift == null
                                ? "null"
                                : filtersState.has_lift
                        }
                        onChange={(e) =>
                            setFiltersState({
                                ...filtersState,
                                has_lift:
                                    e.target.value == "null"
                                        ? null
                                        : e.target.value,
                            })
                        }
                    >
                        <MenuItem value={"null"}>Peu importe</MenuItem>
                        <MenuItem value={true}>Oui</MenuItem>
                        <MenuItem value={false}>Non</MenuItem>
                    </Select>
                </FilterBlock>
                <FilterBlock title={"Etat du bien"}>
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        className="select-filter"
                        id="demo-simple-select"
                        value={filtersState.goodState}
                        onChange={(e) =>
                            setFiltersState({
                                ...filtersState,
                                goodState: e.target.value,
                            })
                        }
                    >
                        {Object.keys(good_state).map((elem, id) => (
                            <MenuItem key={id} value={elem}>
                                {good_state[elem]}
                            </MenuItem>
                        ))}
                    </Select>
                </FilterBlock>
            </div>
            <ClearButton save={reset} />
            <SaveButton save={handleSave} />
        </div>
    );
};

export default connect(null, mapDispatchToProps)(Filters);
