import { GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";
import MarketDataMarker from "./markeDataMarker/MarketDataMarker";

import "./marketDataMap.scss";
import settings from "../../settings";

/**
 * !!!!!!!!!!
 * docs for @react-google-maps/api is down, so it's accessible here:
 * https://web.archive.org/web/20230701010714mp_/https://react-google-maps-api-docs.netlify.app/
 */

const mapStyle = {
    width: "100%",
    height: "68vh",
    position: "relative",
};

const MarketDataMap = ({
    setIsPropertyDetailVisible,
    setDetailCardData,
    setCurrentCard,
    properties,
    isGoogleLoaded,
    coordinates,
}) => {
    return (
        <div className={"market-data-map"}>
            {isGoogleLoaded ? (
                <GoogleMap
                    mapContainerStyle={mapStyle}
                    center={
                        coordinates ? coordinates : settings.startCoordinates
                    }
                    zoom={coordinates ? 14 : 8}
                    id="map-container"
                >
                    {coordinates && (
                        <Marker
                            position={coordinates}
                            title="L'adresse séléctionnées"
                        />
                    )}
                    {properties.map((item, index) => {
                        if (item.latitude) {
                            return (
                                <MarketDataMarker
                                    key={index}
                                    property={item}
                                    onHover={
                                        setDetailCardData &&
                                        setIsPropertyDetailVisible
                                            ? () =>
                                                  setCurrentCard.current(
                                                      item.id
                                                  )
                                            : null
                                    }
                                />
                            );
                        }
                        return null;
                    })}
                </GoogleMap>
            ) : null}
        </div>
    );
};

export default MarketDataMap;
