import { GoogleMap } from "@react-google-maps/api";
import React, { useState } from "react";
import CustomMarker from "./CustomMarker";
import "./style.scss";
import axios from "../../../services/axios";
import PropertyElem from "../../home/property_elem/PropertyElem";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import useGoogleMaps from "../../../hooks/useGooglleMaps/useGoogleMaps";

const containerStyle = {
    width: "100%",
    height: "calc(100vh - 69px)",
    position: "relative",
};

const MapView = ({ investments, set_list }) => {
    const { isGoogleLoaded } = useGoogleMaps();
    const [realCenter, setRealCenter] = useState({ lat: 50.7, lng: 4.6 });
    const [map, setMap] = React.useState(null);
    const [shortMark, setShortMark] = useState(true);
    const [selected, setSelected] = useState(null);

    const select_place = async (id) => {
        if (id < 0) {
            setSelected(null);
        } else if (!selected || selected.id != id) {
            const ret = await axios
                .get("/propreties/" + id)
                .then((e) => e.data)
                .catch((e) => null);
            if (ret) {
                setSelected(ret);
            }
        }
    };

    const onLoad = React.useCallback(function callback(map) {
        map.setZoom(8);

        setMap(map);
    }, []);

    const zoom_change = () => {
        if (map) {
            if (map.getZoom() < 11 && !shortMark) {
                setShortMark(true);
            } else if (map.getZoom() >= 11 && shortMark) {
                setShortMark(false);
            }
        }
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <div className="main-map-view" id="main-map">
            <div onClick={set_list} className="list-button">
                <FormatListBulletedIcon />
                <span>List</span>
            </div>
            {isGoogleLoaded &&
            (realCenter.lat !== 0 || realCenter.lng !== 0) ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={realCenter}
                    onZoomChanged={zoom_change}
                    zoom={14}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    id="map-container"
                >
                    {investments.map((e, id) => {
                        if (e.lat) {
                            return (
                                <CustomMarker
                                    key={id}
                                    select={select_place}
                                    short={shortMark}
                                    property={e}
                                />
                            );
                        }
                        return null;
                    })}
                </GoogleMap>
            ) : null}
            {selected === null ? null : (
                <div className="absolute-property">
                    <div className="relative-one">
                        <div
                            onClick={() => setSelected(null)}
                            className="close-btn"
                        >
                            <CloseIcon />
                        </div>
                        <PropertyElem index={0} elem={selected} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MapView;
