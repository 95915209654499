import React, { useEffect, useState } from "react";

import { IoMdCloseCircle } from "react-icons/io";

import "./marketDataCard.scss";

const MarketDataCard = ({
    headerImageUrl,
    title,
    description,
    isVisible,
    setIsVisible,
    rent,
    setCurrentCard,
    properties,
    fullScreenMarketData,
}) => {
    const [activeCard, setActiveCard] = useState(null);
    useEffect(() => {
        setCurrentCard.current = setActiveCard;
    }, []);

    if (!activeCard) return null;

    const active_card = properties.find(
        (property) => property.id === activeCard
    );

    return (
        <section
            className={
                fullScreenMarketData
                    ? "market-data-card__container--full-screen"
                    : "market-data-card__container"
            }
        >
            <header className={"market-data-card__header"}>
                <button
                    className={"market-data-card__header__close-button"}
                    onClick={() => setActiveCard(null)}
                >
                    <IoMdCloseCircle
                        className={
                            "market-data-card__header__close-button__icon"
                        }
                    />
                </button>
                <div className={"market-data-card__image__container"}>
                    <img
                        src={active_card.picture_url}
                        className={"market-data-card__image"}
                        alt={active_card.title}
                    />
                </div>
            </header>
            <main className={"market-data-card__main"}>
                <h3 className={"market-data-card__title"}>
                    {active_card.title}
                </h3>
                <div className={"market-data-card__rent"}>
                    Loyer: {active_card.rent} €
                </div>
                <div className={"market-data-card__description"}>
                    {active_card.description}
                </div>
            </main>
        </section>
    );
};

export default MarketDataCard;
