/**
 * Generates a URL string from filters object and suffix string.
 *
 * @param {object} filters - An object containing filters to be applied to the URL.
 * @param {string} suffix - A string representing a URL suffix, defaults to "/?".
 * @returns {string} - A string representing the URL with filters applied.
 */
const generateUrlFromFilters = (filters, suffix = "/?") => {
    let url = `${suffix}page=${filters.page + 1}&`

    if (filters.sort !== "") {
        url += `sort=${filters.sort}&`
    }

    for(const [key, val] of Object.entries(filters.filter)) {
        if (key === "zip_codes")
            continue
        if (val !== 0 && val !== "" && val !== null && !(key === "count_per_pages" && val === 10) && !key.includes("http"))
            url += `${key}=${encodeURIComponent(val)}&`
    }

    if (filters.zip_codes.length)
        url += `post=${encodeURIComponent(filters.zip_codes.join(","))}&`
    if (filters.districts.length)
        url += `districts=${encodeURIComponent(filters.districts.join(","))}&`
    if (filters.provinces.length)
        url += `provinces=${encodeURIComponent(filters.provinces.join(","))}&`
    if (url.endsWith("&"))
        url = url.substring(0, url.length - 1)

    return url;
}

export default generateUrlFromFilters;
