import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import AuthContext from "../../../contexts/AuthContext";
import { show_location } from "../../../functions/location";
import no_pics from "../../../assets/no_pics.svg";
import { good_state, home_type } from "../../../constants/constant_lists";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../../store/dispatcher";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import {
    BsHouse as ClassicCategory,
    BsHouseFill as ClassicCategoryChecked,
} from "react-icons/bs";
import {
    BsHouses as Flatsharing,
    BsHousesFill as FlatsharingChecked,
} from "react-icons/bs";
// import { BiLogoAirbnb as AirBnb } from "react-icons/bi";
import { FaAirbnb as AirBnb } from "react-icons/fa";
import { TbBrandAirbnb as AirBnbChecked } from "react-icons/tb";

import { Tooltip } from "@mui/material";
import hasPermission from "../../../utils/hasPermission";

var opt_weekday = { year: "numeric", month: "numeric", day: "numeric" };

const DetailsTop = ({
    current_investment,
    open_modal,
    updateInvestment,
    isAPropertyFromUserAgency,
    setActualProperty,
}) => {
    const { user } = useContext(AuthContext);

    const update_item = () => {
        if (!user.isAdmin) return;
        updateInvestment({
            id: current_investment.id,
            updated: {
                ...current_investment,
                spotlight: current_investment.spotlight === true ? false : true,
            },
        });

        setActualProperty({
            ...current_investment,
            spotlight: current_investment.spotlight !== true,
        });
    };

    const update_flagPriceDecrease = () => {
        // if (!user.isAdmin) return;
        updateInvestment({
            id: current_investment.id,
            updated: {
                ...current_investment,
                flagPriceDecrease: !current_investment.flagPriceDecrease,
            },
        });

        setActualProperty({
            ...current_investment,
            flagPriceDecrease: !current_investment.flagPriceDecrease,
        });
    };
    const update_flagFastSale = () => {
        // if (!user.isAdmin) return;
        updateInvestment({
            id: current_investment.id,
            updated: {
                ...current_investment,
                flagFastSale: !current_investment.flagFastSale,
            },
        });

        setActualProperty({
            ...current_investment,
            flagFastSale: !current_investment.flagFastSale,
        });
    };

    const update_flags = () => {
        // if (!user.isAdmin) return;

        const { flagPriceDecrease, flagFastSale, flagAlreadySold } =
            current_investment;

        let updatedFlagPriceDecrease = flagPriceDecrease;
        let updatedFlagFastSale = flagFastSale;
        let updatedFlagAlreadySold = !flagAlreadySold;

        if (flagPriceDecrease || flagFastSale) {
            updatedFlagPriceDecrease = false;
            updatedFlagFastSale = false;
        }

        const updatedFlags = {
            flagPriceDecrease: updatedFlagPriceDecrease,
            flagFastSale: updatedFlagFastSale,
            flagAlreadySold: updatedFlagAlreadySold,
        };

        updateInvestment({
            id: current_investment.id,
            updated: { ...current_investment, ...updatedFlags },
        });

        setActualProperty({
            ...current_investment,
            flagPriceDecrease: updatedFlagPriceDecrease,
            flagFastSale: updatedFlagFastSale,
            flagAlreadySold: updatedFlagAlreadySold,
        });
    };

    const updateIsClassic = () => {
        let updatedCategories = {
            ...current_investment.categories,
            is_classic: !current_investment.categories?.is_classic,
        };
        updateInvestment({
            id: current_investment.id,
            updated: { ...current_investment, categories: updatedCategories },
        });

        setActualProperty({
            ...current_investment,
            categories: updatedCategories,
        });
    };

    const updateIsColloc = () => {
        let updatedCategories = {
            ...current_investment.categories,
            is_colloc: !current_investment.categories?.is_colloc,
        };
        updateInvestment({
            id: current_investment.id,
            updated: { ...current_investment, categories: updatedCategories },
        });

        setActualProperty({
            ...current_investment,
            categories: updatedCategories,
        });
    };

    const updateIsAirBnb = () => {
        console.log("airbnb");
        let updatedCategories = {
            ...current_investment.categories,
            is_airbnb: !current_investment.categories?.is_airbnb,
        };
        updateInvestment({
            id: current_investment.id,
            updated: { ...current_investment, categories: updatedCategories },
        });

        setActualProperty({
            ...current_investment,
            categories: updatedCategories,
        });
    };

    const isUserCanUpdateProperty =
        hasPermission(user, "write_myAgencyProperty") &&
        isAPropertyFromUserAgency;

    function PhotoViewLargeImageWrapper({ current_investment, no_pics }) {
        const [key, setKey] = useState(Date.now());

        useEffect(() => {
            setKey(Date.now());
        }, [current_investment]);

        return (
            <PhotoView
                key={key}
                src={
                    current_investment.media.pictures[0]?.largeUrl
                        ? current_investment.media.pictures[0].largeUrl
                        : no_pics
                }
            >
                <img
                    src={
                        current_investment.media.pictures[0]?.largeUrl
                            ? current_investment.media.pictures[0].largeUrl
                            : no_pics
                    }
                    alt=""
                />
            </PhotoView>
        );
    }

    return (
        <div className="header">
            <div className="header_data">
                <PhotoProvider key={current_investment.id}>
                    <div className="header_images-container">
                        <div className="header_image-large">
                            <div className={"header-flags"}>
                                {!current_investment.spotlight ? (
                                    <div className="flag" onClick={update_item}>
                                        <StarBorderIcon />
                                    </div>
                                ) : (
                                    <div className="flag" onClick={update_item}>
                                        <StarIcon />
                                    </div>
                                )}
                                {!current_investment.flagPriceDecrease ||
                                    (user.isAdmin && (
                                        <div
                                            className="flagPriceDecrease"
                                            onClick={update_flagPriceDecrease}
                                        >
                                            <Tooltip title="Le prix de ce bien à diminué">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <TrendingDownIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}
                                {!current_investment.flagPriceDecrease &&
                                    (user.isAdmin ||
                                        isUserCanUpdateProperty) && (
                                        <div
                                            className="flag"
                                            onClick={update_flagPriceDecrease}
                                        >
                                            <Tooltip title="Flagger le bien comme ayant connu une diminution du prix">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <TrendingFlatIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                {!current_investment.flagFastSale ||
                                    (user.isAdmin && (
                                        <div
                                            className="flag"
                                            onClick={update_flagFastSale}
                                        >
                                            <Tooltip title="Ce bien est à vendre rapidement">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <TimerOutlinedIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}
                                {!current_investment.flagFastSale &&
                                    (user.isAdmin ||
                                        isUserCanUpdateProperty) && (
                                        <div
                                            className="flag"
                                            onClick={update_flagFastSale}
                                        >
                                            <Tooltip title="Flagger le bien comme étant urgent à vendre">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <TimerOffOutlinedIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                {!current_investment.flagAlreadySold ||
                                    (user.isAdmin && (
                                        <div
                                            className="flag"
                                            onClick={update_flags}
                                        >
                                            <Tooltip title="Ce bien est déja vendue">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <LoyaltyRoundedIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}
                                {!current_investment.flagAlreadySold &&
                                    user.isAdmin && (
                                        <div
                                            className="flag"
                                            onClick={update_flags}
                                        >
                                            <Tooltip title="Flagger le bien comme ayant été déja vendu">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <LoyaltyOutlinedIcon />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}

                                {user.isAdmin &&
                                    (current_investment.categories
                                        ?.is_classic ? (
                                        <div
                                            className="flag"
                                            onClick={updateIsClassic}
                                        >
                                            <Tooltip title="Enlever de la catégorie 'location classique'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <ClassicCategoryChecked />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div
                                            className="flag"
                                            onClick={updateIsClassic}
                                        >
                                            <Tooltip title="Ajouter à la catégorie 'location classique'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <ClassicCategory />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}

                                {user.isAdmin &&
                                    (current_investment.categories
                                        ?.is_colloc ? (
                                        <div
                                            className="flag"
                                            onClick={updateIsColloc}
                                        >
                                            <Tooltip title="Enlever de la catégorie 'colocation'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <FlatsharingChecked />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div
                                            className="flag"
                                            onClick={updateIsColloc}
                                        >
                                            <Tooltip title="Ajouter à la catégorie 'colocation'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <Flatsharing />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}

                                {user.isAdmin &&
                                    (current_investment.categories
                                        ?.is_airbnb ? (
                                        <div
                                            className="flag"
                                            onClick={updateIsAirBnb}
                                        >
                                            <Tooltip title="Enlever de la catégorie 'colocation'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <AirBnbChecked />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div
                                            className="flag"
                                            onClick={updateIsAirBnb}
                                        >
                                            <Tooltip title="Ajouter à la catégorie 'colocation'">
                                                {/*The div around icon prevent forwardRef error in Tooltip*/}
                                                <div>
                                                    <AirBnb />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ))}
                            </div>
                            {/*<PhotoView*/}
                            {/*    src={*/}
                            {/*        current_investment.media.pictures[0]*/}
                            {/*            ?.largeUrl*/}
                            {/*            ? current_investment.media.pictures[0]*/}
                            {/*                  .largeUrl*/}
                            {/*            : no_pics*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    /!*<Button className="show-more">Voir toutes les photos</Button>*!/*/}
                            {/*</PhotoView>*/}

                            <PhotoViewLargeImageWrapper
                                current_investment={current_investment}
                                no_pics={no_pics}
                            />

                            {/*<PhotoView*/}
                            {/*    key={current_investment.id}*/}
                            {/*    src={*/}
                            {/*        current_investment.media.pictures[0]*/}
                            {/*            ?.largeUrl*/}
                            {/*            ? current_investment.media.pictures[0]*/}
                            {/*                  .largeUrl*/}
                            {/*            : no_pics*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        src={*/}
                            {/*            current_investment.media.pictures[0]*/}
                            {/*                ?.largeUrl*/}
                            {/*                ? current_investment.media*/}
                            {/*                      .pictures[0].largeUrl*/}
                            {/*                : no_pics*/}
                            {/*        }*/}
                            {/*        alt=""*/}
                            {/*    />*/}
                            {/*</PhotoView>*/}
                        </div>

                        <div className="header_image_small-container">
                            {[1, 2, 3].map((elem, id) => {
                                if (
                                    current_investment.media.pictures.length >=
                                    elem + 1
                                ) {
                                    return (
                                        <div
                                            key={id}
                                            className="header_image-small"
                                        >
                                            <PhotoView
                                                key={`${current_investment.id}-${id}`}
                                                src={
                                                    current_investment.media
                                                        .pictures[elem]
                                                        ?.largeUrl
                                                }
                                            >
                                                <img
                                                    src={
                                                        current_investment.media
                                                            .pictures[elem]
                                                            ?.mediumUrl
                                                    }
                                                    alt=""
                                                />
                                            </PhotoView>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            {current_investment.media.pictures.map(
                                (elem, id) => {
                                    if (id <= 3) return null;
                                    return (
                                        <PhotoView
                                            // key={id}
                                            key={`${current_investment.id}-${id}`}
                                            src={elem.largeUrl}
                                        ></PhotoView>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </PhotoProvider>

                <div className="header_data__top">
                    <h2>
                        {home_type[current_investment.property_data.subtype]}
                    </h2>

                    <div className="header_data__top__subtitle">
                        <p>
                            <span className="header_data__top__subtitle__location--underline">
                                {show_location(
                                    current_investment.property_data.location
                                )}
                            </span>
                            <span className="header_data__top__subtitle__location">
                                {" "}
                                •{" "}
                            </span>
                        </p>
                        {current_investment.publication?.creationDate && (
                            <p className="header_data__top__subtitle__date">
                                Date de publication:{" "}
                                {new Date(
                                    current_investment.publication.creationDate
                                ).toLocaleDateString(`fr-FR`, opt_weekday)}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(DetailsTop);
