import React, { useContext, useState } from "react";
import "./style.scss";
import { TextField } from "@mui/material";
import axios from "../../../services/axios";
import { LoadingButton } from "@mui/lab";
import ToastContext from "../../../contexts/PopupContext";
import { TOAST_LVL } from "../../../constants/constant_lists";

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState("");
    const { setToast } = useContext(ToastContext);

    const send_request = async () => {
        setLoading(true);
        const ret = await axios
            .post("/reset-password", { email: email })
            .then((e) => e.data)
            .catch((e) => null);
        if (ret) {
            setToast.current(
                TOAST_LVL.SUCCESS,
                "Email de réinitialisation envoyé",
                "Vérifiez votre boite mail. Cet email peut s'être perdu dans les spams."
            );
        } else {
            setToast.current(
                TOAST_LVL.ERROR,
                "Echec de l'envoie",
                "Veuillez vérifiez qu'il s'agisse bien de votre email Immovestor."
            );
        }
        setLoading(false);
    };

    return (
        <div id="reset-password">
            <div className="reset-center">
                <span>
                    Entrez l'email de votre compte Immovestor. Un email de
                    réinitialisation de mot de passe sera envoyé à cette
                    adresse.
                </span>
                <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    size="small"
                    fullWidth
                />
                <LoadingButton
                    onClick={send_request}
                    variant="outlined"
                    loading={loading}
                >
                    Send email
                </LoadingButton>
            </div>
        </div>
    );
};

export default ResetPassword;
