import React, { useEffect, useState } from "react";
import formatNumber from "../../helpers/formatNumber";
import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorAnnualCharges = ({
    propertyPrice,
    annualCharges,
    setAnnualCharges,
    yieldCalculatorSettings,
    mensualRent,
    propertyRc,
}) => {
    const [rc, setRc] = useState(propertyRc ? propertyRc : 0);
    const [maintenanceFees, setMaintenanceFees] = useState(
        propertyPrice ? Math.round(propertyPrice / 100) : 0
    );
    const [managementFees, setManagementFees] = useState(
        mensualRent ? Math.round(mensualRent * 12 * 0.08) : 0
    );

    const [insurance, setInsurance] = useState(0);
    const [otherCharges, setOtherCharges] = useState(0);

    useEffect(() => {
        if (!yieldCalculatorSettings) {
            return;
        }

        if (propertyPrice > 750000 && propertyPrice <= 1500000) {
            setInsurance(
                yieldCalculatorSettings
                    ? yieldCalculatorSettings.insuranceThirdStep
                    : 0
            );
        } else if (propertyPrice > 250000 && propertyPrice <= 750000) {
            setInsurance(
                yieldCalculatorSettings
                    ? yieldCalculatorSettings.insuranceSecondStep
                    : 0
            );
        } else if (propertyPrice > 1500000) {
            setInsurance(
                yieldCalculatorSettings
                    ? yieldCalculatorSettings.insuranceFourthStep
                    : 0
            );
        } else {
            setInsurance(
                yieldCalculatorSettings
                    ? yieldCalculatorSettings.insuranceFirstStep
                    : 0
            );
        }
    }, [propertyPrice, yieldCalculatorSettings]);

    useEffect(() => {
        setAnnualCharges(
            parseFloat(rc) +
                parseFloat(maintenanceFees) +
                parseFloat(managementFees) +
                parseFloat(insurance) +
                parseFloat(otherCharges)
        );
    }, [rc, maintenanceFees, managementFees, insurance, otherCharges]);

    return (
        <div className="yield-calculator__annual-charges">
            <h2 className="yield-calculator__annual-charges__title">
                Charges annuelles
            </h2>

            <YieldCalculatorInput
                value={rc}
                setValue={setRc}
                className={"yield-calculator__input"}
                label={"RC"}
                id={"annual-charges-rc"}
                prefix={"€"}
                showNotCommunicated
            />

            <YieldCalculatorInput
                value={maintenanceFees}
                setValue={setMaintenanceFees}
                className={"yield-calculator__input"}
                label={"Frais d’entretien"}
                id={"annual-charges-maintenance-fees"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={managementFees}
                setValue={setManagementFees}
                className={"yield-calculator__input"}
                label={"Frais de gestion"}
                id={"annual-charges-management-fees"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={insurance}
                setValue={setInsurance}
                className={"yield-calculator__input"}
                label={"Assurance"}
                id={"annual-charges-insurance"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={otherCharges}
                setValue={setOtherCharges}
                className={"yield-calculator__input"}
                label={"Autre charge"}
                id={"annual-charges-other-charges"}
                prefix={"€"}
            />

            <div className="yield-calculator__total">
                Total annuel des charges :{" "}
                <span>{`${formatNumber(annualCharges)} €`}</span>
            </div>
        </div>
    );
};

export default YieldCalculatorAnnualCharges;
