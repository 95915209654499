import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SaveButton from "../../components/filters/SaveButton";
import AuthContext from "../../contexts/AuthContext";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import "./details.scss";
import "react-photo-view/dist/react-photo-view.css";
import DetailsWeb from "../../components/details/details-web/DetailsWeb";
import DetailsMobile from "../../components/details/details-mobile/DetailsMobile";
import ContactModal from "../../components/details/contact-modal/ContactModal";
import ReportModal from "../../components/details/report-modal/ReportModal";
import Loading from "../../components/main/loading/Loading";
import NotFound from "../not_found/NotFound";
import { propertiesSettings } from "../../constants/constant_properties";
import hasPermission from "../../utils/hasPermission";
import isPropertyIsFrozen from "../../utils/isPropertyIsFrozen";
import axios from "../../services/axios";
import { updated_item } from "../../store/slices/investments";
import {
    youNeedAplanToSeeFlatsharingdModalSettings,
    youNeedAplanToSeeHighYieldModalSettings,
} from "../../constants/constant_modals";
import NoAccessScreen from "../noAccessScreen/NoAccessScreen";
import getSubscribeButtonText from "../../helpers/getSubscribeButtonText";
import getSubscribeButtonLink from "../../helpers/getSubscribeButtonLink";

// todo : in use only in DemographicData component : should use formatNumber in it instead of this.
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

let last_load_width = 0;

const Details = ({ updateInvestment }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { user, addedByCron, setAddedByCron } = useContext(AuthContext);
    const [reload, setReload] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showReportForm, setShowReportForm] = useState(false);
    const [actualProperty, setActualProperty] = useState(null);
    const [propertyNotFound, setPropertyNotFound] = useState(false);

    const check_reload = () => {
        if (last_load_width > 950 && window.innerWidth <= 950) {
            setReload((reload) => !reload);
            last_load_width = window.innerWidth;
        } else if (last_load_width <= 950 && window.innerWidth > 950) {
            setReload((reload) => !reload);
            last_load_width = window.innerWidth;
        }
    };

    useEffect(() => {
        const getCurrentInvestment = async () => {
            try {
                const response = await axios.get(`/propreties/${id}`);
                return response.data;
            } catch (error) {
                return null;
            }
        };

        getCurrentInvestment().then((property) => {
            if (property) {
                setActualProperty(updated_item(property));
            } else {
                setPropertyNotFound(true);
            }
        });

        window.scrollTo(0, 0);
        window.addEventListener("resize", check_reload);

        return () => {
            window.removeEventListener("resize", check_reload);
        };
    }, [id]);

    const isHighYield =
        actualProperty?.property_data?.bot_data?.rdm >=
        propertiesSettings.fremiumPlanMaxYield;
    const isFlatsharing = actualProperty?.categories?.is_colloc;

    const update_item = (
        invest,
        need_more = false,
        deleted = false,
        invisible = false
    ) => {
        // console.log("update item");
        if (need_more) {
            const updated = deleted
                ? { ...invest, deleted: true }
                : invisible
                ? { ...invest, deleted: false, is_cron: false }
                : { ...invest, visible: true, is_cron: false };
            updateInvestment({
                id: invest.id,
                updated: updated,
            }).then((e) => {
                console.log("Added by cron: ", addedByCron);
                if (addedByCron.length) {
                    let added = [...addedByCron];
                    added = added.filter((e) => e.id != invest.id);
                    setAddedByCron(added);
                    if (added.length) navigate(`/details/${added[0].id}`);
                    else navigate("/admin/new-by-cron");
                } else {
                    navigate("/admin/new-by-cron");
                }
            });
        } else {
            updateInvestment({
                id: invest.id,
                updated: { ...invest, visible: invest.visible === false },
            });
        }
    };

    if (
        propertyNotFound ||
        (actualProperty && actualProperty.visible === false && !user.isAdmin)
    ) {
        return <NotFound />;
    }

    if (!actualProperty) {
        return (
            <div className="detail-loading-container">
                <Loading />
            </div>
        );
    }

    last_load_width = window.innerWidth;

    const isAPropertyFromUserAgency = user.agencyId === actualProperty.agencyId;

    const isFrozen = isPropertyIsFrozen(user, actualProperty);

    if (isHighYield && !hasPermission(user, "read_highYield")) {
        return (
            <NoAccessScreen
                link={getSubscribeButtonLink(user)}
                buttonName={getSubscribeButtonText(user)}
                message={youNeedAplanToSeeHighYieldModalSettings.text}
            />
        );
    }

    if (isFlatsharing && !hasPermission(user, "read_flatsharing")) {
        return (
            <NoAccessScreen
                link={getSubscribeButtonLink(user)}
                buttonName={getSubscribeButtonText(user)}
                message={youNeedAplanToSeeFlatsharingdModalSettings.text}
            />
        );
    }

    if (isFrozen) {
        return (
            <NoAccessScreen
                link={getSubscribeButtonLink(user)}
                buttonName={getSubscribeButtonText(user)}
                message={
                    "Vous n'avez pas accès aux annonces bloquées par un autre utilisateur."
                }
            />
        );
    }

    return (
        <div className="main_view">
            <ContactModal
                url={actualProperty.url}
                investment={actualProperty}
                visible={showContactForm}
                close={() => setShowContactForm(false)}
            />
            <ReportModal
                elem_id={actualProperty.id}
                url={actualProperty.url}
                visible={showReportForm}
                close={() => setShowReportForm(false)}
            />

            {/*The best is probably to have only one component and not two, and use css media queries.*/}
            {window.innerWidth > 950 ? (
                <DetailsWeb
                    open_modal={(type) =>
                        type === "contact"
                            ? setShowContactForm(true)
                            : setShowReportForm(true)
                    }
                    current_investment={actualProperty}
                    setActualProperty={setActualProperty}
                    isAdmin={user.isAdmin}
                    isAPropertyFromUserAgency={isAPropertyFromUserAgency}
                />
            ) : (
                <DetailsMobile
                    open_modal={(type) =>
                        type === "contact"
                            ? setShowContactForm(true)
                            : setShowReportForm(true)
                    }
                    current_investment={actualProperty}
                    setActualProperty={setActualProperty}
                    isAdmin={user.isAdmin}
                    isAPropertyFromUserAgency={isAPropertyFromUserAgency}
                />
            )}
            {user.isAdmin ||
            (hasPermission(user, "write_myAgencyProperty") &&
                isAPropertyFromUserAgency) ? (
                // this is the edit button
                <SaveButton
                    type={2}
                    save={() => navigate("/edit-details/" + id.toString())}
                />
            ) : null}
            {user.isAdmin ? (
                <span
                    onClick={() => {
                        update_item(actualProperty, true, true);
                    }}
                    className="delete-cron"
                >
                    Delete this property
                </span>
            ) : null}
            {user.isAdmin &&
            actualProperty.visible === false &&
            actualProperty.is_cron === true ? (
                <>
                    {/*<span*/}
                    {/*    onClick={() => {*/}
                    {/*        update_item(actualProperty, true, true);*/}
                    {/*    }}*/}
                    {/*    className="delete-cron"*/}
                    {/*>*/}
                    {/*    Delete it*/}
                    {/*</span>*/}
                    <span
                        onClick={() =>
                            update_item(actualProperty, true, false, true)
                        }
                        className="delete-cron invisible"
                    >
                        Set invisible
                    </span>
                </>
            ) : null}
            {user.isAdmin ? (
                <div className="visibility">
                    {actualProperty.visible === false &&
                    actualProperty.is_cron === true ? (
                        <span onClick={() => update_item(actualProperty, true)}>
                            Accept it from cron
                        </span>
                    ) : actualProperty.visible === false ? (
                        <i
                            onClick={() => {
                                update_item(actualProperty);

                                setActualProperty({
                                    ...actualProperty,
                                    visible: true,
                                });
                            }}
                            className="fas fa-eye-slash"
                        ></i>
                    ) : (
                        <i
                            onClick={() => {
                                update_item(actualProperty);

                                setActualProperty({
                                    ...actualProperty,
                                    visible: false,
                                });
                            }}
                            className="fas fa-eye"
                        ></i>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
