import { noAccessToFunctionalityText } from "../../constants/infoMessages";
import ImmovestorButton from "../immovestorButton/ImmovestorButton";
import getSubscribeButtonLink from "../../helpers/getSubscribeButtonLink";
import getSubscribeButtonText from "../../helpers/getSubscribeButtonText";
import React from "react";

import "./noAccessMessage.scss";

const NoAccessMessage = (user) => {
    return (
        <div className={"no-access-message"}>
            <p>{noAccessToFunctionalityText}</p>
            <ImmovestorButton
                href={getSubscribeButtonLink(user)}
                newTab={true}
                className="no-access-message__button"
            >
                {getSubscribeButtonText(user)}
            </ImmovestorButton>
        </div>
    );
};

export default NoAccessMessage;
