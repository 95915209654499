import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useRef } from "react";
import AuthContext from "../../../contexts/AuthContext";
import LoadingButton from "@mui/lab/LoadingButton";
import "./style.scss";
import ToastContext from "../../../contexts/PopupContext";
import { TOAST_LVL } from "../../../constants/constant_lists";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
    const { needLog, login, setNeedLog } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { setToast } = useContext(ToastContext);
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    if (!needLog) {
        document.body.style.overflow = "unset";
        return null;
    }

    const login_wait = async () => {
        setLoading(true);
        const resp = await login(email, password);
        if (!resp) {
            setToast.current(
                TOAST_LVL.ERROR,
                "Échec de la connexion",
                "Nom d'utilisateur ou mot de passe incorrect. Veuillez réessayer"
            );
        }
        setLoading(false);
    };

    document.body.style.overflow = "hidden";

    return (
        <div className="main-log">
            <div className="center-log">
                <div className="log-header">
                    <h2>
                        Connectez-vous et profitez de toutes les fonctionnalités
                    </h2>
                    <i
                        onClick={() => setNeedLog(false)}
                        className="fas fa-times"
                    ></i>
                </div>
                <div className="log-form">
                    <TextField
                        fullWidth
                        label={"Email"}
                        autoComplete="email"
                        variant="outlined"
                        value={email.current}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        fullWidth
                        autoComplete="password"
                        type={showPassword ? "text" : "password"}
                        label={"Password"}
                        variant="outlined"
                        value={password.current}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") login_wait();
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={handleTogglePassword}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            ),
                        }}
                    />
                    <div className="forgotten-password-container">
                        <Link
                            onClick={() => setNeedLog(false)}
                            to={"/reset-password"}
                        >
                            Mot de passe oublié?
                        </Link>
                    </div>
                    <LoadingButton
                        disabled={email === "" || password === ""}
                        loading={loading}
                        fullWidth
                        variant="outlined"
                        onClick={() => login_wait()}
                    >
                        Se connecter
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
};

export default Login;
