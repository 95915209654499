import React from "react";
import ImageUploading from "react-images-uploading";

import "./imagesUpload.scss";
import { FaTrash } from "react-icons/fa";

const ImagesUpload = ({ images, setImages }) => {
    const MAX_NUMBER = 10;
    const MAX_MB_SIZE = 2;
    // const MAX_MB_SIZE = 0.4;
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    return (
        <div className="images-upload__container">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={MAX_NUMBER}
                dataURLKey="data_url"
                acceptType={["jpg"]}
                maxFileSize={MAX_MB_SIZE * 1048576}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                }) => (
                    // write your building UI
                    <div className="images-upload__image-wrapper">
                        <button
                            className={`images-upload__drop-button ${
                                isDragging && "dragging"
                            }`}
                            // style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            Cliquez ici ou glisser les images ici
                        </button>
                        {/*<button onClick={onImageRemoveAll}>*/}
                        {/*    Supprimer toutes les images*/}
                        {/*</button>*/}
                        <div className={"images-upload__images-list"}>
                            {imageList.map((image, index) => (
                                <div
                                    key={index}
                                    className="images-upload__images-list__item"
                                >
                                    <img
                                        class={"images-upload__image"}
                                        src={image.data_url}
                                        alt=""
                                        width="100"
                                    />
                                    <button
                                        className="images-upload__images-list__item__delete-button"
                                        onClick={() => onImageRemove(index)}
                                    >
                                        <FaTrash
                                            className={
                                                "images-upload__images-list__item__delete-icon"
                                            }
                                        />
                                    </button>
                                </div>
                            ))}
                        </div>

                        {errors && errors.maxFileSize && (
                            <div className="error">
                                {`Le fichier est trop lourd. La taille maximale
                                    autorisée est de ${MAX_MB_SIZE}MB.`}
                            </div>
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

export default ImagesUpload;
