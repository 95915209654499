import React, { useRef, useState } from "react";
import "./style.scss";
import { Modal } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import MarketDataCard from "../../../marketDataCard/MarketDataCard";
import MarketDataMap from "../../../marketDataMap/MarketDataMap";

const columns = [
    { field: "category", headerName: "Type de bien", width: 120 },
    { field: "postal_code", headerName: "Code postal", width: 100 },
    { field: "nbr_bedrooms", headerName: "Nombre de chambres", width: 180 },
    { field: "peb", headerName: "Peb", width: 120 },
    { field: "surface", headerName: "Surface", width: 100 },
    { field: "rent", headerName: "Loyer", width: 100 },
    {
        field: "view",
        headerName: "",
        renderCell: (cellValues) => <a>Voir</a>,
        sortable: false,
        width: 100,
    },
];

const ShowData = ({ data, isGoogleLoaded, coordinates }) => {
    const safe_data = {
        properties: data?.properties ? data.properties : [],
        average_rent: data?.average_rent ? data.average_rent : 0,
        number_of_records: data?.number_of_records ? data.number_of_records : 0,
    };
    const [selectedField, setSelectedField] = useState(null);
    const [page, setPage] = useState(0);
    const [isPropertyDetailVisible, setIsPropertyDetailVisible] =
        useState(false);

    const [detailCardData, setDetailCardData] = useState({
        headerImageUrl: "",
        title: "",
        description: "",
    });

    const setCurrentCard = useRef(null);

    if (selectedField != null) {
        const chart_list = safe_data.properties.map(
            (elem) => elem[selectedField]
        );
        const chart_count = [];
        for (let i = 0; i < chart_list.length; i++) {
            const e = chart_list[i];
            const inside = chart_count.filter((a) => a.data == e);
            if (inside.length == 0) {
                chart_count.push({
                    data: e,
                    count: 1,
                });
            } else {
                const ind = chart_count.indexOf(inside[0]);
                chart_count[ind].count += 1;
            }
        }
    }

    const handleClick = (cellData) => {
        const propertyData = cellData.row;

        if (setDetailCardData && setIsPropertyDetailVisible)
            setCurrentCard.current(propertyData.id);
    };

    return (
        <div id="admin-market-data-show">
            <div className="data-header">
                <div className="data-show">
                    <span className="label">Nombre de biens</span>
                    <span className="value">{safe_data.number_of_records}</span>
                </div>
                <div className="data-show">
                    <span className="label">Moyenne des loyers</span>
                    <span className="value">
                        {safe_data.average_rent.toFixed(2)}€
                    </span>
                </div>
            </div>

            <MarketDataMap
                setIsPropertyDetailVisible={setIsPropertyDetailVisible}
                setDetailCardData={setDetailCardData}
                setCurrentCard={setCurrentCard}
                properties={safe_data.properties}
                isGoogleLoaded={isGoogleLoaded}
                coordinates={coordinates}
            />

            <div className="data-body">
                <DataGrid
                    rows={safe_data.properties}
                    columns={columns}
                    onPaginationModelChange={(e) => setPage(e.page)}
                    paginationModel={{ page, pageSize: 9 }}
                    onRowClick={(cellData) => handleClick(cellData)}
                />
            </div>
            <MarketDataCard
                setCurrentCard={setCurrentCard}
                properties={safe_data.properties}
                headerImageUrl={detailCardData.headerImageUrl}
                title={detailCardData.title}
                description={detailCardData.description}
                rent={detailCardData.rent}
                isVisible={isPropertyDetailVisible}
                setIsVisible={setIsPropertyDetailVisible}
                fullScreenMarketData
            />

            <Modal
                open={selectedField != null}
                onClose={() => setSelectedField(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-center"></div>
            </Modal>
        </div>
    );
};

export default ShowData;
