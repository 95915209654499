import React from "react";
import formatNumber from "../../helpers/formatNumber";
import InfoTooltip from "../infoTooltip/InfoTooltip";

const YieldCalculatorResult = ({
    mensualRent,
    annualCharges,
    investTotal,
    monthlyRent,
    bulletMonthlyPayment,
}) => {
    const annualRent = parseFloat(mensualRent) * 12;
    const yearlyClassicLoanPayment = monthlyRent * 12;
    const yearlyBulletLoanPayment = bulletMonthlyPayment * 12;

    const grossReturn =
        ((parseFloat(mensualRent) * 12) / parseFloat(investTotal)) * 100;
    const netAnnualCashflowExcludingFinancing =
        annualRent - parseFloat(annualCharges);
    const netAnnualCashFlowIncludingFinancing =
        annualRent -
        annualCharges -
        yearlyClassicLoanPayment -
        yearlyBulletLoanPayment;
    const netReturn =
        (netAnnualCashflowExcludingFinancing / parseFloat(investTotal)) * 100;

    return (
        <div className="yield-calculator__result">
            <h2 className="yield-calculator__result__title">Résultat</h2>
            <div className="yield-calculator__total">
                Rendement brut :{" "}
                <span>{`${formatNumber(grossReturn, 1)} %`}</span>
                <InfoTooltip
                    infoText={
                        "Le rendement brut est calculé de la manière suivante : (loyer annuel / investissement total) * 100"
                    }
                />
            </div>
            <div className="yield-calculator__total">
                Rendement net : <span>{`${formatNumber(netReturn, 1)} %`}</span>
                <InfoTooltip
                    infoText={
                        "Le rendement net est calculé de la manière suivante : ((loyer annuel - total des charges) / investissement total) * 100"
                    }
                />
            </div>
            <div className="yield-calculator__total">
                Cash-Flow annuel net hors financement :{" "}
                <span>{`${formatNumber(
                    netAnnualCashflowExcludingFinancing
                )} €`}</span>
                <InfoTooltip
                    infoText={
                        "Le cash-Flow annuel net hors financement est calculé de la manière suivante : loyer annuel - charges annuelles"
                    }
                />
            </div>
            <div className="yield-calculator__total">
                Cash-Flow annuel net avec financement :{" "}
                <span>{`${formatNumber(
                    netAnnualCashFlowIncludingFinancing
                )} €`}</span>
                <InfoTooltip
                    infoText={
                        "Le cash-Flow annuel net avec financement  est calculé de la manière suivante : loyer annuel - charges annuelles - mensualité crédit classique sur 12 mois - mensualité terme fixe sur 12 mois"
                    }
                />
            </div>
        </div>
    );
};

export default YieldCalculatorResult;
