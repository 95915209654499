import React, { useEffect, useState } from 'react'
import "./styles.scss"

let lastScrollTop = 0;
let yet_click = false;

const titles = [
	"Lancer une recherche",
	"Enrigistrer les modifications",
	"Modifier ce document",
	"Nos outils",
	"Soumettre cette offre"
];

const icons = [
	"fa-search",
	"fa-save",
	"fa-edit",
	"fa-tools",
	"fa-save"
];



const SaveButton = ({save, type=0, hover=null}) => {

    const [openButton, setOpenButton] = useState(false);
	
	useEffect(() => {
		const open_bot = () => {
			let st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > lastScrollTop && window.pageYOffset > 30) {
				setOpenButton(true);
			} else {
				setOpenButton(false);
			}
			lastScrollTop = st <= 0 ? 0 : st;
		}
		window.addEventListener("scroll", open_bot);
	
		return () => {
			window.removeEventListener("scroll", open_bot);
		}
	}, [])

	const set_hover = (value) => {
		if (hover) {
			hover(value);
		}
	}

	return (
		<div onMouseEnter={() => set_hover(true)} onMouseLeave={() => set_hover(false)} onClick={() => save()} className={`bot_button${openButton ? " open" : ""} ${"abcdef"[type]}`}>
            <div className='unwrap'>
                <span>{titles[type]}</span>
                <div className='icon_box'>
                    <i className={`fas ${icons[type]}`}></i>
                </div>
            </div>
        </div>
	)
}

export default SaveButton