import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClearButton from "../../components/filters/ClearButton";
import SaveButton from "../../components/filters/SaveButton";
import SearchComponent from "../../components/home_invest/SearchComponent";
import {
    good_state,
    home_type,
    pebs,
    TOAST_LVL,
} from "../../constants/constant_lists";
import { mapDispatchToProps } from "../../store/dispatcher";
import axios from "../../services/axios";
import Switch from "@mui/material/Switch";
import "../filters/style.scss";
import "./style.scss";
import ToastContext from "../../contexts/PopupContext";
import AuthContext from "../../contexts/AuthContext";
import hasPermission from "../../utils/hasPermission";
import NoAccessScreen from "../noAccessScreen/NoAccessScreen";
import NumericInputOnChange from "../../components/NumericInput/NumericInputOnChange ";
import getSubscribeButtonText from "../../helpers/getSubscribeButtonText";
import getSubscribeButtonLink from "../../helpers/getSubscribeButtonLink";

const FilterBlock = ({ title, children }) => {
    return (
        <div className="filter-block">
            <div className="filter-block-header">
                <h2>{title}</h2>
            </div>
            {children}
        </div>
    );
};

const Alerts = () => {
    const { setToast } = useContext(ToastContext);
    const { user, getProfile } = useContext(AuthContext);
    const navigate = useNavigate();
    // alerts activated by default
    const [activateAlert, setActivateAlert] = useState(
        user.alert_activated !== undefined ? user.alert_activated : true
    );
    const [criterias, setCriterias] = useState(
        user.alert_criterias
            ? user.alert_criterias
            : {
                  minPrice: 0,
                  maxPrice: 0,
                  minYeld: 0,
                  maxYeld: 0,
                  minRent: 0,
                  maxRent: 0,
                  placeType: "all",
                  conditions: "all",
                  epcScores: "Tous",
                  zip_codes: [],
                  provinces: [],
                  districts: [],
                  zips: [],
              }
    );

    const alert_activation = async (e) => {
        setActivateAlert(e.target.checked);
        const ret = await axios
            .put("/users/alert", {
                activated: e.target.checked,
            })
            .then((e) => e.data)
            .catch((e) => {
                console.log(e);
                return null;
            });
        await getProfile();
    };

    useEffect(() => {
        if (!user.token || user.token === "") {
            return navigate("/");
        }
    }, []);

    const handleSave = async () => {
        const criterias_to_save = { ...criterias };
        if (criterias_to_save.epcScores !== "") {
            let id = pebs.indexOf(criterias_to_save.epcScores);
            const list = [];
            for (let index = 1; index <= id; index++) {
                list.push(pebs[index]);
            }
            criterias_to_save.epcScores = list.join("|");
        }
        const ret = await axios
            .put("/users/alert", {
                criterias: criterias,
                activated: activateAlert,
            })
            .then((e) => e.data)
            .catch((e) => {
                console.log(e);
                return null;
            });
        if (ret !== null) {
            await getProfile();
            setToast.current(
                TOAST_LVL.SUCCESS,
                "Enregistrement réussi",
                "Vos critères d'alerte ont été sauvegardés avec succès."
            );
        } else {
            setToast.current(
                TOAST_LVL.SUCCESS,
                "Enregistrement échoué",
                "Vos critères d'alerte n'ont pas pu être sauvegardés. Veuillez réessayer."
            );
        }
    };

    const reset = () => {
        setCriterias({
            minPrice: 0,
            maxPrice: 0,
            minYeld: 0,
            maxYeld: 0,
            minRent: 0,
            maxRent: 0,
            placeType: "all",
            conditions: "all",
            epcScores: "Tous",
            zip_codes: [],
            provinces: [],
            districts: [],
            zips: [],
        });
    };

    const search_zip = (zips, others) => {
        setCriterias({
            ...criterias,
            zip_codes: others.zip_codes,
            provinces: others.provinces,
            districts: others.districts,
            zips: zips,
        });
    };

    return (
        <>
            {hasPermission(user, ["write_alert", "read_alert"]) ? (
                <div className="filters-view daily-alert">
                    <div className="filters-center">
                        <div className="filters-header">
                            <h1>Alerte personnalisée</h1>
                            <p>
                                Notre alerte quotidienne est une fonctionnalité
                                pratique qui vous permet de rester informé en
                                temps réel des nouvelles annonces de biens
                                immobiliers correspondant à vos critères de
                                recherche.
                            </p>
                        </div>
                        <div className="alert-activation">
                            <span>
                                {activateAlert
                                    ? "Alerte activée"
                                    : "Alerte désactivée"}
                            </span>
                            <Switch
                                checked={activateAlert}
                                onChange={alert_activation}
                            />
                        </div>

                        {!activateAlert ? null : (
                            <>
                                <SearchComponent
                                    filters={{
                                        zip_codes: criterias.zip_codes,
                                        districts: criterias.districts,
                                        provinces: criterias.provinces,
                                    }}
                                    search_zip={search_zip}
                                />

                                <FilterBlock title={"Type de bien"}>
                                    <FormControl className="main-input">
                                        <InputLabel id="demo-simple-select-label">
                                            Type de bien
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={criterias.placeType}
                                            label="Type de bien"
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    placeType: e.target.value,
                                                })
                                            }
                                        >
                                            {Object.keys(home_type).map(
                                                (elem, id) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={elem}
                                                    >
                                                        {home_type[elem]}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </FilterBlock>
                                <FilterBlock title={"Etat du bien"}>
                                    <FormControl className="main-input">
                                        <InputLabel id="demo-simple-select-label">
                                            Etat du bien
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={criterias.conditions}
                                            label="Etat du bien"
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    conditions: e.target.value,
                                                })
                                            }
                                        >
                                            {Object.keys(good_state).map(
                                                (elem, id) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={elem}
                                                    >
                                                        {good_state[elem]}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </FilterBlock>
                                <FilterBlock title={"Budget"}>
                                    <h3>Prix</h3>
                                    <div className="price-inputs">
                                        <NumericInputOnChange
                                            value={
                                                criterias.minPrice !== 0
                                                    ? criterias.minPrice
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    minPrice: e.target.value,
                                                })
                                            }
                                            id="form-minimum-price"
                                            label="Prix minimum"
                                            prefix={"€"}
                                        />
                                        <NumericInputOnChange
                                            value={
                                                criterias.maxPrice !== 0
                                                    ? criterias.maxPrice
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    maxPrice: e.target.value,
                                                })
                                            }
                                            id="form-maximum-price"
                                            label="Prix maximum"
                                            prefix={"€"}
                                        />
                                    </div>
                                </FilterBlock>
                                <FilterBlock title={"Loyer"}>
                                    <h3>Loyer mensuel</h3>
                                    <div className="price-inputs">
                                        <NumericInputOnChange
                                            value={
                                                criterias.minRent !== 0
                                                    ? criterias.minRent
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    minRent: e.target.value,
                                                })
                                            }
                                            id="form-minimum-rent"
                                            label="Loyer minimum"
                                            prefix={"€"}
                                        />
                                        <NumericInputOnChange
                                            value={
                                                criterias.maxRent !== 0
                                                    ? criterias.maxRent
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    maxRent: e.target.value,
                                                })
                                            }
                                            id="form-maximum-rent"
                                            label="Loyer maximum"
                                            prefix={"€"}
                                        />
                                    </div>
                                </FilterBlock>
                                <FilterBlock title={"Rendement brut"}>
                                    <h3>Rendement</h3>
                                    <div className="price-inputs">
                                        <NumericInputOnChange
                                            value={
                                                criterias.minYeld !== 0
                                                    ? criterias.minYeld
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    minYeld: e.target.value,
                                                })
                                            }
                                            id="form-minimum-yield"
                                            label="Rendement minimum"
                                            prefix={"%"}
                                        />
                                        <NumericInputOnChange
                                            value={
                                                criterias.maxYeld !== 0
                                                    ? criterias.maxYeld
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    maxYeld: e.target.value,
                                                })
                                            }
                                            id="form-maximum-yield"
                                            label="Rendement maximum"
                                            prefix={"%"}
                                        />
                                    </div>
                                </FilterBlock>
                                <FilterBlock title={"PEB"}>
                                    <FormControl className="main-input">
                                        <InputLabel id="demo-simple-select-label">
                                            Peb minimum
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            value={criterias.epcScores}
                                            label="Peb minimum"
                                            onChange={(e) =>
                                                setCriterias({
                                                    ...criterias,
                                                    epcScores: e.target.value,
                                                })
                                            }
                                        >
                                            {pebs.map((elem, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={elem}
                                                >
                                                    {elem}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FilterBlock>
                            </>
                        )}
                    </div>
                    <ClearButton save={reset} type={1} />
                    <SaveButton save={handleSave} type={1} />
                </div>
            ) : (
                <NoAccessScreen
                    link={getSubscribeButtonLink(user)}
                    buttonName={getSubscribeButtonText(user)}
                    message={"Vous n'avez pas accès aux alertes."}
                />
            )}
        </>
    );
};

export default connect(null, mapDispatchToProps)(Alerts);
