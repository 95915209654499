import React, { useContext, useEffect, useState } from "react";
import "./detailsPrice.scss";
import AuthContext from "../../../contexts/AuthContext";
import hasPermission from "../../../utils/hasPermission";
import FavoriteButton from "../../favoriteButton/FavoriteButton";
import FreezeButton from "../../freezeButton/freezeButton";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import InfoTooltip from "../../infoTooltip/InfoTooltip";

const DetailsPrice = ({
    property,
    price,
    rentPrice,
    yieldPercentage,
    updateFavorite,
    favorites,
}) => {
    const [userCanUseFavoritesProperties, setUserCanUseFavoritesProperties] =
        useState(false);
    const [userCanUseFreezedProperties, setUserCanUseFreezedProperties] =
        useState(false);
    const { user } = useContext(AuthContext);
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        setUserCanUseFavoritesProperties(
            hasPermission(user, [
                "read_favoritesProperties",
                "write_favoritesProperties",
            ])
        );

        setUserCanUseFreezedProperties(
            hasPermission(user, ["read_FreezeProperty", "write_freezeProperty"])
        );

        if (favorites && favorites.favoritesProperties) {
            setIsFavorite(
                favorites.favoritesProperties.some(
                    (item) => item.id === property.id
                )
            );
        }
    }, [user]);

    return (
        <div className="price-detail">
            <div className="price-detail__left">
                <div className="price-detail__left__tools">
                    {userCanUseFavoritesProperties && (
                        <div className="price-detail__left__favorite">
                            <FavoriteButton
                                isFavorite={isFavorite}
                                setIsFavorite={setIsFavorite}
                                propertyId={property.id}
                                updateFavorite={(id) => {
                                    updateFavorite(id);
                                }}
                            />
                        </div>
                    )}

                    {userCanUseFreezedProperties && (
                        <div className="price-detail__left__freeze">
                            <FreezeButton property={property} />
                        </div>
                    )}
                </div>

                <div className="price-detail__left__price-container">
                    <div className="price-detail__left__price-label">
                        Prix d'achat
                    </div>
                    <div className="price-detail__left__price">{price}</div>
                </div>

                {rentPrice && (
                    <div className="price-detail__left__rent-container">
                        <div className="price-detail__left__rent-label">
                            Loyer&nbsp;<span>(communiqué par l'agent)</span>
                        </div>
                        <div className="price-detail__left__rent">
                            {rentPrice}/mois
                        </div>
                    </div>
                )}
            </div>

            {yieldPercentage && (
                <div className="price-detail__right">
                    <div className="price-detail__right__yield-container">
                        <div className="price-detail__right__yield">
                            {yieldPercentage}
                        </div>
                        <div className="price-detail__right__yield-label">
                            Rendement brut
                            <InfoTooltip
                                infoText={
                                    "Le rendement brut en pourcent est calculé de la manière suivante: loyer mensuel * 12 / (prix * 1.125) * 100"
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// export default DetailsPrice;

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPrice);
