import { Button, Divider, Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./navbar.scss";
import "../../styles/components/buttons.scss";
import hasPermission from "../../utils/hasPermission";
import { ModalContext } from "../../contexts/ModalContext";
import { needAssistanceModalSettings } from "../../constants/constant_modals";
import CloseIcon from "@mui/icons-material/Close";
import ImmovestorButton from "../immovestorButton/ImmovestorButton";
import settings from "../../settings";
import Countdown from "../counter/Counter";

const NavBar = () => {
    const { setNeedLog, user, logout } = useContext(AuthContext);
    const { state, dispatch } = useContext(ModalContext);

    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAssistanceRequest = () => {
        const userEmail = user.email;
        // const userName = user.first_name + " " + user.last_name;

        dispatch({
            type: "openModal",
            payload: {
                ...needAssistanceModalSettings,
                userEmail: userEmail,
                sendData: {
                    // name: userName,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    phone: user.phone,
                },
            },
        });
    };

    const scroll_listener = () => {
        if (window.scrollY > 0) {
            document.getElementById("main-nav").classList.add("hov");
        } else {
            document.getElementById("main-nav").classList.remove("hov");
        }
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerOpen(open);
    };

    useEffect(() => {
        window.addEventListener("scroll", scroll_listener);

        return () => {
            window.removeEventListener("scroll", scroll_listener);
        };
    }, []);

    /**
     * General menu that appear on top in desktop view and on top of drawer in mobile view.
     */
    const generalMenuItems = [
        {
            text: "Nos biens",
            link: "/search?page=1&sort=pert&count_per_pages=12&spotlight=false",
        },
        {
            text: "Notre plateforme",
            link: "https://www.immovestor.be/",
            outsideTab: true, // For opening in another tab
        },
        {
            text: "Données de marché",
            link: "/market-data",
        },
        // {
        //     text: "Nos tarifs",
        //     link: user.token
        //         ? "/pricing"
        //         : "https://www.immovestor.be/#comp-ld5qeotk",
        //     outsideTab: !user.token,
        // },
        // {
        //     text: "Investisseur",
        //     link: "https://www.immovestor.be/#comp-ld5nj70y",
        //     outsideTab: true,
        // },
        // {
        //     text: "Agent Immobilier",
        //     link: "https://www.immovestor.be/#comp-ld5yxoch",
        //     outsideTab: true,
        // },
    ];

    /**
     * Menu for the tools of the user.
     * An item can be hidden on the phone drawer with hiddenForPhone key.
     */
    const appToolsMenu = [
        {
            text: "Mes alertes",
            link: "/alerts",
            permissions: ["write_alert", "read_alert"],
        },
        {
            text: "Calculateur de rendement",
            link: "/yield-calculator",
            permissions: ["read_yieldCalculator", "write_yieldCalculator"],
            hiddenForPhone: true, // hidden for the phone drawer
        },
        {
            text: "Données de marché",
            link: "/market-data",
            permissions: ["read_marketData"],
            hiddenForPhone: true,
        },
        {
            text: "Mes favoris",
            link: "/favorites-properties",
            permissions: ["read_favoritesProperties"],
        },
        {
            text: "Mes annonces bloquées",
            link: "/freezed-properties",
            permissions: ["read_FreezeProperty"],
        },
        {
            text: "Les annonces de mon agence",
            link: "/my-agency-properties",
            permissions: ["read_myAgencyProperty"],
        },
        {
            text: "Ajouter une annonce",
            link: "/new-by-form",
            permissions: ["write_newPropertyFromForm"],
        },
        {
            text: "Ajouter une annonce depuis une URL",
            link: "/new-by-url",
            permissions: ["write_PropertyByUrl"],
        },
    ];

    const hasAtLeastOneTool = appToolsMenu.some(
        (item) => hasPermission(user, item.permissions) && !item.hiddenForPhone
    );

    const StarterTrialInfo = () => {
        const [endOfTrial, setEndOfTrial] = useState(null);

        useEffect(() => {
            if (!user.createdAt || (user.role && user.role !== "start")) return;

            const userCreatedAtDate = new Date(
                user.createdAt._seconds * 1000 +
                    user.createdAt._nanoseconds / 1000000
            );

            const endOfTrialDate = new Date(
                userCreatedAtDate.getTime() +
                    settings.InvestorTryoutDuration * 60 * 60 * 1000
            );

            if (endOfTrialDate < new Date()) return;

            // Countdown needs a timestamp in seconds
            setEndOfTrial(Math.floor(endOfTrialDate.getTime() / 1000));
        }, [user]);

        return (
            endOfTrial && (
                <div className={"main-nav__starter-trial"}>
                    <span className={"main-nav__starter-trial__text"}>
                        {
                            "Vous pouvez encore profiter de notre offre gratuite pour une période de"
                        }
                    </span>
                    <Countdown
                        className={"main-nav__starter-trial__countdown"}
                        timestamp={endOfTrial}
                    />
                </div>
            )
        );
    };

    return (
        <nav id="main-nav" className={"main-nav"}>
            <div className={"main-nav__top"}>
                <div
                    onClick={() => {
                        navigate("/home");
                    }}
                    className="logo"
                >
                    <div className={"logo__text"}>
                        <div className="logo__text--start">IMMO</div>
                        <div className="logo__text--end">VESTOR</div>
                    </div>
                </div>

                <div className="links">
                    {/*!!!!!!!!!!!!!!!!!!*/}
                    {/*desktop header menu*/}
                    {/*!!!!!!!!!!!!!!!!!!*/}
                    <li>
                        <Link to={"/home"}>Accueil</Link>
                    </li>

                    {generalMenuItems.map((item, index) => (
                        <li key={index}>
                            {item.outsideTab ? (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.text}
                                </a>
                            ) : (
                                <Link to={item.link}>{item.text}</Link>
                            )}
                        </li>
                    ))}
                </div>

                {/*!!!!!!!!!!!!!!!!!!*/}
                {/*desktop header account menu*/}
                {/*!!!!!!!!!!!!!!!!!!*/}
                <ul className="account">
                    {user.token !== "" ? (
                        <>
                            {user.isAdmin && (
                                <div className="admin-nav">
                                    <Button
                                        className="admin-button"
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            navigate("/admin/new-by-cron");
                                        }}
                                    >
                                        Ajouté avec le cron
                                    </Button>
                                </div>
                            )}
                            {user.isAdmin && (
                                <div className="admin-nav">
                                    <Button
                                        className="admin-button"
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            navigate("/admin");
                                        }}
                                    >
                                        Interface Admin
                                    </Button>
                                </div>
                            )}
                            <div>
                                <Button
                                    onMouseEnter={handleClick}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        navigate("/profile");
                                    }}
                                >
                                    Mon compte
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <ImmovestorButton
                                className="login"
                                onClick={() => {
                                    setNeedLog(true);
                                }}
                                outlined={true}
                            >
                                S'identifier
                            </ImmovestorButton>
                            <ImmovestorButton
                                className="main-nav__signup-button"
                                onClick={() => {
                                    navigate("/register");
                                }}
                            >
                                {/*Créer un compte*/}
                                {/*Je teste pendant 48h*/}
                                Me créer une alerte
                            </ImmovestorButton>
                        </>
                    )}
                </ul>

                <div className="drawer-button">
                    <DragHandleIcon
                        sx={{ fontSize: 32 }}
                        onClick={toggleDrawer(true)}
                    />
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={toggleDrawer(false)}
                            aria-label="close"
                            sx={{
                                position: "fixed",
                                top: 10,
                                right: 20,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        {/*!!!!!!!!!!!!!!!!!!*/}
                        {/*drawer menu*/}
                        {/*!!!!!!!!!!!!!!!!!!*/}
                        <div className="drawer-content">
                            <ul onClick={() => setDrawerOpen(false)}>
                                <li>
                                    <Link to="/home">
                                        <div className="navigation_elem">
                                            <span>Accueil</span>
                                        </div>
                                    </Link>
                                </li>

                                {generalMenuItems.map((item, index) => (
                                    <li key={index}>
                                        {item.outsideTab ? (
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className="navigation_elem">
                                                    <span>{item.text}</span>
                                                </div>
                                            </a>
                                        ) : (
                                            <Link to={item.link}>
                                                <div className="navigation_elem">
                                                    <span>{item.text}</span>
                                                </div>
                                            </Link>
                                        )}
                                    </li>
                                ))}

                                {hasAtLeastOneTool && (
                                    <li>
                                        <div className="separator">
                                            <h2 className="separator_title">
                                                Mes outils
                                            </h2>
                                        </div>
                                    </li>
                                )}

                                {appToolsMenu.map((item, index) => {
                                    if (
                                        hasPermission(user, item.permissions) &&
                                        !item.hiddenForPhone
                                    ) {
                                        return (
                                            <li key={index}>
                                                <Link to={item.link}>
                                                    <div className="navigation_elem">
                                                        <span>{item.text}</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return null;
                                })}

                                {user.token !== "" ? (
                                    <>
                                        <li>
                                            <div className="separator">
                                                <h2 className="separator_title">
                                                    Mon compte
                                                </h2>
                                            </div>
                                        </li>
                                        {user.isAdmin && (
                                            <li>
                                                <Link to={"/admin"}>
                                                    <div className="immo-button immo-button--primary immo-button--div nav-drawer-button">
                                                        {/*<i className="fas fa-cog"></i>*/}
                                                        Interface Admin
                                                    </div>
                                                </Link>
                                            </li>
                                        )}
                                        <li
                                            className={
                                                location.pathname.includes(
                                                    "/profile"
                                                )
                                                    ? "active hov"
                                                    : "hov"
                                            }
                                        >
                                            <Link to="/profile">
                                                <div className="immo-button immo-button--primary immo-button--div nav-drawer-button">
                                                    {/*<i className="fas fa-cog"></i>*/}
                                                    Mon profil
                                                </div>
                                            </Link>
                                        </li>
                                        {hasPermission(
                                            user,
                                            "send_assistanceEmailRequest"
                                        ) && (
                                            <li>
                                                <div
                                                    className="immo-button immo-button--primary immo-button--div nav-drawer-button"
                                                    onClick={() => {
                                                        handleClose();
                                                        handleAssistanceRequest();
                                                    }}
                                                >
                                                    Demander de l'assistance
                                                </div>
                                            </li>
                                        )}
                                        <li
                                            className={
                                                location.pathname === "/logout"
                                                    ? "active hov"
                                                    : "hov"
                                            }
                                        >
                                            <Link to="/home" onClick={logout}>
                                                <div className="immo-button immo-button--primary immo-button--div nav-drawer-button">
                                                    {/*<i className="fas fa-sign-out-alt"></i>*/}
                                                    Déconnexion
                                                </div>
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li
                                            className={
                                                "connect-button-container"
                                            }
                                        >
                                            <ImmovestorButton
                                                // className={
                                                //     "immo-button immo-button--primary"
                                                // }
                                                onClick={() => {
                                                    setNeedLog(true);
                                                }}
                                                outlined={true}
                                            >
                                                Se connecter
                                            </ImmovestorButton>
                                        </li>
                                        <li
                                            className={
                                                "connect-button-container"
                                            }
                                        >
                                            <ImmovestorButton
                                                onClick={() => {
                                                    navigate("/register");
                                                }}
                                            >
                                                Me créer une alerte
                                            </ImmovestorButton>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </Drawer>
                </div>
            </div>

            <div className={"main-nav__down"}>
                {/*uncomment this StarterTrialInfo component to get back the 48h trial message*/}
                {/*<StarterTrialInfo />*/}
            </div>

            {/*!!!!!!!!!!!!!!!!!!*/}
            {/*dropdown menu*/}
            {/*!!!!!!!!!!!!!!!!!!*/}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/*the tools*/}
                {appToolsMenu.map((item, index) => {
                    if (hasPermission(user, item.permissions)) {
                        return (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    handleClose();
                                    navigate(item.link);
                                }}
                            >
                                {item.text}
                            </MenuItem>
                        );
                    }
                    return null;
                })}

                <Divider />

                {/*the account menu*/}

                <MenuItem
                    onClick={() => {
                        handleClose();
                        navigate("/profile");
                    }}
                >
                    Mon profil
                </MenuItem>

                {hasPermission(user, "send_assistanceEmailRequest") && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            handleAssistanceRequest();
                        }}
                    >
                        Demander de l'assistance
                    </MenuItem>
                )}

                <MenuItem
                    onClick={() => {
                        handleClose();
                        logout();
                    }}
                >
                    Logout
                </MenuItem>
            </Menu>
        </nav>
    );
};

export default NavBar;
