import { useJsApiLoader } from "@react-google-maps/api";
import settings from "../../settings";

/**
 * !!!!!!!!!!
 * docs for @react-google-maps/api is down, so it's accessible here:
 * https://web.archive.org/web/20230701010714mp_/https://react-google-maps-api-docs.netlify.app/
 */
const useGoogleMaps = () => {
    const { isLoaded: isGoogleLoaded, loadError } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: settings.googleMapsApiKey,
        libraries: settings.googleMapsLibraries,
    });

    return { isGoogleLoaded, loadError };
};

export default useGoogleMaps;
