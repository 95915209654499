import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { IconButton, Tooltip } from "@mui/material";
import { useTools } from "../../contexts/ToolsContext";

/**
 * For unknow reason, the use of redux slice and dispatch make button bug
 * isFavorite can never be true when dispatch.
 * @returns
 */
const FavoriteButton = ({ isFavorite, setIsFavorite, propertyId }) => {
    const { updateFavorite } = useTools();

    /**
     * redux version doesn't work because there is re-renderd trigged by Details.jsx
     */
    const handleClick = () => {
        setIsFavorite(!isFavorite);
        updateFavorite(propertyId); // context version
    };

    return (
        <Tooltip title={"Mettre cette annonce dans vos favoris."}>
            <IconButton
                color={isFavorite ? "primary" : "default"}
                onClick={handleClick}
            >
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
        </Tooltip>
    );
};

export default FavoriteButton;

/**
 * redux version that don't work because re-render
 */
// import React, { useEffect, useRef } from "react";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import { IconButton } from "@mui/material";
// // import { connect } from "react-redux";
// // import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";

// const FavoriteButton = ({
//     isFavorite,
//     setIsFavorite,
//     propertyId,
//     updateFavorite,
// }) => {
//     const handleClick = () => {
//         setIsFavorite(!isFavorite);
//     };

//     const isMounted = useRef(false);

//     useEffect(() => {
//         if (isMounted.current) {
//             if (isFavorite) {
//                 updateFavorite(propertyId);
//             }
//         } else {
//             isMounted.current = true;
//         }
//     }, [isFavorite]);

//     return (
//         <IconButton
//             color={isFavorite ? "primary" : "default"}
//             onClick={handleClick}
//         >
//             {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
//         </IconButton>
//     );
// };

// export default FavoriteButton;
