export default {
    InvestorTryoutDuration: 48,
    appUrlStaging: "https://dev.immovestor.be",
    appUrlProduction: "https://app.immovestor.be",
    unregisteredPermissions: {
        read_demographicData: true,
        read_flatsharing: true,
        read_highYield: true,
        read_marketData: true,
        read_spotlight: true,
        read_yieldCalculator: true,
    },
    googleMapsApiKey: "AIzaSyBwRBPcTL6_4MUGWBSqql-SsJVNVNUM_E0",
    googleMapsLibraries: ["places"],
    googleMapSearchCountries: ["be"], // add countries in the array to see more search possibilities.
    startCoordinates: {
        lat: 50.499998,
        lng: 4.749997,
    },
};
