import React from "react";
import "./footer.scss";

const Footer = () => {
    return (
        <div className="footer" id="footer">
            <div className="c">
                <div>
                    <a
                        href="https://www.immovestor.be/mentions-l%C3%A9gales"
                        target={"_blank"}
                        className="link-text"
                    >
                        Mentions légales
                    </a>
                    <a
                        href="https://www.immovestor.be/politique-en-mati%C3%A8re-de-cookies"
                        target={"_blank"}
                        className="link-text"
                    >
                        Politique en matière de cookies
                    </a>
                    <a
                        href="https://www.immovestor.be/politique-de-confidentialit%C3%A9"
                        target={"_blank"}
                        className="link-text"
                    >
                        Politique de confidentialité
                    </a>
                    <a
                        href="https://www.immovestor.be/conditions-d-utilisation"
                        target={"_blank"}
                        className="link-text"
                    >
                        Conditions d'utilisations
                    </a>
                </div>
                <span className="title">© Immovestor 2022</span>
            </div>
            <div className="row">
                <div className="link-block">
                    <div className="title">À propos</div>
                    <div className="separator"></div>
                    <a href="/" className="link-text">
                        Immovestor
                    </a>
                    <a
                        href="https://www.immovestor.be/about-4"
                        className="link-text"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        Presse
                    </a>
                </div>
                {/*<div className='link-block'>*/}
                {/*	<div className='title'>Outils</div>*/}
                {/*	<div className='separator'></div>*/}
                {/*	<a href="/" className='link-text'>Estimer mon bien</a>*/}
                {/*	<a href="/" className='link-text'>Crédit hypothécaire</a>*/}
                {/*</div>*/}
                <div className="link-block">
                    <div className="title">Aide</div>
                    <div className="separator"></div>
                    <a
                        href="https://www.immovestor.be/about-4"
                        className="link-text"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        Contactez-nous
                    </a>
                    {/*<div className='block'>*/}
                    {/*	<div className='side'>*/}
                    {/*		<a href="/" className='link-text'>FAQ</a>*/}
                    {/*		<a href="/" className='link-text'>Fraude</a>*/}
                    {/*	</div>*/}
                    {/*	<div className='vertical-separator'></div>*/}
                    {/*	<div className='side'>*/}
                    {/*		<a href="/" className='link-text'>Accessibilité</a>*/}
                    {/*		<a href="/" className='link-text'>Contactez-nous</a>*/}
                    {/*	</div>*/}
                    {/*</div>*/}
                </div>
                {/*<div className='link-block'>*/}
                {/*	<div className='title'>Suivez-nous</div>*/}
                {/*	<div className='separator'></div>*/}
                {/*	<div className='block'>*/}
                {/*		<div className='side'>*/}
                {/*			<a href="/" className='link-text'>Immoweb Blog</a>*/}
                {/*			<a href="/" className='link-text'>Facebook</a>*/}
                {/*		</div>*/}
                {/*		<div className='vertical-separator'></div>*/}
                {/*		<div className='side'>*/}
                {/*			<a href="/" className='link-text'>Twitter</a>*/}
                {/*			<a href="/" className='link-text'>LinkedIn</a>*/}
                {/*		</div>*/}
                {/*	</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Footer;
