import { Tab, Tabs } from "@mui/material";
import "./categorySelection.scss";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/dispatcher";
// import { filter_sort } from "../../contexts/SearchContext";

const categories = ["is_classic", "is_colloc"];

const CategorySelection = ({ switchCategory }) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const category = queryParameters.get("category");

    const [categoryIndex, setCategoryIndex] = useState(0);

    const handleCategoryChange = (event, newValue) => {
        setCategoryIndex(newValue);
        switchCategory(categories[newValue]);
    };

    useEffect(() => {
        category && categories.includes(category)
            ? setCategoryIndex(categories.indexOf(category))
            : setCategoryIndex(0);
    }, [category]);

    return (
        <Tabs
            value={categoryIndex}
            onChange={handleCategoryChange}
            aria-label="choix de la catégorie"
            className="category-selection"
        >
            <Tab
                className="category-selection__tab"
                label="Locations classiques"
            />
            <Tab
                className="category-selection__tab"
                label="Colocations potentielles"
            />
        </Tabs>
    );
};

export default connect(mapStateToProps, null)(CategorySelection);
