import React, { useContext, useEffect, useState } from "react";
import YieldCalculatorRent from "./YieldCalculatorRent";
import YieldCalculatorInvest from "./YieldCalculatorInvest";
import YieldCalculatorAnnualCharges from "./YieldCalculatorAnnualCharges";
import YieldCalculatorBring from "./YieldCalculatorBring";
import YieldCalculatorClassicLoan from "./YieldCalculatorClassicLoan";
import YieldCalculatorBulletLoan from "./YieldCalculatorBulletLoan";
import YieldCalculatorResult from "./YieldCalculatorResult";
import { connect } from "react-redux";
import {
    mapDispatchToProps,
    mapStateToProps,
    settingsStateToProps,
} from "../../store/dispatcher";

import "./yieldCalculator.scss";
import hasPermission from "../../utils/hasPermission";
import AuthContext from "../../contexts/AuthContext";
import NoAccessMessage from "../noAccessMessage/NoAccessMessage";

const YieldCalculator = ({
    appSettings,
    getYieldCalculatorSettings,
    propertyDetailPrice,
    propertyDetailRent,
    propertyDetailRc,
}) => {
    const { user } = useContext(AuthContext);

    /**
     * Rent states
     */
    const [mensualRent, setMensualRent] = useState(
        propertyDetailRent ? propertyDetailRent : 0
    );

    // todo: remove hardCoding of states values.
    /**
     * Invest states
     */
    const [propertyPrice, setPropertyPrice] = useState(
        propertyDetailPrice ? propertyDetailPrice : 0
    );
    const [investTotal, setInvestTotal] = useState(0);

    /**
     * Annual charges states
     */
    const [annualCharges, setAnnualCharges] = useState(0);

    /**
     * bring states
     */
    const [bring, setBring] = useState(0);

    /**
     * classic loan states
     */
    const [monthlyRent, setMonthlyRent] = useState(0);
    const [classicLoanAmount, setClassicLoanAmount] = useState(0);

    /**
     * Bullet loan states
     */
    const [bulletMonthlyPayment, setBulletMonthlyPayment] = useState(0);
    const [bulletLoanAmount, setBulletLoanAmount] = useState(0);

    useEffect(() => {
        if (!appSettings.yieldCalculator) getYieldCalculatorSettings();
    }, []);

    if (!hasPermission(user, "read_yieldCalculator")) {
        return <NoAccessMessage />;
    }

    return (
        <div className="yield-calculator__container">
            <div className="yield-calculator__first-panel">
                <YieldCalculatorRent
                    mensualRent={mensualRent}
                    setMensualRent={setMensualRent}
                />
                <YieldCalculatorResult
                    mensualRent={mensualRent}
                    annualCharges={annualCharges}
                    investTotal={investTotal}
                    monthlyRent={monthlyRent}
                    bulletMonthlyPayment={bulletMonthlyPayment}
                />
            </div>

            <YieldCalculatorInvest
                propertyPrice={propertyPrice}
                setPropertyPrice={setPropertyPrice}
                investTotal={investTotal}
                setInvestTotal={setInvestTotal}
                yieldCalculatorSettings={appSettings.yieldCalculator}
            />
            <YieldCalculatorAnnualCharges
                propertyPrice={propertyPrice}
                annualCharges={annualCharges}
                setAnnualCharges={setAnnualCharges}
                yieldCalculatorSettings={appSettings.yieldCalculator}
                mensualRent={mensualRent}
                propertyRc={propertyDetailRc}
            />
            <YieldCalculatorBring bring={bring} setBring={setBring} />
            <YieldCalculatorClassicLoan
                monthlyRent={monthlyRent}
                setMonthlyRent={setMonthlyRent}
                yieldCalculatorSettings={appSettings.yieldCalculator}
                investTotal={investTotal}
                bring={bring}
                bulletLoanAmount={bulletLoanAmount}
                classicLoanAmount={classicLoanAmount}
                setClassicLoanAmount={setClassicLoanAmount}
            />
            <YieldCalculatorBulletLoan
                bulletMonthlyPayment={bulletMonthlyPayment}
                setBulletMonthlyPayment={setBulletMonthlyPayment}
                yieldCalculatorSettings={appSettings.yieldCalculator}
                bulletLoanAmount={bulletLoanAmount}
                setBulletLoanAmount={setBulletLoanAmount}
            />
            <YieldCalculatorResult
                mensualRent={mensualRent}
                annualCharges={annualCharges}
                investTotal={investTotal}
                monthlyRent={monthlyRent}
                bulletMonthlyPayment={bulletMonthlyPayment}
            />
        </div>
    );
};

export default connect(
    settingsStateToProps,
    mapDispatchToProps
)(YieldCalculator);
