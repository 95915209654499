import React, { useEffect, useState } from "react";

import formatNumber from "../../helpers/formatNumber";
import YieldCalculatorInput from "./YieldCalculatorInput";

const YieldCalculatorBulletLoan = ({
    bulletMonthlyPayment,
    setBulletMonthlyPayment,
    yieldCalculatorSettings,
    bulletLoanAmount,
    setBulletLoanAmount,
}) => {
    const [bulletLoanRate, setBulletLoanRate] = useState(
        yieldCalculatorSettings ? yieldCalculatorSettings.bulletLoanRate : 0
    );

    useEffect(() => {
        setBulletLoanRate(
            yieldCalculatorSettings ? yieldCalculatorSettings.bulletLoanRate : 0
        );
    }, [yieldCalculatorSettings]);

    useEffect(() => {
        setBulletMonthlyPayment((bulletLoanAmount * bulletLoanRate) / 100 / 12);
    }, [bulletLoanAmount, bulletLoanRate]);

    return (
        <div className="yield-calculator__bullet-loan">
            <h2>Terme fixe (bullet)</h2>

            <YieldCalculatorInput
                value={bulletLoanAmount}
                setValue={setBulletLoanAmount}
                className={"yield-calculator__input"}
                label={"Montant terme fixe"}
                id={"yield-calculator-bullet-loan-amount"}
                prefix={"€"}
            />

            <YieldCalculatorInput
                value={bulletLoanRate}
                setValue={setBulletLoanRate}
                className={"yield-calculator__input"}
                label={"Taux"}
                id={"yield-calculator-bullet-loan-rate"}
                prefix={"%"}
            />

            <div className="yield-calculator__total">
                Mensualité crédit bullet pour 12 mois :{" "}
                <span>{formatNumber(bulletMonthlyPayment * 12)} €</span>
            </div>
            <div className="yield-calculator__total">
                Mensualité : <span>{formatNumber(bulletMonthlyPayment)} €</span>
            </div>
        </div>
    );
};

export default YieldCalculatorBulletLoan;
