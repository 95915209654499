import React from "react";
import ReactShowMoreText from "react-show-more-text";
import { complete_data } from "../../../functions/complete_data";
import DemographicData from "../../demographic/DemographicData";
import Map from "../../map/Map";
import DetailsTop from "../details-top/DetailsTop";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import "./detailsMobile.scss";
import DetailsPrice from "../details-price/DetailsPrice";
import formatNumber from "../../../helpers/formatNumber";
import DetailsDescription from "../details-description/DetailsDescription";
import YieldCalculator from "../../yieldCalculator/YieldCalculator";
import MarketDataInner from "../details-web/market-data/MarketDataInner";
import { Box } from "@mui/material";

const DetailsMobile = ({
    current_investment,
    open_modal,
    isAdmin,
    isAPropertyFromUserAgency,
    setActualProperty,
}) => {
    const data_to_render = complete_data(current_investment, isAdmin);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const property = current_investment.property_data;

    return (
        <>
            <div className="center">
                <DetailsTop
                    open_modal={open_modal}
                    current_investment={current_investment}
                    isAPropertyFromUserAgency={isAPropertyFromUserAgency}
                    setActualProperty={setActualProperty}
                />

                <DetailsPrice
                    price={`${formatNumber(property.price)}€`}
                    rentPrice={data_to_render["Loyer"]}
                    yieldPercentage={data_to_render["Rdmt brut"]}
                    property={current_investment}
                />

                <Box sx={{ width: "100%" }}>
                    {/*<Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "red" }}>*/}
                    <Tabs
                        // sx={{ width: "100%" }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        // scrollButtons="auto"
                        scrollButtons
                        allowScrollButtonsMobile
                    >
                        <Tab wrapped label="Description" />
                        <Tab wrapped label="Données démographiques" />
                        <Tab wrapped label="Map" />
                        <Tab wrapped label="Calculateur de rendement" />
                        <Tab wrapped label="Données de marché" />
                    </Tabs>
                </Box>

                {value === 0 ? (
                    <>
                        <DetailsDescription
                            title={property.title}
                            description={property.description}
                            values={data_to_render}
                            openModal={open_modal}
                            isAdmin={isAdmin}
                            currentInvestmentUrl={current_investment.url}
                            investment={current_investment}
                        />
                    </>
                ) : null}
            </div>
            {value === 1 ? (
                <DemographicData
                    demographic_data={current_investment.demographic_data}
                />
            ) : null}
            {value === 2 ? (
                <div className="map-container">
                    <Map
                        center={{
                            lat: current_investment.property_data.location
                                .latitude
                                ? current_investment.property_data.location
                                      .latitude
                                : 0,
                            lng: current_investment.property_data.location
                                .longitude
                                ? current_investment.property_data.location
                                      .longitude
                                : 0,
                        }}
                        location={current_investment.property_data.location}
                    />
                </div>
            ) : null}
            {value === 3 ? (
                <div className="map-container with-margin">
                    <YieldCalculator
                        propertyDetailRent={
                            current_investment.property_data.bot_data.rent
                        }
                        propertyDetailPrice={
                            current_investment.property_data.price
                        }
                        propertyDetailRc={
                            current_investment.property_data.transaction?.sale
                                ?.cadastralIncome
                        }
                    />
                </div>
            ) : null}
            {value === 4 ? (
                <div className="map-container with-margin">
                    <MarketDataInner current_property={current_investment} />
                </div>
            ) : null}
        </>
    );
};

export default DetailsMobile;
