import React, { createContext, useContext, useEffect, useState } from "react";
import generateFiltersFromUrl from "../helpers/generateFiltersFromUrl";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../store/dispatcher";
import { precision_search } from "../functions/search";
import AuthContext from "./AuthContext";

const SearchContext = createContext();

export const defaultFilterSort = {
    filter: {
        price_min: 0,
        price_max: 0,
        rdt_min: 0,
        rdt_max: 0,
        rent_min: 0,
        rent_max: 0,
        count_per_pages: 12,
        place_type: "",
        conditions: "",
        epc_scores: "",
        spotlight: false,
        convertible_attic: null,
        cadastral_income: null,
        zip_codes: [],
        category: "is_classic",
        bedroom_min: 0,
        bedroom_max: 0,
        agencyId: "",
    },
    zip_codes: [],
    districts: [],
    provinces: [],
    page: 0,
    sort: "pert",
};

/**
 * This is used in Filters.jsx, but it seems that Filters.jsx screen is not used anymore.
 */

export let filter_sort = defaultFilterSort;

// function to create list of zips from start url
const generate_zip_list_on_load = (filters) => {
    const prec = precision_search(filters);

    const prec_v = [
        ...Object.keys(prec.localities).map((elem) => ({
            label: `${elem} (${prec.localities[elem][0]})`,
            zips: prec.localities[elem],
            type: "postal",
            value: `${elem}`,
        })),
        ...Object.keys(prec.provinces).map((elem) => ({
            label: `${elem} (Province)`,
            zips: prec.provinces[elem],
            type: "province",
            value: elem,
        })),
        ...Object.keys(prec.districts).map((elem) => ({
            label: `${elem} (Arrondissement)`,
            zips: prec.districts[elem],
            type: "district",
            value: elem,
        })),
    ];

    const all_zip = [];
    const others = {
        zip_codes: [],
        districts: [],
        provinces: [],
    };

    for (let index = 0; index < prec_v.length; index++) {
        const element = prec_v[index];
        if (element.type === "postal") {
            others.zip_codes.push(element.value);
        } else if (element.type === "district") {
            others.districts.push(element.value);
        } else if (element.type === "province") {
            others.provinces.push(element.value);
        }
        for (let zip_id = 0; zip_id < element.zips.length; zip_id++) {
            const zip = element.zips[zip_id];
            if (!all_zip.includes(zip)) all_zip.push(zip.toString());
        }
    }

    filter_sort.zip_codes = others.zip_codes;
    filter_sort.provinces = others.provinces;
    filter_sort.districts = others.districts;
    filter_sort.filter.zip_codes = all_zip;
    filter_sort.page = 0;
};

let loaded = false;

export const SearchProvider = connect(
    null,
    mapDispatchToProps
)(({ children, fetchInvestments }) => {
    const { user } = useContext(AuthContext);

    const [actualCategory, setActualCategory] = useState("is_classic");

    useEffect(() => {
        filter_sort = generateFiltersFromUrl(defaultFilterSort);
        generate_zip_list_on_load(filter_sort);
        fetchInvestments(filter_sort);
    }, [user]); // re-fetch when user login or logout, for reload favorites, freezes, etc.

    const value = {
        actualCategory,
        setActualCategory,
    };

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    );
});

export default SearchContext;
