export const home_type = {
    all: "Tous",
    APARTMENT_BLOCK: "Immeuble à appartements",
    MIXED_USE_BUILDING: "Immeuble mixte",
    HOUSE: "Maison",
    VILLA: "Villa",
    MANSION: "Maison de maitre",
    DUPLEX: "Duplex",
    APARTMENT: "Appartement",
    BUSINESS: "Commerce",
    FLAT_STUDIO: "Studio",
    HOTEL_RESTAURANT_CAFE: "Horeca",
    MIXED_USE_BUILDING_COMMERCIAL: "Immeuble commercial mixte",
    MIXED_USE_BUILDING_INDUSTRIES: "Immeuble industriel mixte",
    LOCK_UP_GARAGE: "Garage fermé",
    EXCEPTIONAL_PROPERTY: "Bien exceptionnel",
    OFFICES: "Bureaux",
};

export const home_type_without_all = {
    APARTMENT_BLOCK: "Immeuble à appartements",
    MIXED_USE_BUILDING: "Immeuble mixte",
    HOUSE: "Maison",
    VILLA: "Villa",
    MANSION: "Maison de maitre",
    DUPLEX: "Duplex",
    APARTMENT: "Appartement",
    BUSINESS: "Commerce",
    FLAT_STUDIO: "Studio",
    HOTEL_RESTAURANT_CAFE: "Horeca",
    MIXED_USE_BUILDING_COMMERCIAL: "Immeuble commercial mixte",
    MIXED_USE_BUILDING_INDUSTRIES: "Immeuble industriel mixte",
    LOCK_UP_GARAGE: "Garage fermé",
    EXCEPTIONAL_PROPERTY: "Bien exceptionnel",
    OFFICES: "Bureaux",
};

export const good_state = {
    all: "Tous",
    GOOD: "Bon",
    AS_NEW: "Comme neuf",
    TO_RENOVATE: "A rénover",
    TO_BE_DONE_UP: "Bâtiment à finir",
    null: "Non fourni",
};

export const good_state_without_all = {
    GOOD: "Bon",
    AS_NEW: "Comme neuf",
    TO_RENOVATE: "A rénover",
    TO_BE_DONE_UP: "Bâtiment à finir",
    null: "Non fourni",
};

export const chauffage_type = [
    "Chauffage au gaz",
    "Chauffage au fioul",
    "Chauffage électrique",
    "Chauffage solaire",
    "Chauffage au bois",
    "Pompe à chaleur (PAC)",
    "Non fourni",
];

export const categories_without_all = ["Classique", "Collocations"];

export const pebs = ["Tous", "A++", "A+", "A", "B", "C", "D", "E", "F", "G"];

export const peb_scores = ["A++", "A+", "A", "B", "C", "D", "E", "F", "G"];

export const TOAST_LVL = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
};

export const home_type_for_ia = [
    "HOUSE",
    "APARTMENT",
    "OTHER",
    "LOCK_UP_GARAGE",
    "MIXED_USE_BUILDING",
];
