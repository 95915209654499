import React from 'react'
import "./style.scss"

const Loading = () => {
  return (
    <div id="loader">
        <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  )
}

export default Loading