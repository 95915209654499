// import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { numberWithCommas } from "../../screens/details/Details";
import "./demographicData.scss";
// import "../../styles/components/buttons.scss";
import hasPermission from "../../utils/hasPermission";
import ImmovestorButton from "../immovestorButton/ImmovestorButton";
import getSubscribeButtonText from "../../helpers/getSubscribeButtonText";
import getSubscribeButtonLink from "../../helpers/getSubscribeButtonLink";
import NoAccessMessage from "../noAccessMessage/NoAccessMessage";

const demographic_to_render = [
    { key: "Weath_index_2020", label: "Indice de richesse en 2020" },
    {
        key: "Average_income_by_inhabitant_2020",
        label: "Revenu moyen par habitant en 2020",
    },
    { key: "Unemployment_rate_2019", label: "Taux de chômage en 2019" },
    {
        key: "Unemployment_evoluation_rate_2010_2019",
        label: "Evolution du taux de chômage entre 2010 et 2019",
    },
    {
        key: "Inhabitants_evolution_Y-1",
        label: "Évolution du nombre d'habitants depuis l'année dernière",
    },

    { key: "Apartment_price_2021", label: "Prix des appartements en 2021" },
    {
        key: "Apartment_price_Q2_2022",
        label: "Prix des appartements en fin 2022",
    },
    {
        key: "Apartment_price_evolution_2021_Q2_2022",
        label: "Evolution du prix des appartements entre 2021 et fin 2022",
    },
    {
        key: "Apartment_price_evolution_2011_2021",
        label: "Evolution du prix des appartements entre 2011 et fin 2021",
    },
    { key: "House_price_2021", label: "Prix des maisons en 2021" },
    { key: "House_price_Q2_2022", label: "Prix des maisons en fin 2022" },
    {
        key: "House_price_evolution_2011_2021",
        label: "Evolution du prix des maisons entre 2021 et fin 2022",
    },
    {
        key: "House_price_evolution_2021_Q2_2022",
        label: "Evolution du prix des maisons entre 2011 et fin 2021",
    },
    { key: "Inhabitants_2022", label: "Nombre d'habitant de la région" },
    { key: "Inhabitants_km2", label: "Nombre d'habitant par km2" },
];

const render_demographic_value = (value, key) => {
    if ("Apartment_price_2021|House_price_2021".includes(key)) {
        return numberWithCommas(value) + " €";
    } else if (
        (key.includes("price") && !key.includes("evolution")) ||
        key.includes("income")
    ) {
        return value + " €";
    }
    return value;
};

const DemographicData = ({ demographic_data, openModal }) => {
    const { user } = useContext(AuthContext);

    const [userCanSeeDemograhicData, setUserCanSeeDemograhicData] =
        useState(false);

    useEffect(() => {
        (async () => {
            setUserCanSeeDemograhicData(
                hasPermission(user, "read_demographicData")
            );
        })();
    }, [user]);

    const DemographicBody = () => {
        // if (!user.token || (user.token && !userCanSeeDemograhicData)) {
        if (!userCanSeeDemograhicData) {
            return <NoAccessMessage />;
        }

        if (!demographic_data) {
            return (
                <div className="demographic-view__content__ask-log">
                    <p>
                        Toutes nos excuses, mais nous ne possédons pas de
                        données démographiques sur la région de ce bien. Cliquez
                        sur le bouton ci-dessous si ces données vous
                        intéressent.
                    </p>
                    <ImmovestorButton
                        onClick={() => openModal("report")}
                        className="demographic-view__content__button"
                    >
                        Demander les données pour ce bien
                    </ImmovestorButton>
                </div>
            );
        }

        return (
            <ul className="demographic-view__content__list">
                {demographic_to_render.map((elem, id) => (
                    <li key={id} className="demographic-view__content__item">
                        <h3>{elem.label}</h3>
                        <p>
                            {render_demographic_value(
                                demographic_data[elem.key],
                                elem.key
                            )}
                        </p>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="demographic-view">
            <h2>Données démographiques</h2>
            <div className="demographic-view__content">
                <DemographicBody />
            </div>
        </div>
    );
};

export default DemographicData;
