import React, { useEffect, useState } from 'react'
import "./style.scss"
import "../home/AdminHome"
import axios from "../../../services/axios"
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

var opt_weekday = { year: 'numeric', month: 'numeric', day: 'numeric', hour: "numeric", minute: "numeric"};

const UsersReports = () => {
	const [reports, setReports] = useState([]);

	const load = async () => {
		const ret = await axios.get("/propreties/report")
			.then(e => e.data)
			.catch(e => null);
		if (ret) {
			setReports(ret);
		}
	}

	const handle_report = async (id) => {
		const ret = await axios.put(`/propreties/report/${id}`, {
			status: "handled"
		})
			.then(e => e.data)
			.catch(e => null);
		if (ret) {
			const new_rep = [...reports];
			const old = new_rep.filter(e => e.id === id);
			if (!old.length)
				return;
			
			new_rep.splice(new_rep.indexOf(old[0]), 1);
			setReports(new_rep);
		}
	}

	useEffect(() => {
		load();
	}, [])
	
	
	return (
		<div id="user-reports" className='admin-base-view'>
			<h1>Anomalies reportées</h1>
			<div className='report-wrapper'>
				{
					reports.map((elem, id) => {
						const createdAt = elem.createdAt ? new Date(elem.createdAt._seconds * 1000) : new Date();
						return (
							<div key={id} className='report-elem'	>
								<div className='report-elem-header'>
									<p >{elem.email}</p>
									<div className='status-container'>
										<div className={`report-bubble ${elem.type}`}>
											{elem.type}
										</div>
										<div className={`report-bubble ${elem.status}`}>
											{elem.status}
										</div>
									</div>
								</div>
								<p className='date'>{createdAt.toLocaleDateString(`fr-FR`, opt_weekday)}</p>
								<p>{elem.description}</p>
								<div className='report-actions'>
									<Button target={"_blank"} href={`/#/details/${elem.property_id}`}>Voir l'annonce</Button>
									<Button onClick={() => handle_report(elem.id)} variant='outlined'>Alerte gérée</Button>
								</div>
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default UsersReports