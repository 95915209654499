import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    mapDispatchToProps,
    settingsStateToProps,
} from "../../../store/dispatcher";
import AppYieldSettings from "./AppYieldSettings";

import "./appSettings.scss";

const AppSettings = ({
    appSettings,
    getYieldCalculatorSettings,
    updateYieldCalculatorSettings,
}) => {
    const [isLoading, setIsLoading] = useState(true);

    if (!appSettings.yieldCalculator) getYieldCalculatorSettings();

    useEffect(() => {
        if (!appSettings.isLoading) setIsLoading(false);
    }, [appSettings]);

    return (
        <>
            {!isLoading ? (
                <div className="app-settings__container">
                    <h1>Paramètres de l'application</h1>
                    <AppYieldSettings
                        yieldSettings={appSettings.yieldCalculator}
                        updateYieldCalculatorSettings={
                            updateYieldCalculatorSettings
                        }
                    />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default connect(settingsStateToProps, mapDispatchToProps)(AppSettings);
